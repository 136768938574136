import { faBan, faEye, faCheckDouble } from '@fortawesome/pro-light-svg-icons';

export const humanifyText = (text) =>
  text
    .split('_')
    .map((textL) => textL.charAt(0).toUpperCase() + textL.slice(1))
    .join(' ');

export const SETTINGS_TAB = 'settings';
export const SUBMISSIONS_TAB = 'submissions';
export const REPORTS_TAB = 'reports';
export const EXPORT_TAB = 'export';
export const CONNECTOR_TAB = 'connectors';

export const deleteSection = 'DElETE_SECTION';
export const deleteWarning = 'DELETE_WARNING';

export const SECTION_LIMIT = 5;
// Module names
export const MODULE_FORMX = 'FORMX';

// Reducer CONSTANTS
export const FETCHED_FORMX = 'FETCHED_FORMX';

export const FORM_PURPOSE_CAMPAIGN_ALMABASE = 1;
export const FORM_PURPOSE_SURVEY = 2;
export const FORM_PURPOSE_ONLINE_COMPETITION = 3;
export const FORM_PURPOSE_COLLECT_APPLICATIONS = 4;
export const FORM_PURPOSE_RESPONSE_TO_APPEAL = 5;
export const FORM_PURPOSE_CUSTOM_CAMPAIGN = 6;
export const FORM_PURPOSE_DIRECTORY_ALMABASE = 7;
export const FORM_PURPOSE_GROUP_JOINING_FORM = 8;
export const FORM_PURPOSES_TRIGGER_GIFT_WORKFLOW = 9;
export const FORM_PURPOSE_WORKFLOWS = 10;
export const FORM_PURPOSE_EVENT_SYNC_WORKFLOWS = 11;
export const FORM_PURPOSE_EVENT_PRE_REGISTRATION = 12;
export const FORM_PURPOSE_MENTORSHIP_ONBOARDING = 13;

export const WORKFLOW_TYPE_FORM_INTERNAL_FIELD_SLUGS = [
  'gift_group_id__custom',
  'event_id__custom',
  'event_should_update__custom',
  'event_registrations__custom',
  'event_tickets__custom',
];

export const FROALA_EDITOR_TOOLBARS_CONFIG = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'subscript',
  'superscript',
  'fontSize',
  'color',
  'alignLeft',
  'alignCenter',
  'formatOL',
  'formatUL',
  'insertLink',
  'insertImage',
  'insertVideo',
  'emoticons',
  'specialCharacters',
  'insertHR',
];

export const FORM_PURPOSE_CHOICES = [
  {
    text: 'For an event or a giving campaign on Almabase',
    value: FORM_PURPOSE_CAMPAIGN_ALMABASE,
  },
  {
    text: 'To collect submissions for a directory',
    value: FORM_PURPOSE_DIRECTORY_ALMABASE,
  },
  {
    text: 'To conduct a survey',
    value: FORM_PURPOSE_SURVEY,
  },
  {
    text: 'To conduct an online competition or campaign',
    value: FORM_PURPOSE_ONLINE_COMPETITION,
  },
  {
    text: 'To collect requests or applications',
    value: FORM_PURPOSE_COLLECT_APPLICATIONS,
  },
  {
    text: 'To collect responses to an appeal or request',
    value: FORM_PURPOSE_RESPONSE_TO_APPEAL,
  },
  {
    text: 'Custom Campaign',
    value: FORM_PURPOSE_CUSTOM_CAMPAIGN,
  },
  {
    text: 'To collect information for verifying the access',
    value: FORM_PURPOSE_GROUP_JOINING_FORM,
  },
  {
    text: 'To collect information for mentorship onboarding',
    value: FORM_PURPOSE_MENTORSHIP_ONBOARDING,
  },
];

export const TRIGGER_GIFT_WORKFLOW_PURPOSE = {
  text: 'To trigger a gift sync workflow',
  value: FORM_PURPOSES_TRIGGER_GIFT_WORKFLOW,
};

export const TRIGGER_WORKFLOW_PURPOSE = {
  text: 'To trigger a gift list workflow',
  value: FORM_PURPOSE_WORKFLOWS,
};

export const TRIGGER_EVENT_SYNC_WORKFLOW = {
  text: 'To trigger event sync workflow',
  value: FORM_PURPOSE_EVENT_SYNC_WORKFLOWS,
};

export const PARTICIPANT_AFFILIATION_CHOICES = [
  {
    text: 'As a volunteer, mentor ,donor or benefactor',
    value: 1,
  },
  {
    text: 'As an applicant or beneficiary.',
    value: 2,
  },
  {
    text: 'As a participant in a survey or campaign.',
    value: 3,
  },
  {
    text: 'Uncategorized',
    value: 4,
  },
];

export const TEXT_VALUE = 1;

const TEXT_AREA_VALUE = 2;

export const EMAIL_VALUE = 3;

const SWITCH_VALUE = 4;

export const CHECKBOX_VALUE = 5;

export const DROPDOWN_VALUE = 6;

export const MULTI_SELECT_VALUE = 7;

export const RADIO_VALUE = 8;

export const FILE_VALUE = 9;

const DATE_VALUE = 10;

export const URL_VALUE = 14;

export const DROPDOWN_RANGE_VALUE = 16;

export const MULTIPLE_FILE_VALUE = 17;

export const GOOGLE_LOCATION_VALUE = 18;

const FILE_SIZE_LIMIT = 20;

export const MULTIPLE_FILE_LIMIT = 20;

export const FIELD_NAME_VALUE = 22;

const VIDEO_SIZE_LIMIT = 200;

export const RICH_TEXT_VALUE = 21;

export const PHONE_NUMBER_VALUE = 23;

export const FIELD_VALUES_SHOULD_HAVE_OPTIONAL = [CHECKBOX_VALUE, DROPDOWN_VALUE, RADIO_VALUE];

export const FIELD_VALUES_SHOULD_HAVE_AUTOFILL_OPTION = [DROPDOWN_VALUE, RADIO_VALUE];

export const FIELD_VALUES_SHOULD_HAVE_RANGE = [DROPDOWN_RANGE_VALUE];

export const FIELD_VALUES_HAVE_DROPDOWN_CONDITION = [GOOGLE_LOCATION_VALUE, FIELD_NAME_VALUE];

export const FIELD_TYPES_HAVE_DEFAULT_VALUE_AS_DROPDOWN = [
  CHECKBOX_VALUE,
  DROPDOWN_VALUE,
  RADIO_VALUE,
  DROPDOWN_RANGE_VALUE,
];

export const DISABLE_DELETE_FIELDS = [FIELD_NAME_VALUE, EMAIL_VALUE];

export const FIELD_TYPES_HAVE_MULTI_VALUES = [CHECKBOX_VALUE, MULTI_SELECT_VALUE];

export const FIELD_TYPES_HAVE_CONDITIONS = [DROPDOWN_VALUE, RADIO_VALUE, DROPDOWN_RANGE_VALUE];

export const DISABLE_DEFAULT_VALUE_FOR_FIELDS = [
  TEXT_VALUE,
  TEXT_AREA_VALUE,
  FILE_VALUE,
  MULTI_SELECT_VALUE,
  DATE_VALUE,
  GOOGLE_LOCATION_VALUE,
  PHONE_NUMBER_VALUE,
  URL_VALUE,
];

export const DISABLE_PLACEHOLDER_TEXT_FOR_FIELDS = [
  CHECKBOX_VALUE,
  DROPDOWN_VALUE,
  RADIO_VALUE,
  FILE_VALUE,
  MULTIPLE_FILE_VALUE,
  DATE_VALUE,
  DROPDOWN_RANGE_VALUE,
  PHONE_NUMBER_VALUE,
  URL_VALUE,
];

export const OPTION_SPLIT_FULL_NAME = 'split_name';

export const OPTION_SPLIT_FULL_NAME_LABEL = 'First & Last Name (collected separately)';

export const OPTION_KEEP_FULL_NAME = 'full_name';

export const OPTION_KEEP_FULL_NAME_LABEL = 'Full Name (single field)';

export const FIELD_CHOICES = [
  {
    text: 'Name',
    value: FIELD_NAME_VALUE,
    enabled: true,
    type: 'name',
    additionalDropDownLabel: 'How would you like to collect the name?',
    additionalDropDownOptions: [
      {
        key: OPTION_SPLIT_FULL_NAME,
        label: OPTION_SPLIT_FULL_NAME_LABEL,
      },
      {
        key: OPTION_KEEP_FULL_NAME,
        label: OPTION_KEEP_FULL_NAME_LABEL,
      },
    ],
    additionalDropDownPlaceholder: '',
  },
  {
    text: 'Email',
    value: EMAIL_VALUE,
    enabled: true,
    type: 'email',
  },
  {
    text: 'Single line text',
    value: TEXT_VALUE,
    enabled: true,
    type: 'text',
  },
  {
    text: 'Paragraph text',
    value: TEXT_AREA_VALUE,
    enabled: true,
    type: 'textarea',
  },
  {
    text: 'Switch',
    value: SWITCH_VALUE,
    enabled: false,
    type: 'switch',
  },
  {
    text: 'Check boxes',
    value: CHECKBOX_VALUE,
    enabled: true,
    type: 'checkbox',
  },
  {
    text: 'Drop down',
    value: DROPDOWN_VALUE,
    enabled: true,
    type: 'select',
  },
  {
    text: 'Dropdown (Range)',
    tooltip_text: 'Specify a range of values in a dropdown',
    help_text_placeholder: 'Eg: Choose your Class Year',
    default_value_placeholder: '2014',
    value: DROPDOWN_RANGE_VALUE,
    enabled: true,
    type: 'select_range',
  },
  {
    text: 'Multi Select DropDown',
    value: MULTI_SELECT_VALUE,
    enabled: false,
    type: 'multi_select',
  },
  {
    text: 'Radio buttons',
    value: RADIO_VALUE,
    enabled: true,
    type: 'radio',
  },
  {
    text: 'Date',
    value: DATE_VALUE,
    enabled: true,
    type: 'date',
  },
  {
    text: 'File Upload',
    tooltip_text: `Supports images up to ${FILE_SIZE_LIMIT} MB and videos up to ${VIDEO_SIZE_LIMIT} MB`,
    value: FILE_VALUE,
    enabled: true,
    type: 'file',
  },
  {
    text: 'Multiple file upload',
    tooltip_text: `Supports up to ${MULTIPLE_FILE_LIMIT} images and documents. Max file size : ${FILE_SIZE_LIMIT} MB`,
    value: MULTIPLE_FILE_VALUE,
    enabled: true,
    type: 'multi_file',
  },
  {
    text: 'Location',
    tooltip_text: 'Powered by Google. Add accurate locations to your form submissions',
    value: GOOGLE_LOCATION_VALUE,
    enabled: true,
    type: 'google_location',
    additionalDropDownLabel: 'Options (Comma Separated)',
    additionalDropDownOptions: [
      {
        key: 'cities',
        label: 'Allow cities only',
      },
      {
        key: 'regions',
        label: 'Allow any area (District, county, state, or country)',
      },
      {
        key: 'anywhere',
        label: 'Allow any Address (Building, Business, Landmarks etc.)',
      },
    ],
    additionalDropDownPlaceholder: 'Location Type',
  },
  {
    text: 'Rich text',
    value: RICH_TEXT_VALUE,
    enabled: true,
    type: 'rich_text',
  },
  {
    text: 'Phone Number',
    value: PHONE_NUMBER_VALUE,
    enabled: true,
    type: 'phone_number',
  },
  {
    text: 'URL',
    value: URL_VALUE,
    enabled: true,
    type: 'url',
  },
];

export const FORM_PURPOSES_CONDITIONS = {
  1: {
    defaultValue: {
      button_text: '',
      redirect_url: '',
      is_basic_detail_required: false,
      participant_affiliation_type: 4,
    },
    initialValue: {
      button_text: '',
      redirect_url: '',
      is_basic_detail_required: false,
      participant_affiliation_type: 4,
    },
    disabledField: {
      button_text: true,
      redirect_url: true,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: true,
      identification: true,
      notification_emails: true,
      button_text: true,
      redirect_url: true,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
      add_section_button: true,
    },
  },
  2: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: true,
      participant_affiliation_type: 3,
    },
    initialValue: {
      participant_affiliation_type: 3,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  3: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: true,
      participant_affiliation_type: 3,
    },
    initialValue: {
      is_basic_detail_required: true,
      participant_affiliation_type: 3,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      button_text: false,
      form_submission: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  4: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: true,
      participant_affiliation_type: 2,
    },
    initialValue: {
      is_basic_detail_required: true,
      participant_affiliation_type: 2,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  5: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: true,
      participant_affiliation_type: 1,
    },
    initialValue: {
      is_basic_detail_required: true,
      participant_affiliation_type: 1,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  6: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: true,
      participant_affiliation_type: 4,
    },
    initialValue: null,
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: false,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: false,
    },
  },
  7: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: true,
      participant_affiliation_type: 4,
    },
    initialValue: null,
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: false,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: false,
    },
  },
  8: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: true,
      participant_affiliation_type: 3,
    },
    initialValue: {
      participant_affiliation_type: 3,
      is_basic_detail_required: true,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  9: {
    defaultValue: {
      button_text: 'Run workflow',
      redirect_url: '',
      is_basic_detail_required: false,
      participant_affiliation_type: 3,
    },
    initialValue: {
      participant_affiliation_type: 3,
      is_basic_detail_required: false,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  10: {
    defaultValue: {
      button_text: 'Run workflow',
      redirect_url: '',
      is_basic_detail_required: false,
      participant_affiliation_type: 3,
    },
    initialValue: {
      participant_affiliation_type: 3,
      is_basic_detail_required: false,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  11: {
    defaultValue: {
      button_text: 'Run workflow',
      redirect_url: '',
      is_basic_detail_required: false,
      participant_affiliation_type: 3,
    },
    initialValue: {
      participant_affiliation_type: 3,
      is_basic_detail_required: false,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: false,
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: true,
    },
  },
  12: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: false,
      participant_affiliation_type: 3,
    },
    initialValue: {
      participant_affiliation_type: 3,
      is_basic_detail_required: false,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
    },
    hiddenField: {
      form_submission: true,
      identification: true,
      notification_emails: true,
      button_text: true,
      redirect_url: true,
      is_basic_detail_required: true,
      participant_affiliation_type: true,
      add_section_button: true,
    },
  },
  13: {
    defaultValue: {
      button_text: 'Submit',
      redirect_url: '',
      is_basic_detail_required: false,
      participant_affiliation_type: 3,
    },
    initialValue: {
      participant_affiliation_type: 3,
      is_basic_detail_required: false,
    },
    disabledField: {
      button_text: false,
      redirect_url: false,
      is_basic_detail_required: false,
      participant_affiliation_type: false,
    },
    hiddenField: {
      form_submission: true,
      identification: true,
      notification_emails: true,
      button_text: true,
      redirect_url: true,
      is_basic_detail_required: false,
      participant_affiliation_type: false,
      add_section_button: true,
    },
  },
};

// Almasights touchpoints
export const TOUCHPOINTS = {
  MAPPED_FORM_ENTRY: 'forms-mapped-a-form-entry',
  UNMAPPED_FORM_ENTRY: 'forms-unmapped-a-form-entry',
  IGNORED_FORM_ENTRY: 'forms-ignored-a-form-entry',
};

export const FOOTER_TABS = {
  SUBMISSIONS: 'submissions',
  UNMAPPED: 'unmapped',
  IGNORED: 'ignored',
  MAPPED: 'mapped',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const FOOTER_TAB_STATUS_MAPPING = {
  pending: 0,
  approved: 1,
  rejected: 2,
};

export const PHONE_NUMBER_INPUT_PREFIX = 'phone_number_input';

export const GROUP_BY_KEY = 'form_group_label';

export const DONT_SHOW_STEP_FORM = [FORM_PURPOSE_CAMPAIGN_ALMABASE];

export const WORKFLOW_TRIGGERS_FORM_PURPOSES = [FORM_PURPOSES_TRIGGER_GIFT_WORKFLOW, FORM_PURPOSE_EVENT_SYNC_WORKFLOWS];

export const RULE_CONFIG = [
  {
    name: "Don't Update",
    icon: faBan,
    helpText: "Don't update or flag for review",
    id: 'ignore',
  },
  {
    name: 'Review before updating',
    icon: faEye,
    helpText: 'Flag all for review',
    id: 'manual',
  },
  {
    name: 'Automatically Replace',
    icon: faCheckDouble,
    helpText: 'This field supports multiple values.Any new data will be automatically added to existing data.',
    id: 'auto',
  },
];
