import React from 'react';
import Lottie from 'react-lottie';
import { number } from 'prop-types';
import data from './loader';

function LottieLoader({ width = 50, height = 50 }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return <Lottie options={defaultOptions} width={width} height={width} isStopped={false} isPaused={false} />;
}

LottieLoader.propTypes = {
  width: number,
  height: number,
};

export default LottieLoader;
