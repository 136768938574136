import instance from 'Src/common/utilities/axios_util';
import { HUB_API, CAMPAIGN_HUB_DONOR_API } from '../endpoints';

export function fetchSettings(endpoint) {
  return instance.get(endpoint);
}

export function fetchPage(endpoint) {
  return instance.get(endpoint);
}

export const fetchHub = (pageId, hubId) => {
  let url = HUB_API.replace('<page_id>', pageId);
  if (hubId) {
    url = url.concat(hubId, '/');
  }
  url = url.concat('?skip_pagination=True');
  return instance.get(url);
};

export const fetchCampaignDonors = (campaignId, pageNo = 1) => {
  const url = CAMPAIGN_HUB_DONOR_API.replace('<campaign_id>', campaignId).concat('?page=', pageNo);
  return instance.get(url);
};
