/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect } from 'react';
import { Button, Input, Tooltip, message, Row, Col, Menu, Statistic, Drawer } from 'antd';
import { injectIntl } from 'react-intl';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faLock } from '@fortawesome/pro-solid-svg-icons';
import CurrencyRenderer from 'Src/common/components/currencyRenderer';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import { find, isEmpty } from 'lodash';
import { countDecimals } from 'Src/common/utilities/data_util';
import { GiftConsumer } from '../../../context/gift';
import { SettingsConsumer } from '../../../context/settings';
import CurrencySlabs from './currencySlabs';
import { success } from '../../../actionCreators';
import { MODULE_GIFT } from '../../../constants';
import CurrencySelector from '../currencySelector';

import './style.scss';

function AmountSlabs(props) {
  const [settings] = SettingsConsumer();
  const [gift, giftDispatch] = GiftConsumer();
  const [donationType, setDonationType] = useState(props?.gift?.subscription_plan?.interval || null);
  const [intervals, setIntervals] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(gift.amount);
  const [otherAmount, setOtherAmount] = useState();
  const [limit, setLimit] = useState(props?.gift?.subscription_plan?.limit || null);
  const [showLimitMenu, setShowLimitMenu] = useState(false);
  const anotherAmountPlaceHolder = props.intl.formatMessage({
    id: 'or-another-amount-placeholder',
  });

  function toFixed(num, fixed) {
    const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
    return num.toString().match(re)[0];
  }

  const checkAmount = (amount) => {
    if (isNaN(parseFloat(amount))) {
      return undefined;
    }
    if (countDecimals(amount) > 2) return toFixed(parseFloat(amount), 2);
    return amount;
  };

  const handleDefaultAmount = (amount, quickSlab) => {
    if (settings.page.are_quick_select_options_visible && quickSlab.includes(amount)) {
      setSelectedAmount(amount);
      setOtherAmount();
    } else {
      setOtherAmount(checkAmount(amount));
      setSelectedAmount(0);
    }
  };

  const getDonationType = () => {
    const { payment_configs: paymentConfigs } = gift.account || {};
    if (!isEmpty(paymentConfigs)) {
      const firstPaymentConfig = paymentConfigs[0];
      if (firstPaymentConfig && (firstPaymentConfig.interval || firstPaymentConfig.interval === null)) {
        const quickSlabs = firstPaymentConfig.quick_select_options.split(',');
        handleDefaultAmount(gift.amount || firstPaymentConfig.default_selected_amount, quickSlabs);
        setDonationType(firstPaymentConfig.interval);
        setLimit(firstPaymentConfig.default_limit);
      }
    }
  };

  const getAllIntervals = () => {
    const { payment_configs: paymentConfigs } = gift.account || {};
    if (!isEmpty(paymentConfigs)) {
      setIntervals(paymentConfigs.map((config) => config.interval));
    }
  };

  useEffect(() => {
    getDonationType();
    getAllIntervals();
  }, [gift]);

  useEffect(() => {
    const slabs = gift.account?.one_time_quick_select_options.split(',') || [];
    if (gift.amount && slabs.indexOf(gift.amount.toString()) === -1) {
      setOtherAmount(gift.amount);
    }
  }, []);

  function selectAmount(amount) {
    setOtherAmount('');
    setSelectedAmount(parseFloat(amount));
  }

  const getPaymentConfigByInterval = (interval) => {
    const { payment_configs: paymentConfigs } = gift.account || {};
    return find(paymentConfigs, (config) => config.interval === interval);
  };

  const getMinimumAmount = () => {
    const config = getPaymentConfigByInterval(donationType);
    if (config) {
      return config.minimum_donation_amount;
    }
    return 1;
  };

  function getMinimumAmountErrorMessage() {
    return (
      <span>
        Minimum donation amount is <CurrencyRenderer currencyId={gift.account?.currency} />
        {getMinimumAmount()}
      </span>
    );
  }

  function donate() {
    if (!selectedAmount && !otherAmount) {
      message.error('Please select or enter amount');
      return;
    }
    if (!gift.account) {
      message.error('No payment method selected for the page');
      return;
    }
    const amount = selectedAmount || otherAmount;
    if (amount < parseFloat(getMinimumAmount())) {
      message.error(getMinimumAmountErrorMessage());
      return;
    }
    gift.funds = [];
    gift.amount = selectedAmount || otherAmount;
    gift.donation_type = donationType === null ? 'one_time' : 'recurring';
    gift.subscription_plan = {
      interval: donationType,
      limit,
    };
    giftDispatch(success(MODULE_GIFT, gift));
    props.proceedToNextStep();
  }

  function setGiftCurrency(currencyId) {
    const account = settings.page.accounts.filter((accountL) => accountL.currency === currencyId)[0];
    gift.account = account;
    giftDispatch(success(MODULE_GIFT, gift));
  }

  function changeOtherAmount(e) {
    if (e.target.value && (isNaN(e.target.value) || parseFloat(e.target.value) < 0)) {
      setOtherAmount(checkAmount(otherAmount) || null);
      return;
    }
    setSelectedAmount(0);
    if (e.target.value) setOtherAmount(checkAmount(e.target.value));
    else setOtherAmount();
  }

  const onChangeDonationType = (value) => {
    const requiredPaymentDetails = getPaymentConfigByInterval(value);
    if (requiredPaymentDetails) {
      const quickSlabs = requiredPaymentDetails.quick_select_options.split(',');
      handleDefaultAmount(gift.amount || requiredPaymentDetails.default_selected_amount, quickSlabs);
      setDonationType(value);
      setLimit(requiredPaymentDetails.default_limit);
    }
  };

  const PAYMENT_INTERVAL_MAPPING = {
    ONE_TIME: null,
    MONTHLY: 'month',
    YEARLY: 'year',
  };

  const PAYMENT_INTERVAL_NAME_MAPPING = {
    [PAYMENT_INTERVAL_MAPPING.ONE_TIME]: 'one-time-donation-tab-label',
    [PAYMENT_INTERVAL_MAPPING.MONTHLY]: 'monthly-recurring-donation-tab-label',
    [PAYMENT_INTERVAL_MAPPING.YEARLY]: 'yearly-recurring-donation-tab-label',
  };

  const getSequenceClassNames = (index, total) => {
    index += 1;
    if (index === 1) {
      return 'first';
    }
    if (index === total) {
      return 'last';
    }
    return 'middle';
  };

  const getKeyByValue = (value, object) => Object.keys(object)[Object.values(object).indexOf(value)];

  const toogleLimitMenuVisibility = () => {
    setShowLimitMenu(!showLimitMenu);
  };

  const handleLimitChange = (key) => {
    if (key === 'no_limit') {
      setLimit(null);
    } else {
      setLimit(key);
    }
    setShowLimitMenu(false);
  };

  const calculateTabIndex = (isPopup = false) => {
    if (showLimitMenu) {
      return isPopup ? 0 : -1;
    }
    return 0;
  };

  const getSupportedLimits = () => {
    const reqPaymentConfig = getPaymentConfigByInterval(donationType) || { supported_limits: [] };
    const { supported_limits: supportedLimits } = reqPaymentConfig;
    return supportedLimits;
  };

  // eslint-disable-next-line react/prop-types, no-shadow
  function LimitOptions({ limit }) {
    const supportedLimits = getSupportedLimits();
    const activeLimit = limit?.toString() || 'no_limit';
    return (
      <div>
        <p className="menu-heading arc-H150 arc-color-black">Select schedule</p>
        <Menu
          selectable
          className="limit-options-menu"
          selectedKeys={[activeLimit]}
          onClick={({ key }) => handleLimitChange(key)}>
          <Menu.Item
            tabIndex={calculateTabIndex(true)}
            onKeyDown={(e) => handleEnterKeyPress(e, () => handleLimitChange('no_limit'))}
            key="no_limit">
            <CurrencyRenderer currencyId={gift.account?.currency} />
            <Statistic value={parseFloat(selectedAmount || otherAmount).toFixed(2)} />
            {`/${donationType === 'month' ? 'mo' : 'yr'} (${
              donationType === 'month' ? 'monthly' : 'yearly'
            } supporter)`}
          </Menu.Item>
          <If condition={!isEmpty(supportedLimits)}>
            {supportedLimits.map((limitL) => (
              <Menu.Item
                tabIndex={calculateTabIndex(true)}
                onKeyDown={(e) => handleEnterKeyPress(e, () => handleLimitChange(limitL))}
                key={limitL}>
                <CurrencyRenderer currencyId={gift.account?.currency} />
                <Statistic value={parseFloat((selectedAmount || otherAmount) * limitL).toFixed(2)} />
                &nbsp;(
                {limitL}
                {donationType === 'month' ? 'mo' : 'yr'})
              </Menu.Item>
            ))}
          </If>
        </Menu>
      </div>
    );
  }

  const shouldShowLimit = () => {
    const { is_pledge_limit_enabled: isPledgeLimitEnabled } = getPaymentConfigByInterval(donationType) || {
      is_pledge_limit_enabled: false,
    };
    return isPledgeLimitEnabled;
  };

  const getMaxHeightStyleForScrollWrapper = () => {
    let baseHeight = 141;
    if (otherAmount || selectAmount) {
      baseHeight = 180;
      if (!isEmpty(getSupportedLimits()) && shouldShowLimit()) {
        baseHeight = 272;
      }
    }
    if (window.innerWidth <= 500)
      return { maxHeight: `calc(100% - ${baseHeight}px)`, height: `calc(100% - ${baseHeight}px)` };
    return {};
  };

  return (
    <div className={`amount-slabs-wrapper ${settings.page.accounts.length > 1 && 'multi-currency'}`}>
      <div
        className="single-fund-limit-menu-drawer-wrapper"
        style={{
          ...getMaxHeightStyleForScrollWrapper(),
          position: (showLimitMenu || window.innerWidth >= 500) && 'relative',
        }}>
        <If condition={settings.page.accounts.length > 1}>
          <CurrencySelector currency={gift.account?.currency} setGiftCurrency={setGiftCurrency} />
        </If>
        <If condition={intervals && intervals.length > 1}>
          <div className="amount-slabs-header">
            <h5
              className="arc-p arc-color-B85 arc-text-align-c modal-title arc-font-weight-medium"
              style={{ marginTop: 0 }}>
              <I18nCustomFormatter id="how-would-you-like-to-give" />
            </h5>
            <Row gutter={window.innerWidth <= 500 ? 0 : 1} className="payment-type-select">
              {intervals.map((interval, id) => (
                <Col
                  key={interval}
                  span={24 / intervals.length}
                  onKeyDown={(e) => handleEnterKeyPress(e, () => onChangeDonationType(interval))}
                  onClick={() => onChangeDonationType(interval)}>
                  <div
                    aria-label={`${props.intl.formatMessage({
                      id: 'how-would-you-like-to-give',
                    })}  ${props.intl.formatMessage({ id: PAYMENT_INTERVAL_NAME_MAPPING[interval] })} ${
                      donationType === interval ? 'Selected' : ''
                    }`}
                    role="button"
                    className={`select-item ${getSequenceClassNames(id, intervals.length)}  ${
                      donationType === interval && 'checked'
                    }`}
                    tabIndex={calculateTabIndex()}>
                    <I18nCustomFormatter id={PAYMENT_INTERVAL_NAME_MAPPING[interval]} />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </If>
        <div className="ammount-slabs-body">
          <If condition={settings.page.are_quick_select_options_visible}>
            <h5 className="arc-p arc-color-B85 arc-text-align-c modal-title arc-font-weight-medium">
              <I18nCustomFormatter
                id="how-would-you-like-to-donate-label"
                values={{
                  interval: donationType && getKeyByValue(donationType, PAYMENT_INTERVAL_MAPPING).toLowerCase(),
                }}
              />
            </h5>
            <CurrencySlabs
              labelPrefix={props.intl.formatMessage(
                { id: 'how-would-you-like-to-donate-label' },
                { interval: donationType && getKeyByValue(donationType, PAYMENT_INTERVAL_MAPPING).toLowerCase() },
              )}
              tabIndex={calculateTabIndex()}
              slabs={getPaymentConfigByInterval(donationType)?.quick_select_options?.split(',') || []}
              selectedAmount={selectedAmount}
              selectAmount={selectAmount}
              currencyId={gift.account?.currency}
              isLocked={gift.isLocked}
            />
          </If>
          <div className="another-amount-wrapper arc-text-align-c">
            <label className="arc-p arc-color-B85 arc-font-weight-medium" style={{ marginTop: 12 }}>
              <I18nCustomFormatter
                id="or-enter-the-amount"
                values={{ options_available: settings.page.are_quick_select_options_visible }}
              />
            </label>
            <Input
              tabIndex={calculateTabIndex()}
              size="large"
              placeholder={anotherAmountPlaceHolder}
              value={otherAmount}
              className="arc-text-align-c"
              readOnly={gift.isLocked}
              {...(gift.isLocked && {
                suffix: (
                  <Tooltip
                    title={
                      <p className="arc-support">
                        You cannot edit this amount. It has been locked by the administrator. For any assistance, please
                        contact {window.collegeEmail}.
                      </p>
                    }>
                    <FontAwesomeIcon icon={faLock} className="arc-color-B25" />
                  </Tooltip>
                ),
              })}
              prefix={<CurrencyRenderer currencyId={gift.account?.currency} />}
              onChange={(e) => changeOtherAmount(e)}
            />
          </div>
        </div>
        <If condition={showLimitMenu}>
          <Drawer
            title={null}
            placement="bottom"
            closable={false}
            onClose={toogleLimitMenuVisibility}
            visible={showLimitMenu}
            getContainer={false}
            className="limit-drawer"
            style={{
              position: 'absolute',
              zIndex: 0,
            }}>
            <LimitOptions limit={limit} />
          </Drawer>
        </If>
      </div>
      <If condition={selectedAmount || otherAmount}>
        <div className={`ammount-slab-info ${settings.page.accounts.length > 1 && 'multi-currency'}`}>
          <div className="ammount-slab-info-item">
            <p className="arc-color-B85 arc-font-weight-bold">
              {(donationType && getKeyByValue(donationType, PAYMENT_INTERVAL_MAPPING)) || 'GIFT'} TOTAL
            </p>
            <div className="arc-color-B85 arc-font-weight-medium">
              <CurrencyRenderer currencyId={gift.account?.currency} />
              <Statistic value={parseFloat(selectedAmount || otherAmount).toFixed(2)} />
            </div>
          </div>
          <If condition={!isEmpty(getSupportedLimits()) && shouldShowLimit()}>
            <div className="ammount-slab-info-item pledge-schedule-item">
              <p className="arc-color-primary arc-font-weight-bold arc-text-uppercase">Pledge schedule</p>
              <div
                tabIndex={0}
                onKeyDown={(e) => handleEnterKeyPress(e, toogleLimitMenuVisibility)}
                onClick={toogleLimitMenuVisibility}>
                <Choose>
                  <When condition={limit === null || (selectedAmount || otherAmount) * limit === 0}>
                    <div className="arc-d-flex arc-flex-v-align-middle arc-color-primary arc-cursor-p">
                      <div className="arc-text-underline mr12 limit-value arc-font-weight-medium">
                        <CurrencyRenderer currencyId={gift.account?.currency} />
                        <Statistic value={parseFloat(selectedAmount || otherAmount).toFixed(2)} />
                        {donationType && (donationType === 'month' ? '/mo' : '/yr')}
                      </div>
                      {!showLimitMenu ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </When>
                  <Otherwise>
                    <div className="arc-d-flex arc-flex-v-align-middle arc-color-primary arc-cursor-p limit-value arc-font-weight-medium">
                      <div className="arc-text-underline mr12">
                        <CurrencyRenderer currencyId={gift.account?.currency} />
                        <Statistic
                          className="arc-text-underline"
                          value={parseFloat((selectedAmount || otherAmount) * limit).toFixed(2)}
                        />
                        {donationType && (donationType === 'month' ? `(${limit}mo)` : `(${limit}yr)`)}
                      </div>
                      {!showLimitMenu ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </Otherwise>
                </Choose>
              </div>
            </div>
          </If>
        </div>
      </If>
      <div className="payment-footer">
        <Button type="primary" size="large" onClick={() => donate()}>
          <I18nCustomFormatter id="proceed-btn-label" />
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(AmountSlabs);
