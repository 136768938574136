import React, { useEffect } from 'react';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { Alert, Button } from 'antd';
import { success } from "./../../../actionCreators";
import { MODULE_GIFT } from "./../../../constants";
import { GiftConsumer } from "./../../../context/gift";
import { SettingsConsumer } from "./../../../context/settings";
import "./style.scss";

function FailedPayment(props) {
    const [settings, dispatch] = SettingsConsumer();
    const [gift, giftDispatch] = GiftConsumer();

    function proceedToNextStep() {
        let blankGift = { funds: [] };
        for (var i = 0; i < settings.page.accounts.length; i++) {
            if (settings.page.accounts[i].account.id === window.giftData.transaction.account.id) {
                blankGift['account'] = settings.page.accounts[i];
                break;
            }
        }
        giftDispatch(success(MODULE_GIFT, blankGift));
        window.giftData = '';
        props.proceedToNextStep();
    }

    return (
        <div className="failed-payment-wrapper">
            <div style={{ height: 160, width: '100%' }}></div>
            <div className="details-wrapper">
                <p className="arc-H300 arc-color-black arc-text-align-c m16">
                    <I18nCustomFormatter id="failed-payment-title"></I18nCustomFormatter>
                </p>
                <p className="arc-p arc-text-align-c m16">
                    <I18nCustomFormatter id="failed-payment-subtitle"></I18nCustomFormatter>
                </p>
                <Alert
                    message={<p className="arc-support arc-text-align-c arc-error-color arc-font-weight-bold"><I18nCustomFormatter id="reason-label"></I18nCustomFormatter></p>}
                    description={<p className="arc-support arc-text-align-c arc-error-color">{window.giftData.transaction.failure_reason}</p>}
                    type="error"
                    className="m24 arc-error-b arc-error-bg arc-error-alert-br"
                />
                <div className="payment-footer">
                    <Button type="primary" size={"large"} onClick={() => proceedToNextStep()}>
                        {<I18nCustomFormatter id="retry-btn-label" />}
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default FailedPayment;