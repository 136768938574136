import { PROPERTY_OPTION_API_PATH } from 'Src/common/endpoints';
import instance from 'Src/common/utilities/axios_util';
import { FIELD_WITH_SKIP_PAGINATION_FOR_CHOICES } from 'Src/common/utilities/form_utils';

export const lookAndAddAutoFillOptions = async (result) => {
  const indexArray = [];
  let indexCount = 0;
  const apisStacks = [];
  try {
    for (const field of result) {
      const { choice_source_identifier: choiceSourceIdentifier, type } = field;
      if (choiceSourceIdentifier) {
        if (FIELD_WITH_SKIP_PAGINATION_FOR_CHOICES.includes(type)) {
          const url = PROPERTY_OPTION_API_PATH.replace('{}', choiceSourceIdentifier).concat('&skip_pagination=true');
          apisStacks.push(instance.get(url));
          indexArray.push(indexCount);
        }
      }
      indexCount++;
    }
    if (apisStacks.length > 0) {
      const responses = await Promise.all(apisStacks);
      if (responses) {
        for (indexCount = 0; indexCount < responses.length; indexCount++) {
          result[indexArray[indexCount]].options = responses[indexCount].data.data.map(({ label, value }) => ({
            label,
            value: value.toString(),
          }));
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const lookAndAddAutoFillOptionsWithNoPagination = async (result) => {
  const indexArray = [];
  let indexCount = 0;
  const apisStacks = [];
  try {
    for (const field of result) {
      const { choice_source_identifier: choiceSourceIdentifier } = field;
      if (choiceSourceIdentifier) {
        const url = PROPERTY_OPTION_API_PATH.replace('{}', choiceSourceIdentifier).concat('&skip_pagination=true');
        apisStacks.push(instance.get(url));
        indexArray.push(indexCount);
      }
      indexCount++;
    }
    if (apisStacks.length > 0) {
      const responses = await Promise.all(apisStacks);
      if (responses) {
        for (indexCount = 0; indexCount < responses.length; indexCount++) {
          result[indexArray[indexCount]].options = responses[indexCount].data.data.map(({ label, value }) => ({
            label,
            value: value.toString(),
          }));
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
};
