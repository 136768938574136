import React from 'react';
import { string, bool, node } from 'prop-types';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Avatar, Tooltip } from 'antd';
import './style.scss';

const ThemeXProfileDetailItem = ({
  title,
  isRegistered,
  src,
  shape,
  size,
  icon,
  url,
  hideProfileAttributes,
  extraProfileAttributes,
  extra,
  action,
  doNotShowAvatar = false,
}) => {
  return (
    <div className="profile-detail-item">
      {!doNotShowAvatar && <Avatar src={src} icon={<LegacyIcon type={icon} />} shape={shape} size={size} alt={title} />}
      <div className="profile-detail-item-content">
        <p className="arc-text-ellipsis">
          <Choose>
            <When condition={url}>
              <a
                href={url}
                target="_blank"
                className="arc-H200 profile-item-title arc-focus-outline"
                aria-label={`${title}. ${isRegistered ? 'registered user' : ''}`}>
                {title}
              </a>
            </When>
            <Otherwise>
              <span className="arc-H200 profile-item-title">{title}</span>
            </Otherwise>
          </Choose>
          <If condition={!hideProfileAttributes}>
            <span className="profile-attribute-icons">
              {isRegistered && (
                <Tooltip title="Verified user" mouseEnterDelay={0.5} aria-hidden={true}>
                  <CheckCircleOutlined className="profile-registered-attribute" />
                </Tooltip>
              )}
              {extraProfileAttributes}
            </span>
          </If>
        </p>
        <If condition={extra}>
          <Choose>
            <When condition={typeof extra === 'string'}>
              <p
                className="arc-p profile-detail-item-extra arc-color-B55 arc-text-ellipsis"
                dangerouslySetInnerHTML={{
                  __html: extra,
                }}
              />
            </When>
            <Otherwise>{extra}</Otherwise>
          </Choose>
        </If>
        <If condition={action}>
          <div className="action-container mt8">{action}</div>
        </If>
      </div>
    </div>
  );
};

ThemeXProfileDetailItem.propTypes = {
  title: string.isRequired,
  isRegistered: bool.isRequired,
  icon: string,
  src: string,
  shape: string,
  size: string,
  url: string,
  hideProfileAttributes: bool,
  extraProfileAttributes: node,
  extra: node,
  action: node,
};

ThemeXProfileDetailItem.defaultProps = {
  src: null,
  icon: 'user',
  shape: 'circle',
  size: 'default',
  url: null,
  hideProfileAttributes: false,
};

export default ThemeXProfileDetailItem;
