import React from "react";
import { Col, Row, Button } from "antd";
import CurrencyRenderer from "Src/common/components/currencyRenderer";
import "./style.scss";
import { find } from "lodash";
import { CURRENCIES } from "../../../../../common/constants";

function CurrencySlabs(props) {
  const getCurrencyIsoCode = () => find(CURRENCIES, ({ id }) => id === props.currencyId)?.iso_code || ""
  return (
    <div className="amount-slabs">
      <Row gutter={16} type="flex">
        {props.slabs.map((slab, index) => {
          return (
            <Col span={8} key={`amount-slab-${index}`}>
              <Button
                aria-label={`${props.labelPrefix || ""} ${slab} ${getCurrencyIsoCode()} ${(parseFloat(slab) === parseFloat(props.selectedAmount) ? "selected" : "")}`}
                tabIndex={props.tabIndex}
                size={"large"}
                className={
                  `arc-btn-subtle amount-btn full-width mt8 mb8 ${(parseFloat(slab) === parseFloat(props.selectedAmount) ? "selected" : "")} ${props.isLocked ? 'locked' : ''}`

                }
                onClick={() => props.selectAmount(slab)}
              >
                <CurrencyRenderer currencyId={props.currencyId} /> {slab}
              </Button>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default CurrencySlabs;
