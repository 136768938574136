import React from 'react';
import { Card, Skeleton } from 'antd';

import './style.scss';

const TributeModalLoadingCard = () => {
  return (
    <Card bordered={false} className="tribute-modal-loader">
      <Skeleton.Input active />
      <Skeleton.Input active className="skeletal-card-bottom" />
    </Card>
  );
};

export default TributeModalLoadingCard;
