import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Root from './root';
import store from './redux/store';
import 'Src/common/arc.scss';
import './style.scss';

if (document.getElementById('root')) {
  ReactDOM.render(
    <Provider store={store}>
      <Root />
    </Provider>,
    document.getElementById('root'),
  );
}
