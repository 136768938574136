export const createMockGift = function(giftObj) {
    return {
        'id': '_' + Math.random().toString(36).substr(2, 9),
        'donor_name': giftObj.donor_name,
        'donor_email': giftObj.donor_email,
        'donor_phone_number': giftObj.donor_phone_number,
        'donor_address_line1': giftObj.donor_address_line1,
        'donor_address_line2': giftObj.donor_address_line2,
        'donor_city': giftObj.donor_city
    }
}