/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import './style.scss';

function handleEnterKeyPress(event, cb) {
  if (event.key === 'Enter') {
    cb();
  }
}

function NavigationFooter({ onNext, onPrev, prevDisabled, nextDisabled, id = undefined }) {
  return (
    <div className="navigation-footer">
      <a
        role="button"
        tabIndex={0}
        onKeyDown={(e) => handleEnterKeyPress(e, () => onPrev(id))}
        className={`navigation-link arc-color-primary ${prevDisabled ? 'disabled' : ''}`}
        onClick={() => onPrev(id)}
        disabled={prevDisabled}>
        <FontAwesomeIcon icon={faArrowLeft} className="mr8" />
        <I18nCustomFormatter id="prev-label" defaultMessage="Prev" />
      </a>
      <a
        role="button"
        tabIndex={0}
        className={`navigation-link arc-color-primary ${nextDisabled ? 'disabled' : ''}`}
        onClick={() => onNext(id)}
        onKeyDown={(e) => handleEnterKeyPress(e, () => onNext(id))}
        disabled={nextDisabled}>
        <I18nCustomFormatter id="next-label" defaultMessage="Next" />
        <FontAwesomeIcon icon={faArrowRight} className="ml8" />
      </a>
    </div>
  );
}

export default NavigationFooter;
