/* eslint-disable function-paren-newline */
/* eslint-disable max-statements */
/* eslint-disable complexity */

export const getModalBodyHeight = () => {
  const fixedElement = document.getElementsByClassName('themex-modal-fixed-block')[0];
  const modalHeaderElement = document.getElementsByClassName('ant-modal-header')[0];
  const vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const modalHeaderElementHeight = (modalHeaderElement && modalHeaderElement.offsetHeight) || 0;
  const fixedElementHeight = fixedElement.offsetHeight;
  return vH - fixedElementHeight - modalHeaderElementHeight;
};

export const getMetaContent = (metaName) => {
  const metaTags = document.getElementsByTagName('meta');
  for (let i = 0; i < metaTags.length; i++) {
    if (metaTags[i].getAttribute('name') === metaName || metaTags[i].getAttribute('property') === metaName) {
      return metaTags[i].getAttribute('content');
    }
  }
  return '';
};

export const copyToClipboard = (text) => {
  const dummyInput = document.createElement('input');
  document.body.appendChild(dummyInput);
  dummyInput.value = text;
  dummyInput.select();
  document.execCommand('copy');
  document.body.removeChild(dummyInput);
};

export const getCommunityMembersText = (memberCount) => {
  if (memberCount) {
    if (memberCount === 1) {
      return '1 member';
    }

    return `${memberCount} members`;
  }
  return '';
};

export const getRandomCoverImage = () => {
  const coverImages = [
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_1.png`,
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_2.png`,
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_3.png`,
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_4.png`,
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_5.png`,
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_6.png`,
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_7.png`,
    `${window.staticPath}img/react-modules/empty-cover-images/empty_cover_8.png`,
  ];
  return coverImages[Math.floor(Math.random() * coverImages.length)];
};

export const getImagePath = (path, dimension) => {
  let pathL = path;
  const imgName = pathL.split('/').slice(-1)[0];
  const splittedImgName = imgName.split('.');
  pathL = pathL.replace(imgName, `${splittedImgName[0]}_${dimension}.${splittedImgName[1]}`);
  return pathL;
};

export const getSrcFromSrcSet = (srcSet, src) => {
  if (!srcSet) {
    return src;
  }
  const windowWidth = window.innerWidth;
  const splittedSrcSet = srcSet.split(',');
  if (windowWidth <= 360) {
    return splittedSrcSet[0].trim().split(' ')[0];
  }
  if (windowWidth > 360 && windowWidth <= 768) {
    return splittedSrcSet[1].trim().split(' ')[0];
  }
  return splittedSrcSet[2].trim().split(' ')[0];
};

export const getFallbackImage = () => `${window.staticPath}img/fallback.png`;

export const lightenDarkenColor = (color, amount) =>
  `#${color
    .replace(/^#/, '')
    .replace(/../g, (colorL) =>
      `0${Math.min(255, Math.max(0, parseInt(colorL, 16) + amount)).toString(16)}`.substr(-2),
    )}`;

/**
 * Takes Hex and Opacity as input and convert to Gex
 */
export const HexToHexOpacity = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');
  let opacityL = opacity;

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacityL > 1 && opacityL <= 100) {
    opacityL /= 100;
  }

  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  opacityL = Math.round(opacityL * 255).toString(16);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;
  if (opacityL.length === 1) opacityL = `0${opacityL}`;

  return `#${r}${g}${b}${opacityL}`;
};

/**
 * Handle enter key press
 */
export function handleEnterClick(event, func) {
  if (event.key === 'Enter') {
    func();
  }
}
