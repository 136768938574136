const AuthHelper = (() => {
  let authToken = null;
  function setToken(val) {
    authToken = val;
  }

  return {
    set: val => {
      setToken(val);
    },
    token: () => {
      return authToken;
    },
  };
})();

export default AuthHelper;
