import React, {useState, useEffect, useRef} from "react";
import { Button } from "antd";
import moment from 'moment-timezone';
import "./style.scss";

const getDateTimeDiff = (dateTime) => {
    let diff = moment.parseZone(dateTime).unix() - moment.parseZone().unix();
    return diff > 0 ? diff : 0;
}

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
        let id = null;
        function tick() {
            if (!savedCallback.current()) {
                clearInterval(id);
            };
        }
        if (delay !== null) {
            id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const getSeconds = (diff) => Math.trunc(diff) % 60;

const getMintues = (diff) => Math.trunc(diff / 60) % 60;

const getHours = (diff) => Math.trunc(diff / 3600) % 24;

const getDays = (diff) => Math.trunc(diff / (24 * 3600));


function CampaignDonateCountDownBlock (props) {
    const [diff, setDiff] = useState(getDateTimeDiff(props.dateTime));

    useInterval(()=>{
        if (diff === 0) {
            return 0;
        }
        setDiff(diff - 1);
        return 1;
    }, 1000);
    
    function renderTime() {
        //TODO: look better way
        if (diff < 60) {
            return ` ${getSeconds(diff)}s Left`;
        } else if (diff < (60 * 60)) {
            return `${getMintues(diff)}m ${getSeconds(diff)}s Left`;
        } else if (diff < (24 * 60 * 60)) {
            return `${getHours(diff)}h ${getMintues(diff)}m ${getSeconds(diff)}s Left`;
        } else if (diff) {
            return `${getDays(diff)}d ${getHours(diff)}h ${getMintues(diff)}m ${getSeconds(diff)}s Left`;
        }

        return "0s Left";
    }



    return (
        <div className={ `donate-btn-countdown-wrapper ${props.hideBtn ? "hide" : "show"}`}>
            <Button
                type="primary"
                className="donate-btn"
                size="large"
                onClick={() => props.openPaymentModal()}
                >
                <p className="action-text arc-H200 arc-color-white">
                    {props.callToActionText || "Make a gift"}
                </p>
                <If condition={props.dateTime}>
                    <p className="count-down-text arc-p arc-color-white">
                        { renderTime() }
                    </p>
                </If>
                
            </Button>
        </div>
    )
}

export default CampaignDonateCountDownBlock;