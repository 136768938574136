import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Custom i18n formatter, adds id as i18nkey to span
 * @param {*} props
 */
function I18nCustomFormatter(props) {
  return <FormattedMessage {...props}>{(txt) => <span i18nkey={props.id}>{txt}</span>}</FormattedMessage>;
}

export default I18nCustomFormatter;
