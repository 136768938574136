import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { sortBy } from 'lodash';
import instance from 'Src/common/utilities/axios_util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { CHALLENGE_API_PATH } from '../../endpoints';
import { SettingsConsumer } from '../../context/settings';

import './style.scss';

function ChallengeCard() {
  const [settings] = SettingsConsumer();
  const [challenges, setChallenges] = useState([]);
  const [sortedChallenges, setSortedChallenges] = useState(challenges);

  function getChallenges() {
    instance.get(CHALLENGE_API_PATH.replace('{}', settings.page.id)).then((response) => {
      const result = response.data?.results?.filter((challenge) => challenge.is_listed);
      setChallenges(
        sortBy(result, function (a) {
          return a.display_order;
        }),
      );
    });
    // TODO: error handling
  }

  useEffect(() => {
    getChallenges();
  }, []);

  useEffect(() => {
    const challengeIsNotCompleted = challenges.filter((challenge) => !challenge.is_completed && challenge.is_listed);
    const challengeIsCompleted = challenges.filter((challenge) => challenge.is_completed && challenge.is_listed);

    setSortedChallenges([...challengeIsNotCompleted, ...challengeIsCompleted]);
  }, [challenges]);

  return (
    <If condition={challenges && challenges.length}>
      <Col span={24}>
        <div className="section-title">Challenges</div>
        <Row id="challenges-wrapper">
          <Col span={24}>
            <Card
              bordered={false}
              className="campaign-details-block arc-card-box-shadow arc-card-border-radius fr-view">
              {sortedChallenges.map((challenge, idx) => (
                <>
                  <Row key={challenge.id} className={`challenge-row ${challenge.is_completed ? 'completed' : ''}`}>
                    <Col span={24}>
                      <Row>
                        <If condition={challenge.is_completed}>
                          <Col span={24}>
                            <div className="completed-status arc-support arc-color-black arc-text-align-">
                              <FontAwesomeIcon className="mr8" icon={faCircleCheck} />
                              Completed
                            </div>
                          </Col>
                        </If>
                        <Col span={24}>
                          <div className="challenge-name arc-H200 arc-color-primary">{challenge.name}</div>
                        </Col>
                        <Col span={24}>
                          <div
                            className="challenge-description arc-p arc-color-B55"
                            dangerouslySetInnerHTML={{
                              __html: challenge.description,
                            }} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <If condition={sortedChallenges.length - 1 !== idx}>
                    <div className="cs-divider" />
                  </If>
                </>
              ))}
            </Card>
          </Col>
        </Row>
      </Col>
    </If>
  );
}

export default ChallengeCard;
