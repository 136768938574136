import axios from 'axios';
import { walkErrorObj } from './data_util';
import Notification from '../components/errorNotification';
import AuthHelper from 'Src/common/factories/auth';

const API_BASE = '/api/v1/';

const axiosInstance = axios.create({
  baseURL: API_BASE,
  timeout: 25000,
  headers: {
    'Content-type': 'application/json',
    'X-CSRFToken': window.csrfToken,
  },
});

// request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // This is is super duper hack but this is how we handle generic code for now
    // so we have two auth token now, jwt and csrf and according to config passed we will append it to request
    if (config.jwtToken) {
      config.headers.authorization = `Bearer ${config.jwtToken}`;
      delete config.jwtToken;
    }
    if (config.csrfToken) {
      config.headers['X-CSRFToken'] = config.csrfToken;
      delete config.csrfToken;
    }
    let authToken = AuthHelper.token();
    if (authToken) {
      config.headers.authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  error =>
    // Do something with request error
    Promise.reject(error),
);

axiosInstance.interceptors.response.use(undefined, error => {
  const { response } = error;
  if (error) {
    if (response && !response.config.hideNotification) {
      let overrideDefault = false;
      let errorText = '';
      if (response.status === 400 || response.status === 401) {
        overrideDefault = true;
        errorText = walkErrorObj(response.data);
      } else {
        errorText = response.data.detail;
      }

      Notification({
        errorCode: response.status,
        responseRequestId: response.request && response.request.getResponseHeader('X-Request-Id'),
        errorText,
      });
    }
    return Promise.reject(error);
  }
});

export default axiosInstance;
