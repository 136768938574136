import { FETCHED_PAYMENT_LINK } from "./../constants";

export const paymentLinkReducer = (state, action) => {
  switch (action.type) {
    case FETCHED_PAYMENT_LINK:
      return { paymentLink: action.response.paymentLink };

    default:
      return state;
  }
};
