import React, { useState } from 'react';
import { copyToClipboard } from 'Src/common/utilities/dom_utils';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

function LinkBlock({ title, url }) {
  const [showCopiedMsg, toggleCopiedMsg] = useState(false);

  function Copied(url) {
    copyToClipboard(url);
    toggleCopiedMsg(true);
    setTimeout(() => {
      toggleCopiedMsg(false);
    }, 3000);
  }

  return (
    <div className="link-block-wrapper">
      <If condition={title}>
        <div className="link-block-header arc-H100 arc-color-B45">{title}</div>
      </If>
      <div
        className={`link-block-body ${showCopiedMsg ? 'show-msg' : ''}`}
        onClick={() => Copied(url)}>
        <span className="link-icon">
          <FontAwesomeIcon icon={faCopy} />
        </span>
        <span className="link-url arc-support arc-text-ellipsis arc-color-B85">{url}</span>
      </div>
      <div className={`link-msg arc-support ${showCopiedMsg ? 'show' : ''}`}>
        <FontAwesomeIcon icon={faCheck} className="mr8" />
        <I18nCustomFormatter id="link-copied-to-clipboard" defaultMessage="Link copied to clipboard!" />
      </div>
    </div>
  );
}

export default LinkBlock;
