import React, { useEffect, useState } from "react";
import { Emoji } from 'emoji-mart'
import { times, shuffle } from "lodash"

import './animationBlock.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ALLOWED_SIZES = [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46]
const ALLOWED_ANIMATION_DELAYS = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]


function AnimationBlock({reaction}) {

    const [leftData, setLeftData] = useState([])
    const [sizeData, setSizeData] = useState([])
    const [delayData, setDelayData] = useState([])
    const [unmounted, setUnmounted] = useState(false)

    function rand( from, to ) {
        return Math.floor(Math.random()*(to-from+1)+from);
    }

    function randomPos( from, to, inc, amount ) {
        var retArray = [rand(from,to)]; // sets the first element
        var temp = null;
    
        for ( var x = 0; x < amount-1; x++ ) {
            do {
                temp = rand( from, to );
            } while ( Math.abs( temp - retArray[x] ) <= inc );
    
            retArray.push( temp );
        }
    
        return retArray;
    }

    useEffect(()=>{
        for(let i=0; i< 7; i++) {
            sizeData.push(ALLOWED_SIZES[Math.floor(Math.random() * ALLOWED_SIZES.length)])
            delayData.push(ALLOWED_ANIMATION_DELAYS[Math.floor(Math.random() * ALLOWED_ANIMATION_DELAYS.length)])
        }
        const randomLeftPos = randomPos(1, 80, 11, 7)
        setLeftData(randomLeftPos)
        setDelayData(delayData)
        setSizeData(sizeData)
        setTimeout(()=>{
            // This is how you use and throw
            // We are showing an animation and then unmounting it
            setUnmounted(true)
        }, 4000)
    }, [reaction])

    return (
            !unmounted && 
            <div className="animation-container">
                {
                    leftData.map((val, index)=>{
                        return(
                            <div className={`emoji emoji-item-${index+1}`} style={{ left: `${val}%`, animationDelay: `${delayData[index]}s`}}><FontAwesomeIcon icon={reaction} style={{fontSize : sizeData[index] }}/></div>
                        )
                    })
                }
            </div>
    )    
}

export default AnimationBlock;