/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Input, Button, message, Checkbox, Divider, Radio, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { isEmpty, findIndex } from 'lodash';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import { GiftConsumer } from 'Src/alumniGiving/context/gift';
import { announceToScreenReader } from 'Src/common/utilities/a11y_util';
import { PaymentLinkConsumer } from 'Src/alumniGiving/context/paymentLink';
import CustomFormItem from 'Src/common/components/customFormItem';
import instance from 'Src/common/utilities/axios_util';
import { getFormValue, processLocationFieldAndExtractLocationValues } from 'Src/common/utilities/form_utils';
import initializeCaptchaV2 from 'Src/common/utilities/captcha_util';
import { CREATE_GIFT_API_PATH, PAGE_API, DONOR_COVERING_CHARGES_API } from 'Src/alumniGiving/endpoints';
import { fetchPage } from 'Src/alumniGiving/actions/settings';
import { MODULE_PAGE, THANK_YOU_PAGE, MODULE_GIFT, MODULE_PAYMENT_LINK } from 'Src/alumniGiving/constants';

import { success } from 'Src/alumniGiving/actionCreators';
import { GIVING_GIFT_SUCCESS_PAYMENT_PATH } from 'Src/alumniGiving/routes';
import { createMockGift } from 'Src/alumniGiving/common/utils/gift_mock';
import { isFieldVisible, isFieldRequired } from 'Src/alumniGiving/common/utils/helper';
import queryString from 'query-string';

import PhoneNumberInput from 'Src/common/components/formItem/PhoneNumberInput';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setCampaignIdAction, setCampaignHubFunds, setSelectedCampaignData } from '../../../redux/reducers/campaignHub';
import store from '../../../redux/store';
import '@ant-design/compatible/assets/index.css';
import './style.scss';
import { useSelector } from 'react-redux';

let giftData = {};
let mode = null;

function PrePaymentForm(props) {
  const [settings, dispatch] = SettingsConsumer();

  const selectedCampaignId = useSelector((state) => state.campaignDataReducer.selectedCampaignId);
  const campaignHubFunds = useSelector((state) => state.campaignDataReducer.campaignHubFunds);

  const [paymentLink, paymentLinkDispatch] = PaymentLinkConsumer();
  const [gift, giftDispatch] = GiftConsumer();
  const { getFieldDecorator } = props.form;
  const [isLoading, setIsLoading] = useState(false);
  const [isDCCEnabled, setIsDCCEnabled] = useState(false);
  const [dccCharges, setDccCharges] = useState(0);
  const [toastVisible, setToastVisible] = useState(false);
  const [isGiftAidSelected, setGiftAidSelected] = useState(null);
  const queryS = queryString.parse(props.location.search);
  if (!isEmpty(queryS)) {
    mode = queryS.mode;
  }
  const fullNamePlaceHolder = props.intl.formatMessage({
    id: 'prepayment-form-fullname-placeholder',
  });
  const emailPlaceHolder = props.intl.formatMessage({
    id: 'prepayment-form-email-placeholder',
  });
  const phonePlaceHolder = props.intl.formatMessage({
    id: 'prepayment-form-phone-placeholder',
  });
  const addressLine1PlaceHolder = props.intl.formatMessage({
    id: 'prepayment-form-address-line1-placeholder',
  });
  const addressLine2PlaceHolder = props.intl.formatMessage({
    id: 'prepayment-form-address-line2-placeholder',
  });
  const cityPlaceHolder = props.intl.formatMessage({
    id: 'prepayment-form-city-placeholder',
  });
  const zipCodePlaceHolder = props.intl.formatMessage({
    id: 'prepayment-form-zipcode-placeholder',
  });

  useEffect(() => {
    const placeInput = document.getElementById('donor_city');
    if (placeInput) {
      const options = {
        types: ['geocode'],
      };
      const places = new window.google.maps.places.Autocomplete(placeInput, options);
      window.google.maps.event.addListener(places, 'place_changed', () => {
        getLatLng(places);
      });
      setTimeout(() => {
        placeInput.setAttribute('autocomplete', 'new-password');
      }, 2000);
    }

    // get DCC charges
    getDonorCoveringCharges();
    return () => {
      giftData = {};
    };
  }, []);

  const resetCampaignHub = () => {
    store.dispatch(setCampaignIdAction(null));
    store.dispatch(setCampaignHubFunds(null));
    store.dispatch(setSelectedCampaignData(null));
  };

  function getPaymentLink(pageId, paymentLinkId) {
    return instance.get(`/givingx/${pageId}/links/${paymentLinkId}/`);
  }

  function getLatLng(place) {
    const googlePlace = place.getPlace();
    const d = {
      donor_city_place: {
        gpid: googlePlace.place_id,
        name: googlePlace.formatted_address,
      },
    };
    d.donor_city = googlePlace.formatted_address;
    props.form.setFieldsValue(d);
  }

  function paymentSuccessful(giftData) {
    setIsLoading(false);
    if (window.fbq) {
      fbq('track', 'Donate');
    }

    if (window.gtag && window.googleConversionTrackingConfig) {
      gtag('event', 'conversion', {
        send_to: window.googleConversionTrackingConfig.send_to,
        transaction_id: giftData.transaction && giftData.transaction.id,
        value: giftData.amount,
        page: giftData.page && giftData.page.name,
      });
    }

    giftDispatch(success(MODULE_GIFT, giftData));
    fetchPage(PAGE_API.replace('{}', settings.page.id)).then((response) => {
      dispatch(success(MODULE_PAGE, { page: response.data }));
    });
    if (!isEmpty(paymentLink.data)) {
      paymentLinkDispatch(success(MODULE_PAYMENT_LINK, { paymentLink: {} }));
    }
    // giftDispatch(success(MODULE_GIFT, giftData));
    props.proceedToNextStep();
    props.history.push(
      GIVING_GIFT_SUCCESS_PAYMENT_PATH.replace(':slug', settings.page.slug).replace(':giftId', giftData.id),
      { step: THANK_YOU_PAGE },
    );
    resetCampaignHub();
  }

  function paymentFailed() {
    message.error('Payment failed, Please try again. If issue persists contact us.');
    resetCampaignHub();
    setIsLoading(false);
  }

  function checkoutCloseCallback() {
    // Do something
    setIsLoading(false);
  }

  function validateFullName(rule, value, callback) {
    value = value && value.trim();
    if (value) {
      const regex =
        /^[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*( [a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*)+$/;
      if (!regex.test(value.toLowerCase())) {
        callback('Please enter your full name');
      }
    }
    callback();
  }

  function validateCity(rule, value, callback) {
    value = value && value.trim();
    if (value && !props.form.getFieldValue('donor_city_place')) {
      callback(
        'Please pick a location from the list. If you are unable to view the list, kindly erase and re-enter your location.',
      );
    }
    callback();
  }

  function getDonorCoveringCharges() {
    let { funds, amount, donation_type } = gift;
    const { account, is_donor_covering_charges_enabled, currency, is_donor_covering_charges_by_default } =
      gift.account || {};
    const C_USD = 1;

    if (!is_donor_covering_charges_enabled || currency !== C_USD || donation_type === 'recurring') {
      return 0;
    }

    if (funds && funds.length) {
      amount = 0;
      funds.forEach((orderedFund) => {
        if (orderedFund.fund.amount) {
          amount += parseFloat(orderedFund.fund.amount);
        }
      });
    }

    if (is_donor_covering_charges_enabled && is_donor_covering_charges_by_default) {
      setIsDCCEnabled(true);
      props.form.setFieldsValue({ is_donor_covered_charges: true });
    }

    instance.post(DONOR_COVERING_CHARGES_API.replace('{}', account.id), { amount }).then(({ data }) => {
      setDccCharges(data.charges);
    });
  }

  function updateSplitAmountWithDccCharge(split, amount, dccCharges) {
    const splitLength = split.length;
    let remaining = 0;
    const charges = parseFloat(parseFloat(dccCharges).toFixed(2));
    const actualAmount = parseFloat(amount);
    const totalAmount = actualAmount + charges;

    if (splitLength > 0) {
      if (splitLength > 1) {
        for (let index = 0; index < splitLength - 1; index++) {
          split[index].amount = parseFloat((totalAmount * parseFloat(split[index].amount)) / actualAmount).toFixed(2);
          remaining += parseFloat(split[index].amount);
        }
      }
      // last element
      split[splitLength - 1].amount = parseFloat(totalAmount - remaining).toFixed(2);
    }
    return split;
  }

  const getPageFund = () => {
    if (selectedCampaignId) {
      return campaignHubFunds || [];
    }
    return settings.page.ordered_funds || [];
  };

  function transformFormData(formData) {
    let values = JSON.parse(JSON.stringify(formData));
    const { newValues, locations } = processLocationFieldAndExtractLocationValues(values);
    values = newValues;
    // convert data which is acceptable for gift serializer
    if (settings.page.pre_payment_form && settings.page.pre_payment_form.id) {
      values.pre_payment_form_entry = {
        form_id: settings.page.pre_payment_form.id,
        field_entries: props.prePaymentCustomFields
          .filter((field) => typeof values[field.id] !== 'undefined')
          .map((field) => {
            let fieldVal = {
              value: getFormValue(values[field.id]),
              field_id: field.id,
            };
            if (typeof locations[field.id] !== 'undefined') {
              fieldVal = { ...fieldVal, ...locations[field.id] };
            }
            return fieldVal;
          }),
      };
      props.prePaymentCustomFields.forEach((field) => {
        delete values[field.id];
      });
    }

    values = { ...gift, ...values };
    if (values.id) {
      delete values.id;
    }
    const { account } = values.account || {};
    const { currency } = values.account || {};
    delete values.account;
    values.account = account;
    values.currency = currency;
    const { funds } = values;
    delete values.funds;
    values.splits = [];
    let otherFundName = null;
    let isDonationToOtherFund = false;
    if (funds && funds.length) {
      let totalAmount = 0;
      funds.forEach((orderedFund) => {
        if (orderedFund.fund.amount) {
          if (orderedFund.fund.type) {
            isDonationToOtherFund = true;
            otherFundName = orderedFund.fund.name;
          }
          totalAmount += parseFloat(orderedFund.fund.amount);
          values.splits.push({
            fund: {
              id: orderedFund.fund.id,
              name: orderedFund.fund.name,
              type: orderedFund.fund.type,
            },
            amount: orderedFund.fund.amount,
          });
        }
      });
      values.amount = Math.round((totalAmount + Number.EPSILON) * 100) / 100;
    }
    if (otherFundName) {
      values.other_fund_name = otherFundName;
    }
    // if there is only 1 fund
    const availableFund = getPageFund();
    if (availableFund.length === 1) {
      values.splits.push({
        fund: {
          id: availableFund[0].fund.id,
          name: availableFund[0].fund.name,
        },
        amount: values.amount,
      });
    }
    if (isDCCEnabled && dccCharges && parseFloat(dccCharges) > 0) {
      values.splits = updateSplitAmountWithDccCharge(values.splits, values.amount, dccCharges);
      values.amount = (parseFloat(values.amount) + parseFloat(dccCharges)).toFixed(2);
    }
    values.status = 'started';
    if (!isEmpty(paymentLink.data)) {
      values.payment_link = {
        id: paymentLink.data.id,
      };
    }

    if (!values.donor_pays_tax) {
      delete values.donor_pays_tax;
    }

    // if any campaign is selected than add that id
    if (selectedCampaignId) values.campaign = selectedCampaignId;
    return values;
  }

  function createGift(formData, captchaFailed) {
    setIsLoading(true);
    announceToScreenReader(`Loading`);
    instance
      .post(CREATE_GIFT_API_PATH.replace('{}', settings.page.id), formData, { hideNotification: true })
      .then((response) => {
        giftData = response.data;
        const paymentConfig = response.data.payment_config;
        if (Payment.isPaymentConfigValid(paymentConfig).isValid) {
          Payment.setConfiguration(true);
          Payment.handler(paymentConfig).then((handler) => {
            Payment.setCallbacks(() => paymentSuccessful(giftData), paymentFailed, checkoutCloseCallback);
            Payment.makePayment(handler, {
              ...paymentConfig,
              donor_city: response?.data?.donor_city || '',
              donor_zip_code: response?.data?.donor_zip_code || '',
              isBBMSTippingEnabled: window.isBBMSTippingEnabled,
              region: window.siteRegion,
            });
          });
        } else {
          // TODO: figure out how to handle errors on front end
        }
      })
      .catch((err) => {
        resetCampaignHub();
        setIsLoading(false);

        if (err.response && err.response.status === 400 && err.response.data.detail === 'Captcha Low Score') {
          const captchaId = initializeCaptchaV2((token) => {
            formData['captcha_token'] = token;
            formData['captcha_v2'] = true;

            return createGift(formData);
          });
        }
      });
  }

  function validate(token) {
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        message.error(
          'A few more fields need to be filled out. Try scrolling through the form to view all remaining fields.',
        );
        return;
      }
      const formData = transformFormData(values);
      formData['captcha_token'] = token;
      if (!mode) {
        if (!isEmpty(paymentLink) && !isEmpty(paymentLink.data)) {
          getPaymentLink(settings.page.id, paymentLink.data.id)
            .then((res) => {
              if (res.data.status === 'paid' && res.data.allow_payment_only_once) {
                notification.open({
                  type: 'error',
                  message: 'This payment link is not valid anymore.',
                });
              } else {
                createGift(formData);
              }
            })
            .catch((err) => {
              createGift(formData);
            });
        } else {
          createGift(formData);
        }
      } else {
        const gift = createMockGift(formData);
        giftDispatch(success(MODULE_GIFT, gift));
        setIsLoading(true);
        announceToScreenReader(`Loading`);
        setTimeout(() => {
          props.proceedToNextStep();
          props.history.push(
            GIVING_GIFT_SUCCESS_PAYMENT_PATH.replace(':slug', settings.page.slug).replace(':giftId', gift.id),
            { step: THANK_YOU_PAGE },
          );
          setIsLoading(false);
        }, 2000);
      }
    });
  }

  const onDccClick = (isDccTrueorFalse) => {
    if (!isDccTrueorFalse) {
      setToastVisible(true);
      announceToScreenReader('Thank you');
      setTimeout(() => {
        setToastVisible(false);
      }, 700);
    }
    setIsDCCEnabled(!isDccTrueorFalse);
  };

  function onGiftAidClick(val) {
    setGiftAidSelected(val);
    props.form.setFieldsValue({ donor_pays_tax: val });
  }

  function getDataFromPaymentLink(value, delim) {
    if (
      isEmpty(paymentLink.data) ||
      isEmpty(paymentLink.data.pre_filled_values) ||
      isEmpty(paymentLink.data.pre_filled_values.fields)
    ) {
      return null;
    }
    const index = findIndex(paymentLink.data.pre_filled_values.fields, (val) => val[delim] === value);
    if (index > -1) {
      return paymentLink.data.pre_filled_values.fields[index].value;
    }
    return null;
  }

  function getInitialDonorPlace() {
    if (isEmpty(paymentLink.data) || isEmpty(paymentLink.data.pre_filled_values)) {
      return null;
    }
    const index = findIndex(paymentLink.data.pre_filled_values, (val) => val.slug === 'donor_city');
    if (index > -1) {
      return paymentLink.data.pre_filled_values[index].extraValue;
    }
    return null;
  }

  function getCaptcha(e) {
    e.preventDefault();
    if (captchaEnabled) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(googleCaptchaKey, { action: 'submit' })
          .then(function (token) {
            validate(token);
          })
          .catch((err) => {
            validate(null);
          });
      });
    } else {
      validate(null);
    }
  }

  return (
    <div className="prepayment-wrapper">
      <div className="prepayment-body-wrapper">
        <div className="tell-us-about-yourself-wrapper">
          <h2 className="tell-us-about-yourself-text arc-H300 arc-text-align-c arc-color-B85">
            <I18nCustomFormatter id="prepayment-form-title" />
          </h2>
          <p className="arc-support arc-text-align-c arc-color-gray8">
            <I18nCustomFormatter id="prepayment-form-description" />
          </p>
        </div>
        <div id="g-recaptcha" className="g-recaptcha" data-size="invisible" />
        <div className="prepayment-form-wrapper">
          <p className="arc-support arc-color-gray8 arc-text-align-c">
            Asterisk (<span className="arc-color-red">*</span>) Indicates a required field
          </p>
          <Form>
            <Form.Item
              color={false}
              label={
                <span className="arc-p arc-color-black arc-font-weight-medium">
                  <I18nCustomFormatter id="prepayment-form-fullname" />
                </span>
              }>
              {getFieldDecorator('donor_name', {
                initialValue: getDataFromPaymentLink('donor_name', 'slug') || userData.full_name,
                rules: [
                  {
                    required: true,
                    message: 'Please enter your full name',
                  },
                  {
                    validator: validateFullName,
                  },
                ],
                validateTrigger: 'onBlur',
              })(
                <Input
                  placeholder={fullNamePlaceHolder}
                  aria-required="true"
                  autoComplete="full_name"
                  className="pre-payment-input"
                />,
              )}
            </Form.Item>
            <Form.Item
              color={false}
              label={
                <span className="arc-p arc-color-black arc-font-weight-medium">
                  <I18nCustomFormatter id="prepayment-form-email" />
                </span>
              }>
              {getFieldDecorator('donor_email', {
                initialValue: getDataFromPaymentLink('donor_email', 'slug') || userData.email,
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                    transform: (value) => value && value.trim(),
                  },
                  {
                    required: true,
                    message: 'Please enter your e-mail!',
                  },
                ],
                validateTrigger: 'onBlur',
              })(
                <Input
                  placeholder={emailPlaceHolder}
                  aria-required="true"
                  autoComplete="email"
                  className="pre-payment-input"
                />,
              )}
            </Form.Item>
            <PhoneNumberInput
              form={props.form}
              formItem={{
                key: 'donor_phone_number',
                maxLength: 20,
                label: (
                  <span className="arc-p arc-color-black arc-font-weight-medium">
                    <I18nCustomFormatter id="prepayment-form-phone" />
                  </span>
                ),
                placeholder: phonePlaceHolder,
                autoComplete: 'tel',
                required: true,
                message:
                  'Please enter a valid phone number. Your phone number is required for completing this online transaction.',
              }}
              formRecord={{ donor_phone_number: getDataFromPaymentLink('donor_phone_number', 'slug') }}
            />
            <If condition={isFieldVisible('donor_address_line1')}>
              <Form.Item
                color={false}
                label={
                  <span className="arc-p arc-color-black arc-font-weight-medium">
                    <I18nCustomFormatter id="prepayment-form-address-line1" />
                  </span>
                }>
                {getFieldDecorator('donor_address_line1', {
                  initialValue: getDataFromPaymentLink('donor_address_line1', 'slug'),
                  rules: [
                    {
                      required: isFieldRequired('donor_address_line1'),
                      message: 'Please enter your address',
                    },
                  ],
                })(
                  <Input
                    placeholder={addressLine1PlaceHolder}
                    autoComplete="address_line_1"
                    className="pre-payment-input"
                    aria-required={isFieldRequired('donor_address_line1')}
                  />,
                )}
              </Form.Item>
            </If>
            <If condition={isFieldVisible('donor_address_line2')}>
              <Form.Item
                color={false}
                label={
                  <span className="arc-p arc-color-black arc-font-weight-medium">
                    <I18nCustomFormatter id="prepayment-form-address-line2" />
                  </span>
                }>
                {getFieldDecorator('donor_address_line2', {
                  initialValue: getDataFromPaymentLink('donor_address_line2', 'slug'),
                  rules: [
                    {
                      required: isFieldRequired('donor_address_line2'),
                      message: 'Please enter your address',
                    },
                  ],
                })(
                  <Input
                    placeholder={addressLine2PlaceHolder}
                    autoComplete="address_line_2"
                    className="pre-payment-input"
                    aria-required={isFieldRequired('donor_address_line2')}
                  />,
                )}
              </Form.Item>
            </If>
            <If condition={isFieldVisible('donor_city')}>
              <Form.Item
                color={false}
                label={
                  <span className="arc-p arc-color-black arc-font-weight-medium">
                    <I18nCustomFormatter id="prepayment-form-city" />
                  </span>
                }>
                {getFieldDecorator('donor_city', {
                  initialValue: getDataFromPaymentLink('donor_city', 'slug'),
                  rules: [
                    {
                      required: isFieldRequired('donor_city'),
                      message: 'Please enter your city',
                    },
                    {
                      validator: validateCity,
                    },
                  ],
                })(
                  <Input
                    placeholder={cityPlaceHolder}
                    autoComplete="city"
                    className="pre-payment-input"
                    aria-required={isFieldRequired('donor_city')}
                  />,
                )}
              </Form.Item>
            </If>
            <Form.Item style={{ display: 'none' }}>
              {getFieldDecorator('donor_city_place', {
                initialValue: getInitialDonorPlace(),
              })(<Input type="hidden" />)}
            </Form.Item>
            <If condition={isFieldVisible('donor_zip_code')}>
              <Form.Item
                color={false}
                label={
                  <span className="arc-p arc-color-black arc-font-weight-medium">
                    <I18nCustomFormatter id="zip-code" />
                  </span>
                }>
                {getFieldDecorator('donor_zip_code', {
                  initialValue: getDataFromPaymentLink('donor_zip_code', 'slug'),
                  rules: [
                    {
                      required: isFieldRequired('donor_zip_code'),
                      message: 'Please enter your zip code',
                    },
                  ],
                })(
                  <Input
                    placeholder={zipCodePlaceHolder}
                    autoComplete="zip"
                    className="pre-payment-input"
                    aria-required={isFieldRequired('donor_zip_code')}
                  />,
                )}
              </Form.Item>
            </If>
            <If condition={props.prePaymentCustomFields.length}>
              {props.prePaymentCustomFields.map((field) => (
                <CustomFormItem
                  key={field.id}
                  form={props.form}
                  formInput={field}
                  formRecord={{ [field.id]: getDataFromPaymentLink(field.id, 'id') }}
                  metadata={{
                    source_type: 'customform',
                    source_id: settings.page.pre_payment_form && settings.page.pre_payment_form.id,
                    access_level: 'protected',
                  }}
                />
              ))}
            </If>
            <If
              condition={
                ((!window.isBBMSTippingEnabled && gift?.account?.account?.type === 'bbms') ||
                  gift?.account?.account?.type !== 'bbms') &&
                dccCharges &&
                dccCharges > 0
              }>
              <Divider />
              <Form.Item>
                <div className={`dcc-card ${isDCCEnabled ? 'checked' : ''}`}>
                  <p className="dcc-title">OPTIONAL</p>
                  <div className="dcc-body">
                    <div>
                      {getFieldDecorator('is_donor_covered_charges', {
                        valuePropName: 'checked',
                        initialValue: isDCCEnabled,
                      })(
                        <Checkbox onChange={() => onDccClick(isDCCEnabled)}>
                          <div>
                            <p className="arc-H150 dcc-description">
                              {`I would like to help cover the transaction charges of $${dccCharges} for my donation`}
                            </p>
                          </div>
                        </Checkbox>,
                      )}
                      <div className="ml24">
                        <p className="arc-support arc-color-B55 dcc-help">
                          By covering transaction charges, we’ll be able to use your full donation to support our
                          mission.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={`dcc-toast ${!toastVisible ? 'hidden' : ''}`}>
                    <div className="dcc-toast-body">
                      <FontAwesomeIcon icon={faHeart} className="thank-you-icon" />
                      <span className="arc-H100 arc-color-B85">
                        <b>THANK YOU</b>
                      </span>
                    </div>
                  </div>
                </div>
                <If condition={settings.page.count_of_donor_covered_charges_gifts > 2}>
                  <div className="dcc-footer arc-support arc-color-B55 arc-text-align-c mt4">
                    <FontAwesomeIcon icon={faUsers} className="mr4" />
                    <span>{`${settings.page.count_of_donor_covered_charges_gifts} donors on this campaign chose to cover charges`}</span>
                  </div>
                </If>
              </Form.Item>
            </If>

            <If condition={isFieldVisible('gift_aid')}>
              <Divider />
              <Form.Item>
                <div className="dcc-card">
                  <p className="dcc-title arc-font-weight-bold-imp">GIFT AID</p>
                  <p className="mt8 mb12 arc-H150">
                    <I18nCustomFormatter id="gift-aid-description" />
                  </p>
                  <div className="dcc-body">
                    <div className="gift-aid-item">
                      {getFieldDecorator('donor_pays_tax', {
                        initialValue: isGiftAidSelected,
                        rules: [
                          {
                            required: isFieldRequired('gift_aid'),
                            message: 'Please select an option',
                          },
                        ],
                      })(
                        <Radio.Group
                          value={isGiftAidSelected}
                          onChange={(e) => onGiftAidClick(e.target.value)}
                          aria-required={isFieldRequired('gift_aid')}>
                          <div className="arc-d-flex arc-cursor-p" onClick={() => onGiftAidClick('yes')}>
                            <div>
                              <Radio value="yes" />
                            </div>
                            <p className="arc-support arc-color-B55">
                              <I18nCustomFormatter id="gift-aid-option-yes" />
                            </p>
                          </div>
                          <div className="arc-d-flex arc-cursor-p mt8" onClick={() => onGiftAidClick('no')}>
                            <div>
                              <Radio value="no" />
                            </div>
                            <div>
                              <p className="arc-support arc-color-B55 dcc-help">
                                <I18nCustomFormatter id="gift-aid-option-no" />
                              </p>
                            </div>
                          </div>
                        </Radio.Group>,
                      )}
                    </div>
                  </div>
                </div>
              </Form.Item>
            </If>
          </Form>
        </div>

        <Divider />
        <div className="post-form-info-wrapper">
          <div className="icon">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          <p className="post-form-info arc-p">Additional instructions can be specified in upcoming steps.</p>
        </div>
      </div>
      <div className="payment-footer not-fixed-payment-footer">
        <Button id="initiate-payment-button" type="primary" onClick={getCaptcha} size="large" loading={isLoading}>
          <I18nCustomFormatter id="proceed-btn-label" />
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(Form.create()(withRouter(PrePaymentForm)));
