import React, { useState } from 'react'
import ProgressiveImage from "react-progressive-image";
import { getImagePath } from "Src/common/utilities/dom_utils"

import './style.scss'



function ProgressiveFadedImage({ className, path, render, noWrapperClass }) {

    const [fallbackSrc, setFallBackSrc] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    function getSrcSet() {
        return `${getImagePath(path, 'sm')} 360w, ${getImagePath(path, 'md')} 768w, ${getImagePath(path, 'lg')} 2000w`
    }

    function onError(src) {
       setFallBackSrc(path)
       setIsLoading(false)
    }

    return (
        <div className={`${typeof noWrapperClass === 'undefined' ? 'relative-wrapper': ''} ${className || ''}`}>
            <div className="absolute-wrapper">
                <ProgressiveImage
                    src={path}
                    srcSetData={{
                        srcSet: getSrcSet(),
                        sizes: '(max-width: 360px) 360px,(min-width: 361px) and (max-width: 768px) 768px,2000px'
                    }}
                    placeholder={getImagePath(path, 'thumbnail')}
                    onError={onError}
                >
                    {(src, loading, srcSetData) => {
                        return(
                            render(isLoading && loading, fallbackSrc || src, srcSetData)
                        )
                    }}    
                </ProgressiveImage>
            </div>
        </div>
    )
}

export default ProgressiveFadedImage;