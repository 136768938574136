import React from 'react';
import { Button, Divider } from 'antd';
import SocialConnectBlock from './../socialConnectBlock';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';

function RegisterBlock() {

    const nextUrl = window.location.pathname;

    return (
        <div className="signin-register-block">
            <If condition={!window.isEmailSignupDisabled}>
                <div className="register-block-section">
                    <Button
                        id="email-signin-btn"
                        className="arc-btn-subtle"
                        onClick={() => window.location = `/register?next=${nextUrl}`}
                    >
                        Connect with email
                    </Button>
                </div>
                <Divider>In a rush? connect your social media accounts instead.</Divider>
            </If>
            <SocialConnectBlock showAll={true} />
            <p className="signin-disclaimer-text">
                <FontAwesomeIcon icon={faLockAlt} className="mr8"/>
                <span>We do not post anything on your behalf.</span>
            </p>
        </div>
    )
}

export default RegisterBlock;