import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hubData: [],
  selectedCampaignId: null,
  campaignHubFunds: null,
  selectedCampaignData: null,
};

const campaignHubSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setHubAction(state, action) {
      state.hubData = action.payload;
    },
    setSpecificHubAction(state, action) {
      state.hubData = action.payload;
    },
    setCampaignIdAction(state, action) {
      state.selectedCampaignId = action.payload;
    },
    setCampaignHubFunds(state, action) {
      state.campaignHubFunds = action.payload;
    },
    setSelectedCampaignData(state, action) {
      state.selectedCampaignData = action.payload;
    },
  },
});

export const { setHubAction, setSpecificHubAction, setCampaignIdAction, setCampaignHubFunds, setSelectedCampaignData } = campaignHubSlice.actions;
export default campaignHubSlice.reducer;
