/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import { Button, Divider, Input, Select, Switch, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { getFormValue, processLocationFieldAndExtractLocationValues } from 'Src/common/utilities/form_utils';
import instance from 'Src/common/utilities/axios_util';
import { GIVING_GIFT_SHARE_PATH } from 'Src/alumniGiving/routes';
import CustomFormItem from 'Src/common/components/customFormItem';
import UserFeedback from 'Src/common/components/UserFeedback/index';
import DoubleTheDonation from './doubleTheDonation';
import queryString from 'query-string';
import { success } from '../../../actionCreators';
import { isFieldVisible, isFieldRequired } from 'Src/alumniGiving/common/utils/helper';
import { isEmpty } from 'lodash';
import '@ant-design/compatible/assets/index.css';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import {
  UPDATE_GIFT_API_PATH,
  GIFTS_PUBLIC_LIST_API,
  RECURRING_GIFTS_PUBLIC_LIST_API,
  USER_FEEDBACK_API_PATH,
} from '../../../endpoints';
import { MODULE_GIFT, SHARE_PAGE } from '../../../constants';
import { SettingsConsumer } from '../../../context/settings';
import { GiftConsumer } from '../../../context/gift';

let mode = null;

const { Option } = Select;

function PostPaymentForm(props) {
  const { getFieldDecorator } = props.form;
  const [giftObj, giftDispatch] = GiftConsumer();
  const [settings, dispatch] = SettingsConsumer();
  const [isUpdatingGift, setIsUpdatingGift] = useState(false);
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');

  let gift = window.giftData;
  const url = 'https://www.dailymotion.com/embed/video/ktks44peb0o84ttL4rd?autoplay=1&mute=1&controls';

  if (isEmpty(gift)) {
    gift = giftObj;
  }

  const queryS = queryString.parse(props.location.search);
  if (!isEmpty(queryS)) {
    mode = queryS.mode;
  }

  const dtdHelpText = props.intl.formatMessage({
    id: 'double-the-donation-help-text',
  });

  function handleIsTributeChange(val) {
    props.form.setFieldsValue({ is_in_tribute: val });
  }

  function handleIsAnonymousChange(val) {
    props.form.setFieldsValue({ is_anonymous: val });
  }

  function validate() {
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // only update gift if form is changed
        setIsUpdatingGift(true);
        if (rating) {
          const data = [
            {
              rating: rating,
              comment: feedback,
              selected_reasons: [],
              question: { id: window.userFeedbackQuestions[0].id },
            },
          ];
          if (gift.payment_config && gift.payment_config.type === 'one_time') {
            const path = USER_FEEDBACK_API_PATH.replace('<object_id>', gift.id);
            instance.post(path, data, { hideNotification: true });
          }
        }
        values.id = gift.id;
        delete values.is_in_tribute;
        if (!values.honor_message) {
          delete values.honor_prefix;
        }
        const { newValues, locations } = processLocationFieldAndExtractLocationValues(values);
        values = newValues;
        if (settings.page.post_payment_form && settings.page.post_payment_form.id) {
          values.post_payment_form_entry = {
            form_id: settings.page.post_payment_form.id,
            field_entries: props.postPaymentCustomFields
              .filter((field) => typeof values[field.id] !== 'undefined')
              .map((field) => {
                let fieldVal = {
                  value: getFormValue(values[field.id]),
                  field_id: field.id,
                };
                if (typeof locations[field.id] !== 'undefined') {
                  fieldVal = { ...fieldVal, ...locations[field.id] };
                }
                return fieldVal;
              }),
          };
          props.postPaymentCustomFields.forEach((field) => {
            delete values[field.id];
          });
        }
        if (!mode) {
          instance
            .patch(UPDATE_GIFT_API_PATH.replace('{}', settings.page.id), values)
            .then((response) => {
              let url = `${GIFTS_PUBLIC_LIST_API.replace('{}', settings.page.id).concat(gift.id, '/')}?fields=ugc`;
              if (gift.payment_config && gift.payment_config.type === 'recurring') {
                url = `${RECURRING_GIFTS_PUBLIC_LIST_API.replace('{}', settings.page.id).concat(
                  gift.id,
                  '/',
                )}?fields=ugc`;
              }
              instance.get(url).then((res) => {
                gift.ugc = res.data.ugc;
                giftDispatch(success(MODULE_GIFT, gift));
                setIsUpdatingGift(false);
                // this is needed so pushstate as it is async keeps id intact
                const giftId = gift.id;
                props.history.push(
                  GIVING_GIFT_SHARE_PATH.replace(':slug', settings.page.slug).replace(':giftId', giftId),
                  { step: SHARE_PAGE },
                );
                props.proceedToNextStep();
              });
            })
            .catch((err) => {
              setIsUpdatingGift(false);
            });
        } else {
          setIsUpdatingGift(true);
          setTimeout(() => {
            props.proceedToNextStep();
            setIsUpdatingGift(false);
          }, 2000);
        }
      }
    });
  }

  function getThankyouBackgroundImage() {
    if (!settings.page.thank_you_image) {
      return defaultThankYouImage;
    }
    return settings.page.thank_you_image;
  }

  return (
    <div className="post-payment-wrapper">
      <div
        className="thank-you-text-wrapper arc-modal-box-shadow"
        style={{ backgroundImage: `url(${getThankyouBackgroundImage()})` }}
      />
      <div className="gift-update-details-wrapper">
        <div className="thankyou-subtitle title">
          <div className="subtitle-row arc-H300 mb8">
            <FontAwesomeIcon icon={faCheckCircle} className="subtitle-row-icon arc-color-success" />
            <p className="subtitle-text arc-color-black arc-font-weight-medium ml8">
              <I18nCustomFormatter id="gift-received-label" />
            </p>
          </div>
          {/* <p className="arc-p mb24"><FormattedMessage id=contact-us" values={{ email: window.collegeEmail }} /></p> */}
        </div>
        <p className="arc-support arc-color-gray8 arc-text-align-c">
          Asterisk (<span className="arc-color-red">*</span>) Indicates a required field
        </p>
        <div className="gift-update-form-wrapper">
          <Form colon={false}>
            <Row>
              <If condition={settings.page.is_anonymous_gifts_enabled}>
                <Col span={18}>
                  <div className="arc-H150">
                    <I18nCustomFormatter id="is-anonymous-label" />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    {getFieldDecorator('is_anonymous', {
                      initialValue: gift.is_anonymous,
                    })(
                      <Fragment>
                        <Switch
                          size="large"
                          checked={props.form.getFieldValue('is_anonymous')}
                          onChange={handleIsAnonymousChange}
                          aria-label="Make gift anonymous"
                        />
                        <span className="switch-text ml2">
                          {props.form.getFieldValue('is_anonymous') ? 'Yes' : 'No'}
                        </span>
                      </Fragment>,
                    )}
                  </Form.Item>
                </Col>
              </If>
              <If condition={settings.page.is_gift_tribute_enabled}>
                <Col span={18}>
                  <div className="arc-H150">
                    <I18nCustomFormatter id="tribute-label" />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item className="nested-form-item">
                    {getFieldDecorator('is_in_tribute', {
                      initialValue: !!gift.honor_message,
                    })(
                      <Fragment>
                        <Switch
                          size="large"
                          checked={props.form.getFieldValue('is_in_tribute')}
                          onChange={handleIsTributeChange}
                          aria-label="Make a tribute"
                        />
                        <span className="switch-text ml2">
                          {props.form.getFieldValue('is_in_tribute') ? 'Yes' : 'No'}
                        </span>
                      </Fragment>,
                    )}
                  </Form.Item>
                </Col>
                <If condition={props.form.getFieldValue('is_in_tribute')}>
                  <Col span={24}>
                    <Form.Item
                      aria-label="Select tribute type"
                      label="Tribute"
                      className="inner-form-item"
                      labelCol={{ sm: 24 }}
                      wrapperCol={{ sm: 24 }}>
                      {getFieldDecorator('honor_prefix', {
                        initialValue: gift.honor_prefix ? gift.honor_prefix : 'in_honor_of',
                      })(
                        <Select style={{ width: '100%' }}>
                          <Option value="in_honor_of">In honor of</Option>
                          <Option value="in_memory_of">In memory of</Option>
                          <Option value="in_support_of">In support of</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Tribute recipient’s name"
                      className="inner-form-item"
                      labelCol={{ sm: 24 }}
                      wrapperCol={{ sm: 24 }}>
                      {getFieldDecorator('honor_message', {
                        initialValue: gift.honor_message,
                        rules: [{ required: true, message: 'Please enter honor message' }],
                      })(
                        <Input
                          style={{ width: '100%' }}
                          placeholder="Type here"
                          className="post-payment-input"
                          aria-required="true"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </If>
              </If>
            </Row>
            <If condition={isFieldVisible('note')}>
              <Form.Item label="Comments" className="inner-form-item" labelCol={{ sm: 24 }} wrapperCol={{ sm: 24 }}>
                {getFieldDecorator('note', {
                  initialValue: gift.note,
                  rules: [
                    {
                      required: isFieldRequired('note'),
                      message: 'Please enter your comment',
                    },
                  ],
                })(<Input placeholder="Add a comment" className="post-payment-input" />)}
              </Form.Item>
            </If>
            <If condition={window.companyMatchingEnabled}>
              <DoubleTheDonation form={props.form} dtdHelpText={dtdHelpText} />
            </If>
            <If condition={props.postPaymentCustomFields.length}>
              {props.postPaymentCustomFields.map((field) => (
                <CustomFormItem
                  key={field.id}
                  form={props.form}
                  formInput={field}
                  formRecord={{}}
                  labelCol={{ sm: 24 }}
                  wrapperCol={{ sm: 24 }}
                />
              ))}
            </If>
          </Form>
        </div>
      </div>
      <If
        condition={
          !isEmpty(window.userFeedbackQuestions) &&
          !gift.is_feedback_submitted &&
          gift.payment_config &&
          gift.payment_config.type === 'one_time'
        }>
        <div className="user-feedback-container">
          <UserFeedback
            question={{
              main: window.userFeedbackQuestions[0].feedback_question,
              followUp: {
                positive: window.userFeedbackQuestions[0].reason_question_positive.question,
                negative: window.userFeedbackQuestions[0].reason_question_negative.question,
              },
            }}
            hasSubmitButton={false}
            submittedFeedback={false}
            onRatingChange={(rating) => setRating(rating)}
            onFeedbackChange={(feedback) => setFeedback(feedback)}
          />
          <Divider />
        </div>
      </If>
      <div className="thankyou-subtitle">
        <div className="subtitle-row arc-p mb8">
          <FontAwesomeIcon icon={faEnvelope} className="subtitle-row-icon" />
          <p className="subtitle-text arc-support arc-color-gray9 ml8">
            <I18nCustomFormatter id="post-payment-contact-us-label" values={{ email: window.collegeEmail }} />
          </p>
        </div>
        {/* <p className="arc-p mb24"><FormattedMessage id=contact-us" values={{ email: window.collegeEmail }} /></p> */}
      </div>
      <div className="payment-footer not-fixed-payment-footer">
        <Button type="primary" size="large" onClick={() => validate()} loading={isUpdatingGift}>
          <I18nCustomFormatter id="proceed-btn-label" />
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(Form.create()(withRouter(PostPaymentForm)));
