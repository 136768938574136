/* eslint-disable */

/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/extensions */

import React, { Component } from 'react';
import { bool, string, shape } from 'prop-types';

// Require Editor JS files.
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/help.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditor from 'react-froala-wysiwyg';

import './style.scss';

class FroalaEditorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: this.props.model,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.model && nextProps.model !== this.props.model) {
      this.setState({
        model: nextProps.model,
      });
    }
  }

  handleFroalaModelChange(model) {
    this.setState({ model });
    this.props.onModelChange(model);
  }

  render() {
    return (
      <>
        <FroalaEditor
          tag="textarea"
          config={{
            key: window.froalaEditorKey,
            placeholderText: this.props.placeholder,
            toolbarInline: this.props.toolbarInline,
            toolbarButtons: this.props.toolbarButtons,
            pluginsEnabled: [
              'align',
              'lists',
              'paragraphFormat',
              'image',
              'link',
              'fullscreen',
              'quickInsert',
              'table',
              'video',
              'charCounter',
              'emoticons',
              'fontFamily',
              'colors',
              'paragraphStyle',
              'paragraphFormat',
              'specialCharacters',
              'paragraphStyle',
              'trackChanges',
              'inlineClass',
              'inlineStyle',
              'lineHeight',
              'markdown',
              'fontSize',
              'codeView',
              'quote',
              'file',
              'print',
              'help',
            ],
            imageUploadURL: '/_froala_upload_file',
            fileUploadURL: '/_froala_upload_file',
            codeBeautifier: false,
            htmlRemoveTags: ['base'],
            toolbarSticky: false,
            videoResponsive: true,
            charCounterCount: this.props.showCharacterCount,
            quickInsertButtons: this.props.quickInsertButtons,
            useClasses: this.props.useClasses !== false,
            paragraphFormat: this.props.paragraphFormat,
            events: {
              'image.beforeUpload': this.props.handleBeforeUpload,
              initialized: this.props.handleInitialized,
            },
            ...this.props.uploadImageOptions,
          }}
          model={this.state.model}
          onModelChange={(model) => this.handleFroalaModelChange(model)}
        />
      </>
    );
  }
}

FroalaEditorComponent.propTypes = {
  showCharacterCount: bool,
  toolbarInline: bool,
  toolbarButtons: shape(),
  quickInsertButtons: shape(),
  placeholder: string,
  paragraphFormat: shape(),
};

FroalaEditorComponent.defaultProps = {
  showCharacterCount: true,
  toolbarInline: true,
  placeholder: 'Type something',
  // todo: need to define list of default toolbarButtons
  // toolbarButtons: [
  //   'bold', 'italic', 'underline', 'align', '-',
  //   'paragraphFormat', 'fontSize', 'color', 'clearFormatting', '-',
  //   'insertImage', 'insertVideo', 'insertLink', 'emoticons',
  // ],
  quickInsertButtons: ['image', 'video', 'embedly', 'table', 'ul', 'ol', 'hr'],
};

export default FroalaEditorComponent;
