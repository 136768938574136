import React, { Fragment, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Select, Input, Upload, Modal, Row, Progress, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilPaintbrush, faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { faImage, faPencil } from '@fortawesome/pro-regular-svg-icons';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

import './style.scss';

function UGC({ handleFileChange, form, update, actionUrl, method, defaultImage, ugc, donorName, secretHash }) {
  const { getFieldDecorator } = form;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // to whomsoever it may concern, L means local
  const [titleL, setTitleL] = useState(ugc.title || 'in_support_of');
  const [descriptionL, setDescriptionL] = useState(ugc.description || 'Add your tribute');
  const [nameL, setNameL] = useState(ugc.name || donorName);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(false);
  const [ugcL, setUgcL] = useState(ugc);

  const honor_prefix_display_map = {
    in_honor_of: 'In honor of',
    in_memory_of: 'In memory of',
    in_support_of: 'In support of',
  };

  const honorSelector = getFieldDecorator('honor_prefix', {
    initialValue: ugc.title ? ugc.title : 'in_support_of',
  })(
    <Select style={{ width: 180 }} className="honor-prefix-selector">
      <Option value="in_honor_of">In honor of</Option>
      <Option value="in_memory_of">In memory of</Option>
      <Option value="in_support_of">In support of</Option>
    </Select>,
  );

  function updateContent(e) {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setTitleL(values.honor_prefix);
        setDescriptionL(values.description);
        toggleEditModal();
        update({
          title: values.honor_prefix,
          description: values.description,
        });
      }
    });
  }

  function toggleEditModal() {
    setIsEditModalOpen(!isEditModalOpen);
  }

  return (
    <Fragment>
      <div className="ugc-header">
        <div className="ugc-header-icon">
          <FontAwesomeIcon icon={faPencilPaintbrush} className="arc-color-primary" />
        </div>
        <p className="ugc-header-title arc-p arc-color-B45">
          <I18nCustomFormatter id="ugc-title" />
        </p>
      </div>
      <Card
        title={
          <div className="header">
            <div className="image-placeholder" />
            <div className="name-placeholder" />
          </div>
        }
        cover={
          <div className="ugc-image-container">
            <div className="tribute-image">
              <div className={`overlay ${isProgressBarVisible ? 'visible' : ''}`} />
              <div className="gradient-overlay">
                <p className="gift-label">{ugc.gift_label}</p>
                <p>#{ugc.gift_position}</p>
              </div>
              <img src={ugcL.cover_image || defaultImage} className="outer-image" />
              <div className="overlay" />
              <img src={ugcL.cover_image || defaultImage} className="inner-image" />
            </div>
            <Upload
              action={actionUrl}
              method={method}
              name="cover_image"
              headers={{ 'X-CSRFToken': window.csrfToken, 'X-DASHBOARD-HASH': secretHash }}
              accept=".jpg, .jpeg, .png"
              showUploadList={false}
              onStart={(file) => {
                const attachment = {
                  uid: file.uid,
                  name: file.name,
                  url: '',
                  status: 'uploading',
                };
                setIsProgressBarVisible(true);
                handleFileChange([attachment]);
              }}
              onSuccess={(file, info) => {
                const attachment = {
                  status: 'done',
                  data: file,
                };
                ugcL.cover_image = file.cover_image;
                setUgcL(ugcL);
                setIsProgressBarVisible(false);
                setUploadPercent(0);
                handleFileChange([attachment]);
              }}
              onProgress={(e) => {
                setUploadPercent(parseInt(e.percent, 10));
              }}
              onRemove={(file) => {
                handleFileChange([]);
              }}>
              {isProgressBarVisible && (
                <Progress
                  type="circle"
                  percent={uploadPercent}
                  className="progress-bar"
                  width={60}
                  strokeColor={primaryColor}
                  trailColor="transparent"
                />
              )}
              {!isProgressBarVisible && (
                <Button shape="round" className="customize-img-btn arc-p">
                  <FontAwesomeIcon icon={faImage} className="mr8" />
                  Change image
                </Button>
              )}
            </Upload>
          </div>
        }
        className="ugc-container arc-card-box-shadow arc-card-border-radius">
        <Card.Meta
          title={`I am giving ${honor_prefix_display_map[form.getFieldValue('honor_prefix')]}`}
          description={
            <div>
              <p className="description mb8">{descriptionL}</p>
              <p className="name">{nameL}</p>
            </div>
          }
        />
        <a className="edit-icon-wrapper">
          <FontAwesomeIcon
            icon={faPencil}
            className="arc-cursor-p edit-icon"
            aria-hidden="true"
            onClick={toggleEditModal}
          />
          <FontAwesomeIcon
            icon={faEdit}
            className="thumbs-up-icon arc-cursor-p"
            aria-hidden="true"
            onClick={toggleEditModal}
          />
        </a>
        <div className="footer">
          <div className="title-placeholder" />
          <div className="description-placeholder" />
        </div>
      </Card>
      {isEditModalOpen && (
        <Modal
          visible
          closable={false}
          onOk={(e) => updateContent(e)}
          onCancel={toggleEditModal}
          wrapClassName="edit-ugc-details"
          footer={
            <Button type="primary" onClick={(e) => updateContent(e)} size="large">
              Save changes
            </Button>
          }>
          <p className="arc-H300 arc-color-black title">Edit your details</p>
          <Form>
            <Row type="flex" justify="center">
              <Col span={24}>
                <div className="arc-H150 mb8">I am giving</div>
              </Col>
              <Col span={24}>
                <Form.Item label="" className="honor-prefix-selector">
                  {getFieldDecorator('honor_prefix', {
                    initialValue: ugc.title ? ugc.title : 'in_support_of',
                  })(
                    <Select style={{ width: '100%' }}>
                      <Option value="in_honor_of">In honor of</Option>
                      <Option value="in_memory_of">In memory of</Option>
                      <Option value="in_support_of">In support of</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="" className="inner-form-item">
                  {getFieldDecorator('description', {
                    initialValue: descriptionL,
                    rules: [{ required: true, message: 'Please enter honor message' }],
                  })(<Input style={{ width: '100%' }} placeholder="type here" />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <p className="arc-support arc-style-italic">Any changes you make will also be updated on your gift record </p>
        </Modal>
      )}
    </Fragment>
  );
}

export default Form.create()(UGC);
