/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Card, List, Avatar } from 'antd';
import { INFLUENCER_LEADERBOARD_API, GENEROSITY_LEADERBOARD_API } from 'Src/alumniGiving/endpoints';
import { TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT } from 'Src/alumniGiving/constants';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import NavigationFooter from 'Src/common/components/navigationFooter';
import axiosInstance from 'Src/common/utilities/axios_util';
import InfluenceCard from './influenceCard';
import { formatter, commaSeparatedAmountAndCurrency } from 'Src/common/utilities/data_util';
import pluralize from 'pluralize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild, faLeaf } from '@fortawesome/pro-light-svg-icons';
import { isEmpty } from 'lodash';
import './../style.scss';

const PAGE_SIZE = 5;

let pageNumber = 1;
let count = 0;

function InfluencerLeaderboard({ leaderboard }) {
  const TAB_INFLUENCER = 'influence';
  const TAB_GENEROSITY = 'generosity';

  let tabList = [
    {
      key: TAB_INFLUENCER,
      tab: (
        <div className="giving-v2-card-tab-title">
          <FontAwesomeIcon icon={faChild} className="mr8" />
          Reach
        </div>
      ),
    },
    {
      key: TAB_GENEROSITY,
      tab: (
        <div className="giving-v2-card-tab-title">
          <FontAwesomeIcon icon={faLeaf} className="mr8" />
          Generosity
        </div>
      ),
    },
  ];
  if (window.hideGenerosityOnLeaderboard === 'true' || !leaderboard.is_generosity_leaderboard_enabled) {
    tabList = tabList.filter((tab) => tab.key != TAB_GENEROSITY);
  }

  if (!leaderboard.is_participation_leaderboard_enabled) {
    tabList = tabList.filter((tab) => tab.key != TAB_INFLUENCER);
  }

  function getInitialSelectedTab() {
    if (tabList.length === 1) {
      return tabList[0].key;
    }
    return TAB_INFLUENCER;
  }

  const [selectedTab, setSelectedTab] = useState(getInitialSelectedTab());
  const [settings, dispatch] = SettingsConsumer();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [loading, setLoading] = useState(true);

  count = 0;

  function getLeaderboardData(
    url = INFLUENCER_LEADERBOARD_API.replace('{}', settings.page.id).concat(`?order_by=${selectedTab}`),
  ) {
    return axiosInstance({
      method: 'get',
      url: url,
    });
  }

  useEffect(() => {
    count = 0;
    pageNumber = 1;
    setLoading(true);
    setLeaderboardData([].slice());
    let url = '';
    if (selectedTab === TAB_INFLUENCER) {
      url = INFLUENCER_LEADERBOARD_API.replace('{}', settings.page.id).concat(`?order_by=${selectedTab}`);
    } else {
      url = GENEROSITY_LEADERBOARD_API.replace('{}', settings.page.id);
    }
    getLeaderboardData(url).then((response) => {
      setLoading(false);
      setLeaderboardData(response.data.results.slice());
      setNext(response.data.next);
      setPrev(response.data.previous);
    });
  }, [selectedTab]);

  function onTabChange(key) {
    setSelectedTab(key);
  }

  function getGiftDescription(item) {
    if (item.count_gifts_influenced) {
      return `, raised ${item.count_gifts_influenced} gifts worth ${commaSeparatedAmountAndCurrency(
        item.total_amount_influenced_by_currency,
      )}`;
    }
    return '';
  }

  function getDescription(item) {
    if (selectedTab === TAB_INFLUENCER) {
      return (
        <p className="">{`has reached ${item.count_visits_influenced} ${pluralize(
          'visitor',
          item.count_visits_influenced,
        )}${getGiftDescription(item)}`}</p>
      );
    }
    if (item.amount !== undefined && leaderboard?.gift_visibility === TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT) {
      return <p className="">{formatter(settings.page.currency_display).format(item.amount)}</p>;
    }
  }

  function onNext() {
    setLoading(true);
    if (next) {
      pageNumber++;
      getLeaderboardData(next.slice(next.search('/givingx'), next.length)).then((response) => {
        setLeaderboardData(response.data.results.slice());
        setNext(response.data.next);
        setPrev(response.data.previous);
        setLoading(false);
      });
    }
  }

  function onPrev() {
    setLoading(true);
    if (prev) {
      pageNumber--;
      getLeaderboardData(prev.slice(prev.search('/givingx'), prev.length)).then((response) => {
        setLeaderboardData(response.data.results.slice());
        setNext(response.data.next);
        setPrev(response.data.previous);
        setLoading(false);
      });
    }
  }

  const getActions = () => {
    const actions = [];
    if (!isEmpty(leaderboardData)) {
      actions.push(<NavigationFooter onNext={onNext} onPrev={onPrev} prevDisabled={!prev} nextDisabled={!next} />);
    }
    actions.push(<InfluenceCard />);
    return actions;
  };

  return (
    <div>
      <div className="section-title">{leaderboard.name}</div>
      <Card
        bordered={false}
        className="leaderboard-card influencer-leaderboard-card campaign-details-block arc-card-box-shadow arc-card-border-radius"
        tabList={tabList}
        activeTabKey={selectedTab}
        onTabChange={onTabChange}
        actions={getActions()}>
        <List
          className="leaderboard-list"
          itemLayout="horizontal"
          dataSource={leaderboardData}
          loading={loading}
          renderItem={(item) => {
            count++;
            return (
              <List.Item>
                {/* <p className="mr8 arc-H100">{count+((pageNumber - 1)*PAGE_SIZE)}</p> */}
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item.profile ? item.profile.present_picture : ''}
                      icon={<UserOutlined />}
                      size="large"
                    />
                  }
                  title={
                    <p className="arc-p arc-color-primary">{item.influencer_name || item.donor_name || 'Anonymous'}</p>
                  }
                  description={getDescription(item)}
                />
              </List.Item>
            );
          }}
        />
      </Card>
    </div>
  );
}

export default InfluencerLeaderboard;
