import React from "react";
import moment from "moment-timezone";
import { string } from 'prop-types';
import { getFormattedDate } from 'Src/common/utilities/moment_util';
import './style.scss';

const DateBlock = ({ startDateTime, endDateTime, shortTimezone, showTime }) => {
    const startDateString = startDateTime ? moment(startDateTime).format('DD MMM YYYY') : null;
    const endDateString = endDateTime ? moment(endDateTime).format('DD MMM YYYY') : null;
    const startTimeString = startDateTime ? `${moment(startDateTime).format('hh:mm a')} ${shortTimezone}` : null;
    const endTimeString = endDateTime ? `${moment(endDateTime).format('hh:mm a')} ${shortTimezone}` : null;

    function isSameDayEvent() {
        if (startDateTime && endDateTime) {
            return moment(startDateTime).format('DD-MM-YYYY') === moment(endDateTime).format('DD-MM-YYYY');
        }
        return false
    }

    if (isSameDayEvent()) {
        if (startDateString) {
            return (
                <div id="date-block-text">
                    <span>
                        <span className="arc-H250 arc-B85 arc-d-block arc-text-align-l">{startDateString}</span>
                        <span className="arc-p arc-color-B55">
                            <span>{startTimeString}</span>
                            {endTimeString && (
                                <span>&nbsp; to {endTimeString}</span>
                            )}
                        </span>
                    </span>
                </div>
            )
        }
    }
    else {
        if (startTimeString || endTimeString) {
            return (
                <div id="date-block-text">
                    <span>
                        <span className="arc-H250 arc-B85 arc-d-block">{startDateString}</span>
                        <If condition={showTime} >
                         <span className="arc-p arc-color-B55">{startTimeString}</span>
                        </If>
                    </span>
                    <If condition={endTimeString}>
                        <span className="arc-H250 arc-B85"> - </span>
                        <span>
                            <span className="arc-H250 arc-B85 arc-d-block">{endDateString}</span>
                            <If condition={showTime} >
                             <span className="arc-p arc-color-B55">{endTimeString}</span>
                            </If>
                        </span>
                    </If>
                </div>
            )
        }
    }
    return null
}

DateBlock.propTypes = {
    startDateTime: string,
    endDateTime: string,
    timezone: string.isRequired
}

DateBlock.defaultProps = {
    startDateTime: null,
    endDateTime: null,
}

export default DateBlock;