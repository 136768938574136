/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { convertFormLayoutToComponentFields } from 'Src/common/utilities/form_utils';
import { lookAndAddAutoFillOptions } from 'Src/common/components/customFormItem/helper';
import { announceToScreenReader } from 'Src/common/utilities/a11y_util';
import { SettingsConsumer } from '../context/settings';
import { PaymentLinkConsumer } from '../context/paymentLink';
import { GiftConsumer } from '../context/gift';
import { success } from '../actionCreators';
import PaymentHeader from './header';
import PageInfo from './header/pageInfo';
import PaymentBody from './body';
import { fetchCustomFormFields } from '../actions/customForm';
import {
  CURRENCY_SELECTOR,
  FUND_SELECTOR,
  AMOUNT_SLABS,
  PREPAYMENT_FORM,
  THANK_YOU_PAGE,
  SHARE_PAGE,
  MODULE_GIFT,
  FAILED_PAYMENT,
  PAYMENT_MODAL_MUST_BE_MASKCLOSABLE,
  PAGE_TYPE_COMPETITIVE_FUNDRAISING,
} from '../constants';

import './style.scss';

function PaymentModal(props) {
  const [settings] = SettingsConsumer();
  const [paymentLink] = PaymentLinkConsumer();
  const [prePaymentCustomFields, setPrePaymentCustomFields] = useState([]);
  const [postPaymentCustomFields, setPostPaymentCustomFields] = useState([]);
  const [gift, giftDispatch] = GiftConsumer();
  const [step, setStep] = useState(props.step);

  const campaignHubFunds = useSelector((state) => state.campaignDataReducer.campaignHubFunds);
  const selectedCampaignId = useSelector((state) => state.campaignDataReducer.selectedCampaignId);
  const selectedCampaignData = useSelector((state) => state.campaignDataReducer.selectedCampaignData);

  function isMaskClosable() {
    return PAYMENT_MODAL_MUST_BE_MASKCLOSABLE.indexOf(step) > -1;
  }

  const shouldOpenFundSelector = () => {
    if (selectedCampaignId) {
      return !isEmpty(campaignHubFunds) && campaignHubFunds.length > 1;
    }
    return settings?.page?.ordered_funds?.length > 1;
  };

  const addParticipationDriveChoices = (fields) => {
    if (
      selectedCampaignData?.participation_question &&
      selectedCampaignData?.participation_question_response &&
      selectedCampaignData?.participation_question_response?.split(',').length === 1
    ) {
      const newFields = [...fields];
      for (const field of newFields) {
        if (field.id === selectedCampaignData?.participation_question) {
          if (field.type === 'checkbox') {
            Object.assign(field, { default_value: selectedCampaignData?.participation_question_response?.split(',') });
          } else {
            Object.assign(field, {
              default_value: selectedCampaignData?.participation_question_response?.split(',')[0],
            });
          }
          break;
        }
      }
      return newFields;
    }
    return fields;
  };

  useEffect(() => {
    setStep(getInitialStep());
    if (!isEmpty(settings.page.pre_payment_form) && settings.page.pre_payment_form.id) {
      fetchCustomFormFields(`custom_forms/${settings.page.pre_payment_form.id}/?fields=fields`)
        .then(async (response) => {
          let result = convertFormLayoutToComponentFields(response.data.fields);
          await lookAndAddAutoFillOptions(result);
          result = addParticipationDriveChoices(result);
          setPrePaymentCustomFields(result);
        })
        .catch(() => {});
    }
    if (!isEmpty(settings.page.post_payment_form) && settings.page.post_payment_form.id) {
      fetchCustomFormFields(`custom_forms/${settings.page.post_payment_form.id}/?fields=fields`)
        .then(async (response) => {
          let result = convertFormLayoutToComponentFields(response.data.fields);
          await lookAndAddAutoFillOptions(result);
          result = addParticipationDriveChoices(result);
          setPostPaymentCustomFields(result);
        })
        .catch(() => {});
    }
  }, []);

  function getInitialStep() {
    if (step) {
      return step;
    }
    if (!isEmpty(window.giftData)) {
      return THANK_YOU_PAGE;
    }
    // if there are multiple accounts with different currencies, take them
    // to currency selector screen
    resetGift();
    if (shouldOpenFundSelector()) {
      return FUND_SELECTOR;
    }
    // This will make sure we select the amount from payment link data
    if (
      !isEmpty(paymentLink.data) &&
      paymentLink.data.pre_filled_values &&
      !isEmpty(paymentLink.data.pre_filled_values.splits)
    ) {
      gift.amount = paymentLink.data.pre_filled_values.splits[0].amount;
      gift.donation_type = 'one_time';
      gift.isLocked = !paymentLink.data.pre_filled_values.splits[0].is_amount_editable;
      giftDispatch(success(MODULE_GIFT, gift));
      if (!paymentLink.data.pre_filled_values.splits[0].is_amount_editable) {
        return PREPAYMENT_FORM;
      }
    }
    return AMOUNT_SLABS;
  }

  function resetGift() {
    if (typeof gift.account === 'undefined') {
      const account =
        settings.page.accounts.filter((accountL) => accountL.currency === settings.page.currency)[0] ||
        settings.page.accounts[0];
      gift.account = account;
      gift.funds = [];

      giftDispatch(success(MODULE_GIFT, gift));
    }
  }

  function getNextStep() {
    switch (step) {
      case CURRENCY_SELECTOR:
        if (shouldOpenFundSelector()) {
          return FUND_SELECTOR;
        }
        return AMOUNT_SLABS;
      case FUND_SELECTOR:
        return PREPAYMENT_FORM;
      case AMOUNT_SLABS:
        return PREPAYMENT_FORM;
      case PREPAYMENT_FORM:
        return THANK_YOU_PAGE;
      case THANK_YOU_PAGE:
        return SHARE_PAGE;
      case FAILED_PAYMENT:
        if (shouldOpenFundSelector()) {
          return FUND_SELECTOR;
        }
        return AMOUNT_SLABS;
      default:
        return null;
    }
  }

  function proceedToNextStep() {
    const nextStep = getNextStep();

    setStep(nextStep);
    if (nextStep === THANK_YOU_PAGE) {
      announceToScreenReader('Your gift has been received');
    } else if (
      nextStep === SHARE_PAGE &&
      !(settings.page.type === PAGE_TYPE_COMPETITIVE_FUNDRAISING && settings.page.is_gift_tribute_enabled)
    ) {
      announceToScreenReader('We could use your help');
    }
  }

  function closeModal(shareId) {
    if (step === SHARE_PAGE) {
      window.giftData = {};
      giftDispatch(success(MODULE_GIFT, { funds: [] }));
      if (shouldOpenFundSelector()) {
        setStep(FUND_SELECTOR);
      } else {
        setStep(AMOUNT_SLABS);
      }
    } else if (step === THANK_YOU_PAGE) {
      return;
    } else {
      setStep(getInitialStep());
    }
    if (typeof props.closeModal !== 'undefined') {
      props.closeModal(shareId);
    }
  }

  // eslint-disable-next-line consistent-return
  function getPreviousStep() {
    switch (step) {
      case CURRENCY_SELECTOR:
        if (settings.page.type === 'crowdfunding') {
          closeModal();
        }
        break;
      case FUND_SELECTOR:
        closeModal();
        if (settings.page.accounts.length > 1) {
          return CURRENCY_SELECTOR;
        }
        break;

      case AMOUNT_SLABS:
        closeModal();
        if (settings.page.accounts.length > 1) {
          return CURRENCY_SELECTOR;
        }
        break;

      case PREPAYMENT_FORM:
        resetGift();
        if (shouldOpenFundSelector()) {
          return FUND_SELECTOR;
        }
        return AMOUNT_SLABS;

      default:
        return null;
    }
  }

  function goBack() {
    const previousStep = getPreviousStep();
    if (previousStep) {
      setStep(previousStep);
    }
  }

  function getWrapperClassName() {
    switch (step) {
      case AMOUNT_SLABS:
        return `amount-selection-wrapper ${window.isFacebookApp() ? 'in-app-browser' : ''}`;
      case THANK_YOU_PAGE:
        return 'transparent-background';
      case PREPAYMENT_FORM:
        return 'pre-payment-form-wrapper';
      case FUND_SELECTOR:
        return 'fund-selector-payment-wrapper';
      default:
        return null;
    }
  }

  return (
    <Modal
      role="main"
      visible
      closable={false}
      footer={null}
      maskClosable={isMaskClosable()}
      keyboard={isMaskClosable()}
      modalRender={<main role="main" aria-label="giving main" />}
      className={`payment-modal ${settings.page.type === 'checkout' ? 'checkout-page-modal' : ''}`}
      wrapClassName={getWrapperClassName()}
      onCancel={() => closeModal()}
      maskStyle={settings.page.type === 'checkout' ? { background: 'transparent', zIndex: 998 } : {}}>
      <If condition={step !== THANK_YOU_PAGE && step !== SHARE_PAGE && step !== FAILED_PAYMENT}>
        <PaymentHeader
          goBack={goBack}
          hideGoBack={(step === AMOUNT_SLABS || step === FUND_SELECTOR) && settings.page.type === 'checkout'}
        />
      </If>
      <If condition={settings.page.type === 'checkout' && (step === AMOUNT_SLABS || step === FUND_SELECTOR)}>
        <PageInfo />
      </If>
      <PaymentBody
        page={settings.page}
        step={step}
        proceedToNextStep={proceedToNextStep}
        prePaymentCustomFields={prePaymentCustomFields}
        postPaymentCustomFields={postPaymentCustomFields}
        closeModal={closeModal}
      />
      <div className="visually-hidden-a11y-div" role="status" aria-live="polite" id="a11y-announcement-region" />
    </Modal>
  );
}

export default withRouter(PaymentModal);
