import React, { Children, useEffect, useState } from 'react';
import { shape } from 'prop-types';
import Almasights from 'almasights';
import AlmasightsContext from 'Src/common/context/almasights';

/**
 * Almasights Provider
 * @param {*} children
 * @param {*} parentObjectId
 * @param {*} parentObjectName
 * @param {*} parentObjectType
 */
function AlmasightsProvider({
  children, parentObject,
}) {
  const [almasightsInstance, setAlmasightsInstance] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setAlmasightsInstance(new Almasights({
      parentObject,
      url: '/api/v1/almasights/touchpoints/',
    }));
    setIsLoading(false);
  }, []);
  return (
    <AlmasightsContext.Provider value={{ almasightsInstance, isLoading }}>
      {Children.only(children)}
    </AlmasightsContext.Provider>
  );
}

AlmasightsProvider.propTypes = {
  children: shape().isRequired,
  parentObject: shape().isRequired,
};

export default AlmasightsProvider;
