import React from 'react';
import './style.scss';

function ImageAndTitleHeader({ title, text }) {
  return (
    <div className="image-title-header">
      <img src={window.customerLogo} alt={`${window.collegeName} logo`} className="header-logo" />
      <p className="header-title arc-H200 arc-color-B85" role="heading" aria-level="2">
        {title}
      </p>
      <If condition={text}>
        <p className="arc-p arc-color-B55 pt8 pr24 pl24">{text}</p>
      </If>
    </div>
  );
}

export default ImageAndTitleHeader;
