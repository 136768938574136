import React from 'react';
import { CURRENCIES } from '../../constants';

function CurrencyRenderer({ currencyId }) {
  const currency = CURRENCIES.filter((cur) => cur.id == currencyId)[0];
  const htmlEntity = currency && currency.html_entity || '';
  return <span dangerouslySetInnerHTML={{ __html: htmlEntity }} />;
}

export default CurrencyRenderer;
