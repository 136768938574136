/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { matchPath } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons';
import { Card, Col, Row, Spin } from 'antd';
import { Share } from 'arcl';
import isEmpty from 'lodash/isEmpty';
import * as Sentry from '@sentry/react';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import queryString from 'query-string';
import instance from 'Src/common/utilities/axios_util';
import { TOUCHPOINTS, PAGE_TYPE_CROWDFUNDING } from 'Src/alumniGiving/constants';
import AlmasightsContext from 'Src/common/context/almasights';
import { SettingsConsumer } from '../context/settings';
import { PaymentLinkConsumer } from '../context/paymentLink';
import CampaignGoalBlock from '../campaign/blocks/goal';
import CampaignDonateBlock from '../campaign/blocks/donate';
import Donors from '../campaign/blocks/donors';
import PaymentModal from '../paymentModal';
import DonorListModal from './donorListModal';
import { GIFTS_PUBLIC_LIST_API } from '../endpoints';
import {
  GIVING_PAY_PATH,
  GIVING_GIFT_SUCCESS_PAYMENT_PATH,
  GIVING_GIFT_FAILED_PAYMENT_PATH,
  GIVING_GIFT_SHARE_PATH,
} from '../routes';
import GalleryCard from './GalleryCard';
import './style.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function HomePage(props) {
  const [settings] = SettingsConsumer();
  const [paymentLink] = PaymentLinkConsumer();
  const [donors, setDonors] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [giftsCount, setGiftsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingDonors, setIsLoadingDonors] = useState(false);
  const { isLoading, almasightsInstance } = useContext(AlmasightsContext);
  const [isDonorModalVisible, setIsDonorModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

  useEffect(() => {
    if (settings.page.is_donor_list_public) {
      fetchPublicGifts(currentPage);
    }

    const matchedPath = matchPath(window.location.pathname, {
      path: GIVING_PAY_PATH,
    });
    const matchedCompleteGiftPath = matchPath(window.location.pathname, {
      path: GIVING_GIFT_SUCCESS_PAYMENT_PATH,
    });
    const matchFailedPaymentPath = matchPath(window.location.pathname, {
      path: GIVING_GIFT_FAILED_PAYMENT_PATH,
    });
    const matchSharePath = matchPath(window.location.pathname, {
      path: GIVING_GIFT_SHARE_PATH,
    });
    const queryS = queryString.parse(props.location.search);
    let openPaymentModalL = null;
    if (!isEmpty(queryS)) {
      openPaymentModalL = queryS.is_payment_modal_open;
    }

    if (
      matchedPath ||
      matchedCompleteGiftPath ||
      matchFailedPaymentPath ||
      matchSharePath ||
      !isEmpty(paymentLink.data) ||
      openPaymentModalL
    ) {
      setIsPaymentModalVisible(true);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      // Create a "Viewed giving page" almasights touchpoint
      almasightsInstance.trackEvent({
        touchpointType: TOUCHPOINTS.VIEWED_GIVING_PAGE,
        triggerType: 'view',
        authToken: window.csrfToken,
        object: null,
      });
    }
  }, [isLoading]);

  function fetchPublicGifts(page) {
    setIsLoadingDonors(true);
    instance
      .get(GIFTS_PUBLIC_LIST_API.replace('{}', settings.page.id).concat(`?status=completed&page=${page}`))
      .then((response) => {
        const { results, next, count } = response.data;
        setDonors([...donors, ...results]);
        setHasMore(!!next);
        setGiftsCount(count);
        setIsLoadingDonors(false);
      })
      .catch(() => {
        setIsLoadingDonors(false);
      });
  }

  function openPaymentModal() {
    if (!settings.page.external_url) {
      setIsPaymentModalVisible(true);
    } else {
      window.open(settings.page.external_url, '_blank');
    }
  }

  function closeModal() {
    setIsPaymentModalVisible(false);
    props.history.push(`/g/${settings.page.slug}/`);
  }

  const galleryImages = settings.page.images.filter((image) => image.image_type === 'gallery_image');

  function loadMoreGifts() {
    const newPageCount = currentPage + 1;
    setCurrentPage(newPageCount);
    fetchPublicGifts(newPageCount);
  }

  return (
    <div>
      <If condition={settings.page.type === PAGE_TYPE_CROWDFUNDING}>
        <div className="campaign-donate-wrapper">
          <Row type="flex" justify="space-between" gutter={32}>
            <Col lg={14} md={14} sm={12} xs={24}>
              <div className="mb16">
                <p className="arc-color-black campaign-donate-title">{window.pageData.name}</p>
              </div>
              <If condition={settings.page.goal}>
                <CampaignGoalBlock />
              </If>
            </Col>
            <If condition={settings.page.accounts.length || settings.page.external_url}>
              <Col lg={8} md={8} sm={12} xs={24}>
                <CampaignDonateBlock
                  endDate={settings.page.end_date}
                  openPaymentModal={openPaymentModal}
                  callToActionText={settings.page.call_to_action_text}
                />
              </Col>
            </If>
          </Row>
        </div>
        <div className="campaign-details-wrapper">
          <Row type="flex" justify="space-between" gutter={32}>
            <Col lg={16} md={16} sm={24} xs={24}>
              <Card bordered={false} className="fund-detail-card arc-card-box-shadow arc-card-border-radius fr-view">
                <div
                  dangerouslySetInnerHTML={{
                    __html: settings.page.description,
                  }}
                />
              </Card>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <If condition={settings.page.donor_visibility !== 'hide_donors' && donors.length > 0}>
                <Card bordered={false} className="fund-detail-card arc-card-box-shadow arc-card-border-radius">
                  <Choose>
                    <When condition={isLoadingDonors && !donors.length}>
                      <Spin indicator={antIcon} className="loading-donors-icon" />
                    </When>
                    <Otherwise>
                      <p className="arc-H200 arc-color-B85 arc-text-capitalize arc-card-title-mb">
                        <I18nCustomFormatter id="gifts" values={{ gifts: giftsCount }} />
                      </p>
                      <Donors donors={donors} loading={isLoadingDonors} page={settings.page} />
                      <If condition={donors.length > 5}>
                        <a
                          href="javascript:void(0)"
                          onClick={() => setIsDonorModalVisible(true)}
                          className="daenerys-fs-9 daenerys-fw-300 primary-color-text no-text-decoration">
                          <I18nCustomFormatter id="view-more-gifts-label" />
                        </a>
                      </If>
                    </Otherwise>
                  </Choose>
                  <If condition={isDonorModalVisible}>
                    <DonorListModal
                      isDonationAmountHidden={
                        window.isDonationAmountHidden || settings.page.donor_visibility === 'hide_gift_amount'
                      }
                      title={<I18nCustomFormatter id="gifts" values={{ gifts: giftsCount }} />}
                      data={donors}
                      handleClose={() => setIsDonorModalVisible(false)}
                      hasMore={hasMore}
                      loadMoreGifts={loadMoreGifts}
                      loading={isLoadingDonors}
                    />
                  </If>
                </Card>
              </If>
              <div className="share-gallery-card">
                <If condition={galleryImages.length}>
                  <GalleryCard images={galleryImages} />
                </If>
                <Card bordered={false} className="share-detail-card">
                  <p className="arc-H200 arc-color-B85">
                    <I18nCustomFormatter id="share-card-label" />
                  </p>
                  <p className="arc-p arc-color-B55">
                    <I18nCustomFormatter id="share-card-description" />
                  </p>
                  <div className="share-card-actions mt16">
                    <Share
                      title={settings?.page?.name || ''}
                      url={settings.page.social_share_url || `${window.location.origin}/g/${settings.page.slug}`}
                      style={{ width: '100%', padding: '8px 0' }}
                      trigger={['click']}
                      disableNativeShare>
                      <p>
                        <FontAwesomeIcon icon={faShareAlt} className="mr8" />
                        <span>Share</span>
                      </p>
                    </Share>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </If>
      <If condition={isPaymentModalVisible}>
        <PaymentModal closeModal={closeModal} {...props} />
      </If>
    </div>
  );
}

export default Sentry.withProfiler(withRouter(HomePage));
