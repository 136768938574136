import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Select, Spin } from 'antd';
import { PROPERTY_OPTION_API_PATH } from 'Src/common/endpoints';
import instance from 'Src/common/utilities/axios_util';
import { debounce } from 'lodash';

const { Option } = Select;
const FormItem = Form.Item;

export const ExternalSourceSelect = ({ formItem, getKeyValue, form, _this }) => {
  const [next, setNext] = useState(null);
  const [serachNextUrl, setSerachNextUrl] = useState(null);
  const [choices, setChoices] = useState([]);
  const [searchChoices, setSearchChoices] = useState(null);
  const [fetching, setFetching] = useState(true);

  const { getFieldDecorator } = form;

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const { choice_source_identifier } = formItem;
    let url = PROPERTY_OPTION_API_PATH.replace('{}', choice_source_identifier);
    try {
      setFetching(true);
      const response = await instance.get(url);
      if (response && response.data) {
        let { next_page, data } = response.data;
        data = data.map(({ label, value }) => ({ label, value: value.toString() }))
        setChoices([...choices, ...data]);
        if (next_page) setNext(url.concat('&page_no=', next_page));
        else setNext(null);
      }
      setFetching(false);
    } catch (err) {
      console.error(err);
      setNext(null);
      setFetching(false);
    }
  };

  const loadSerachData = async () => {
    if (serachNextUrl) {
      setFetching(true);
      let url = serachNextUrl;
      try {
        const response = await instance.get(url);
        if (response && response.data) {
          let { next_page, data } = response.data;
          data = data.map(({ label, value }) => ({ label, value: value.toString() }))
          setSearchChoices([...searchChoices, ...data]);
          if (next_page) setSerachNextUrl(url.replace(`&page_no=${next_page - 1}`, `&page_no=${next_page}`));
          else setSerachNextUrl(null);
        }
        setFetching(false);
      } catch (err) {
        console.error(err);
        setSerachNextUrl(null);
        setFetching(false);
      }
    }
  };

  const loadData = async () => {
    if (next) {
      setFetching(true);
      let url = next;
      try {
        const response = await instance.get(url);
        if (response && response.data) {
          let { next_page, data } = response.data;
          data = data.map(({ label, value }) => ({ label, value: value.toString() }))
          setChoices([...choices, ...data]);
          if (next_page) setNext(url.replace(`&page_no=${next_page - 1}`, `&page_no=${next_page}`));
          else setNext(null);
        }
        setFetching(false);
      } catch (err) {
        console.error(err);
        setNext(false);
        setFetching(false);
      }
    }
  };

  const handleSearch = async (serachText) => {
    if (serachText) {
      const { choice_source_identifier } = formItem;
      const url = PROPERTY_OPTION_API_PATH.replace('{}', choice_source_identifier).concat('&search_text=', serachText);
      try {
        setFetching(true);
        const response = await instance.get(url);
        if (response && response.data) {
          let { next_page, data } = response.data;
          data = data.map(({ label, value }) => ({ label, value: value.toString() }))
          setSearchChoices(data);
          if (next_page) setSerachNextUrl(url.concat('&page_no=', next_page));
          else setSerachNextUrl(null);
        }
        setFetching(false);
      } catch (err) {
        console.error(err);
        setSerachNextUrl(null);
        setFetching(false);
      }
    } else {
      setSearchChoices(null);
      setSerachNextUrl(null);
    }
  };

  const onScroll = (event) => {
    var target = event.target;
    if (!fetching && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (searchChoices) loadSerachData();
      else loadData();
    }
  };

  const getOptions = () => {
    let options = choices || [];
    if (searchChoices) {
      options = searchChoices;
    }
    return options.map((item) => (
      <Option key={item.value} value={item.value}>
        {item.label}
      </Option>
    ));
  };

  return (
    <FormItem
      label={_this.renderFormItemLabel(formItem)}
      aria-label={_this.getAriaLabel(formItem)}
      extra={_this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
      colon={false}>
      {getFieldDecorator(formItem.key, {
        initialValue: getKeyValue(formItem, 'default_value', ''),
        rules: [
          {
            required: getKeyValue(formItem, 'rules__required__value', false),
            message: getKeyValue(formItem, 'rules__required__message', 'Please enter value'),
          },
          {
            validator: _this.validateFormHandler.bind(_this, getKeyValue(formItem, 'validator', false)),
          },
        ],
      })(
        <Select
          allowClear={true}
          showSearch={getKeyValue(formItem, 'show_search', true)}
          style={{ width: '100%' }}
          onSearch={debounce(handleSearch, 500)}
          disabled={getKeyValue(formItem, 'disabled', false)}
          onPopupScroll={onScroll}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          notFoundContent={fetching ? <Spin size="small" /> : null}>
          {getOptions()}
        </Select>,
      )}
    </FormItem>
  );
};
