import React, { Component } from "react";
import { array } from 'prop-types'
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Modal, Spin } from 'antd';
import ImageWithFallbackSrc from 'Src/common/components/imageWithFallbackSrc';
import GalleryMaximizedModal from 'Src/common/components/galleryMaximizedModal';
import { getImagePath } from "../../../common/utilities/dom_utils";


import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/pro-light-svg-icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
const infoModal = Modal.info;
const IMAGES_OFFSET = 10;

class GalleryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxImageCount: IMAGES_OFFSET,
      images: props.images,
      isImageMaximized: false,
      imageUrl: null
    };
  }

  handleViewMore() {
    const count = this.state.maxImageCount + IMAGES_OFFSET;
    this.setState({
      maxImageCount: count
    })
  }

  openImage = (imageUrl) => {
    this.setState({ imageUrl }, () => {
      this.setState({ isImageMaximized: true })
    })
  }

  close = () => {
    this.setState({ isImageMaximized: false })
  }


  render() {
    const { maxImageCount, images, isImageMaximized, imageUrl } = this.state;
    return (
      <Card bordered={false} className="giving-card" id="gallery-card">
        <p className="arc-H200 arc-color-B85">Gallery</p>
        <div id="gallery-items">
          {images.slice(0, maxImageCount).map(image => {
            return (
              <ImageWithFallbackSrc
                className="gallery-cover-photo"
                src={getImagePath(image.image, 'thumbnail')}
                onClick={() => this.openImage(image.image)}
                fallbackSrc={image.image}
              />
            )
          })}
        </div>
        <If condition={images.length > maxImageCount}>
          <p className="arc-anchor mt8 arc-d-inline-block arc-cursor-p pl2" onClick={() => this.handleViewMore()}>
            <FontAwesomeIcon icon={faImages} className="mr8"/>
            <span>View More</span>
          </p>
        </If>
        <If condition={isImageMaximized}>
          <GalleryMaximizedModal wrapClassName="gallery-image-modal" close={() => this.close()} url={imageUrl}></GalleryMaximizedModal>
        </If>
      </Card>
    )
  }
}

GalleryCard.propTypes = {
  images: array.isRequired
}

export default GalleryCard;
