import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from "antd";
import FormLabelAlongTooltip from 'Src/common/components/formLabelAlongTooltip'
import { getKeyValue } from "Src/common/utilities/data_util";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*
  Todo this component should spit out only one field with all the required values
*/
const FormItem = Form.Item;

class PlaceInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLatLng = this.getLatLng.bind(this);
    this.validatePlace = this.validatePlace.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderFormItemLabel = this.renderFormItemLabel.bind(this);
  }

  static defaultProps = {
    form: {},
    formInput: {},
    formRecord: {},
    renderHelpTextWithIcon:false
  };

  static propTypes = {
    form: PropTypes.object,
    formInput: PropTypes.object,
    formRecord: PropTypes.object,
    renderHelpTextWithIcon: PropTypes.bool
  };

  componentDidMount() {
    const formItem = this.props.formInput;
    var placeInput = document.getElementById(this.props.formInput.key);
      const types={
          "cities" : ["(cities)"],
          "regions" : ["(regions)"],
          "anywhere" : []
      }
      var restriction_type=["(cities)"];
      restriction_type = types[formItem.options[0].value];
      const options = {
          types: restriction_type
      };
      var places = new window.google.maps.places.Autocomplete(
          placeInput,
          options
      );
      window.google.maps.event.addListener(places, "place_changed", () => {
          this.getLatLng(places);
      });
  }
  getLatLng=(place)=>{
    var googlePlace = place.getPlace();
    let d = {
      [`google_place_${this.props.formInput.key}`]: {
        gpid: googlePlace.place_id,
        name: googlePlace["formatted_address"]
      }
    };
    d[this.props.formInput.key] =  googlePlace["formatted_address"];
    this.props.form.setFieldsValue(d);
  }

  handleChange= (e) => {
    // We will keep the value of google place id field as null untill user selects something from list
    let d = {
        [`google_place_${this.props.formInput.key}`]: null
      };
      this.props.form.setFieldsValue(d);

  };
  validatePlace=(rule, value, callback)=>{
    value = value && value.trim();
    if(value && !this.props.form.getFieldValue(`google_place_${this.props.formInput.key}`)) {
      callback('Please pick a location from the list. If you are unable to view the list, kindly erase and re-enter your location.')
    }
    callback();
  };

  renderFormItemLabel(formItem) {
    let tooltipText = getKeyValue(formItem, "tooltip_text");
    if (tooltipText) {
      return (
        <FormLabelAlongTooltip label={formItem.label} tooltipText={tooltipText} />
      );
    }
    return formItem.label;
  }
  renderHelpTextWithIcon(helpText){
      if(helpText){
          return(
              <span><FontAwesomeIcon icon={faInfoCircle} className="mr8"/>{helpText}</span>
              )
      }
      
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItem = this.props.formInput;
    return (
      <div>
        <FormItem
              label={this.renderFormItemLabel(formItem)}
              extra={this.props.renderHelpTextWithIcon?this.renderHelpTextWithIcon(getKeyValue(formItem, "help_text")):getKeyValue(formItem, "help_text")}
              colon={false}
          >
              {getFieldDecorator(formItem.key, {
                  initialValue: getKeyValue(formItem, "default_value", ""),
                  rules: [
                      {
                          required: getKeyValue(
                              formItem,
                              "rules__required__value",
                              false
                          ),
                          message: getKeyValue(
                              formItem,
                              "rules__required__message",
                              "Please enter a location"
                          )
                      },
                      {
                          validator: this.validatePlace
                      }
                  ]                            
              })(
                  <Input placeholder={formItem.placeholder} aria-label={this.renderFormItemLabel(formItem)} onChange={this.handleChange} autoComplete="off" />
              )}
          </FormItem>
          <Form.Item style={{ display: "none" }}>
              {getFieldDecorator(`google_place_${formItem.key}`, {
                  initialValue: null                  
              })(<Input type="hidden" />)}
              </Form.Item>
      </div>
    );
  }
}

export default PlaceInput;
