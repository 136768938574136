/* eslint-disable */

import React from 'react';
import { string, func, bool, oneOfType, object } from 'prop-types';
import { Modal, message, Button } from 'antd';
import { Share } from 'arcl';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import ShareBlock from 'Src/common/components/ShareBlock';
import { getMetaContent, copyToClipboard } from './../../utilities/dom_utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/pro-solid-svg-icons';
import './style.scss';
import { announceToScreenReader } from 'Src/common/utilities/a11y_util';

const ogImage = getMetaContent('og:image');

function ThemeXShareModal({
  className,
  visible,
  url,
  title,
  description,
  twitterShareText,
  closeText,
  handleClose,
  dashboardUrl,
  dashboardAltMsg,
  shareBlockText,
  hideViewDashboard,
  goBack,
}) {
  let facebookUrl = '';
  let twitterUrl = '';
  if (url) {
    facebookUrl = new URL(url);
    twitterUrl = new URL(url);
    facebookUrl.searchParams.set('utm_medium', 'facebook');
    twitterUrl.searchParams.set('utm_medium', 'twitter');
  }
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${facebookUrl}&display=popup`;
  const twUrl = `http://twitter.com/share?text=${twitterShareText}&url=${encodeURIComponent(twitterUrl)}`;

  function copyLink(url) {
    copyToClipboard(url);
    message.success('Copied link to clipboard');
  }

  const containerClassName = `themex-share-modal ${className}`;

  return (
    <Modal
      visible={visible}
      className={containerClassName}
      width="25rem"
      closable={true}
      maskClosable={false}
      keyboard={false}
      footer={null}
      onCancel={() => handleClose()}>
      <div className="themex-share-modal-header">
        <h1 className="arc-H300 title" tabIndex="0" role="heading">
          {title}
        </h1>
        <p className="arc-p arc-color-B45">{description}</p>
      </div>
      <If condition={shareBlockText}>
        <div className="share-block-wrapper">
          <ShareBlock title={shareBlockText} url={url} />
        </div>
      </If>

      <div
        className="themex-share-back arc-card-box-shadow"
        style={{ backgroundImage: `url(${staticPath}img/react-modules/share_og_back.png)` }}>
        <img src={ogImage} className="themex-share-image" alt="Share image" />
      </div>
      <div>
        <Share
          title={window?.pageData?.name || 'Giving page'}
          url={url}
          style={{ width: '100%', padding: '8px 0' }}
          trigger={['click']}
          disableNativeShare
          tabIndex="0"
          onVisibleChange={(visible) => {
            announceToScreenReader(`Share dropdown ${visible ? 'expanded' : 'collapsed'}`);
          }}>
          <div role="button">
            <FontAwesomeIcon className="mr8" icon={faShare} />
            <I18nCustomFormatter id="giving-checkout-share-text" />
          </div>
        </Share>
      </div>
      <div className="themex-share-links">
        <If condition={dashboardUrl || dashboardAltMsg}>
          <Choose>
            <When condition={dashboardUrl}>
              <Button
                type="link"
                shape="round"
                href={dashboardUrl}
                tabIndex="0"
                className="dashboard-link mt16"
                target="_blank">
                View my dashboard
              </Button>
            </When>
            <Otherwise>
              <div className="alt-block">{dashboardAltMsg}</div>
            </Otherwise>
          </Choose>
        </If>
      </div>
      <Button
        onClick={() => handleClose()}
        tabIndex="0"
        className="close-action arc-btn-subtle mt16 arc-p arc-color-B20">
        {goBack ? goBack : 'Go Back'}
      </Button>
      <div className="visually-hidden-a11y-div" role="status" aria-live="polite" id="a11y-announcement-region" />
    </Modal>
  );
}

ThemeXShareModal.propTypes = {
  visible: bool.isRequired,
  title: oneOfType([string, object]),
  description: oneOfType([string, object]),
  closeText: string,
  url: string,
  handleClose: func.isRequired,
  twitterShareText: string,
};

ThemeXShareModal.defaultProps = {
  title: 'Invite a friend',
  description: '',
  closeText: 'Close',
  url: `${window.location.origin}${window.location.pathname}`,
  twitterShareText: '',
};

export default ThemeXShareModal;
