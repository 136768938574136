export const GIVING_BASE_API_PATH = '/givingx/';
export const LIST_PAGES_BASE_API_PATH = '/givingx/pages/';
export const MANAGE_PAGES_BASE_API_PATH = '/givingx/pages/manage/';
export const CONFIG_API_PATH = `${GIVING_BASE_API_PATH}config/details/?hide_internal_funds=True`;
export const GIVING_IMAGES_API_PATH = `${MANAGE_PAGES_BASE_API_PATH}{}/images/`;
export const GIVING_DUPLICATE_API_PATH = `${MANAGE_PAGES_BASE_API_PATH}{}/duplicate/`;

export const IS_PAGE_NAME_VALID = `${MANAGE_PAGES_BASE_API_PATH}is_name_valid/?name={}`;
export const DELETE_PAYMENT_API_PATH = `${MANAGE_PAGES_BASE_API_PATH}<page_id>/accounts/{}/`;
export const GIFTS_API_PATH = '/givingx/pages/{}/gifts/';
export const RECURRING_GIFTS_API_PATH = '/givingx/pages/{}/recurring_gifts/';
export const RECURRING_GIFTS_PATCH_API_PATH = '/givingx/recurring_gifts/{}/';
export const RECURRING_GIFTS_SUMMARY_API_PATH = '/givingx/pages/{}/recurring_gifts/summary/';
export const GIFT_AID_SUMMARY_API_PATH = '/givingx/pages/{}/gift_aid_gifts/summary/';
export const REPORTS_API_PATH = `${MANAGE_PAGES_BASE_API_PATH}{}/report/`;
export const GIFT_API_PATH = `${GIVING_BASE_API_PATH}gifts/{}/`;
export const LEADERBOARDS_API = '/givingx/pages/{}/leaderboards/';
export const GET_TIMEZONE_LIST_API_PATH = 'timezones';
export const CHALLENGE_API_PATH = `${LIST_PAGES_BASE_API_PATH}{}/challenges/`;
export const INFLUENCERS_API = '/givingx/pages/{}/influencers/';
export const PAYMENT_LINK = '/givingx/{}/links';
export const MANAGE_PAYMENT_LINK_TEMPLATE = '/admin/givingx/payment_link_templates/<template_id>';
export const PAYMENT_CONFIG_PATH = '/givingx/pages/manage/<page_id>/accounts/<account_id>/config/';
export const RECURRING_CONFIG_PATH = `${GIVING_BASE_API_PATH}recurring_gifts/<recurring_gift_id>/`;
export const ALMASYNC_MAPPING_PATH = '/manage/almasync/mapping/';
export const ENTITY_FIELDS_PATH =
  '/manage/almacrm/entities?entities=basic_information,educations,employments,addresses,emails,other_educations,phone_numbers,affiliations';
export const SYNC_PATH = `${GIVING_BASE_API_PATH}pages/<page_id>/sync`;
export const SINGLE_PROFILE_PATH =
  '/profiles?id={}&page_size=5&page=1&&active=true&fields=can_email,external_database_id,formatted_name,active,email_addresses,email_addresses__status,email_addresses__is_primary,address,slug,id,role,profile_url,location,nick_name,maiden_name,present_picture,educations__class_year,listed,is_registered,phone_numbers__number,phone_numbers__type,groups';
export const GET_CHANGE_PATH = 'manage/almasync/changes';
export const GIFT_SETTINGS = 'givingx/gifts/fields/settings/';
export const GET_GIFT_AUTH_SETTINGS = 'givingx/gift_sync_settings/';
export const LIST_APPEAL_API_PATH = '/givingx/renxt/appeals/';
export const LIST_PACKAGES_API_PATH = '/givingx/renxt/packages/';
export const LIST_CAMPAIGN_API_PATH = '/givingx/renxt/campaigns/';
export const HUB_PATH = 'givingx/pages/<page_id>/hubs/';
export const CAMPAIGN_PATH = 'givingx/pages/hubs/<hub_id>/campaigns/';
export const SECTION_REORDER_PATH = 'givingx/pages/<page_id>/section_order/';
export const FORM_FIELDS_PATH = 'custom_forms/<form_id>/fields/';
export const ADMIN_ACTION_PATH = 'websites/admin_action';
export const USER_FEEDBACK_API_PATH =
  'manage/user_feedback/overview?parent_object_id=<page_id>&content_type=givingxgift';
export const LIST_USER_FEEDBACK_API_PATH = 'manage/user_feedbacks?parent_object_id=<page_id>&skip_pagination=1';
export const CHECK_SIMILAR_FORM_FIELD_NAME_URL = '/givingx/pages/manage/{}/check_similar_form_field_name/';
export const GIFT_AID_API_PATH = '/givingx/pages/{}/gift_aid_gifts/';
export const PROFILE_UPDATE_API_PATH = '/profiles/<profile_id>';
export const IGNORE_SIMILAR_FORM_FIELDS_URL = '/givingx/pages/manage/{}/ignore_duplicate_form_field_warning';
export const CREATE_PROFILE_FROM_EXTERNAL_DATABASE_ID_API_PATH = '/profiles/create_from_external_database';
