import React from "react";
import { List } from "antd";
import ThemeXProfileDetailItem from "Src/common/components/themeXProfileDetailItem";
import { CURRENCIES } from "Src/common/constants";
import "./style.scss";

function Donors(props) {

  function getCurrencyEntity(id) {
      if (id){
        return CURRENCIES.filter(cur => cur.id === id)[0].html_entity;
      }
  }

  function shouldShowAmount(item) {
    if(window.isDonationAmountHidden || props.page.donor_visibility === 'hide_gift_amount') {
      return void 0
    }
    return `${getCurrencyEntity(item.currency)}${item.amount}`;
  }

  return (
    <List
      className="donors-list"
      itemLayout="horizontal"
      dataSource={props.donors.slice(0, 5)}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <ThemeXProfileDetailItem
                src={item.profile && item.profile.present_picture}
                url={item.profile && item.profile.profile_url}
                title={item.donor_name}
                extra={shouldShowAmount(item)}
                isExtraHtmlNode={true}
                hideProfileAttributes={true}
                size="large"
              />
            }
          />
        </List.Item>
      )}
    />
  );
}

export default Donors;
