import React from "react"
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from "antd";
import Img from 'react-image'
import './style.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

function GalleryMaximizedModal({url, wrapClassName, close}) {
    return(
        <Modal
            footer={null}
            width="100%"
            wrapClassName={wrapClassName}
            visible
            onCancel={close}
            centered
            maskStyle={{ backgroundColor: 'rgba(0,0,0,0.85) !important'}}
        >
            <Img src={url} loader={<div className="loader-wrapper"><Spin indicator={antIcon} /></div>} id="gallery-image" />
        </Modal>    
    )
}

export default GalleryMaximizedModal;