import React from 'react';
import { string } from 'prop-types';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GoogleCalenderLink = ({
  eventTitle, startDateTime, endDateTime, timezone, description, location, title, className,
}) => {
  let googleCalenderUrl = 'http://www.google.com/calendar/render?action=TEMPLATE';

  if (startDateTime) {
    googleCalenderUrl = googleCalenderUrl.concat('&dates=', `${startDateTime}`, 'Z');
    if (endDateTime) {
      googleCalenderUrl = googleCalenderUrl.concat('/', endDateTime, 'Z');
    } else {
      googleCalenderUrl = googleCalenderUrl.concat('/', startDateTime, 'Z');
    }
  }

  if (description) {
    googleCalenderUrl = googleCalenderUrl.concat('&details=', description);
  }

  if (location) {
    googleCalenderUrl = googleCalenderUrl.concat('&location=', location);
  }

  if (timezone) {
    googleCalenderUrl = googleCalenderUrl.concat('&ctz=', timezone);
  }

  googleCalenderUrl = googleCalenderUrl.concat('&sf=true&output=xml');
  googleCalenderUrl = googleCalenderUrl.concat('&text=', encodeURIComponent(eventTitle));

  return (
    <a href={googleCalenderUrl} target="_blank" rel="nofollow" className={`google-calender-link ${className}`}>
      <FontAwesomeIcon icon={faCalendarPlus} className="mr8" />
      {' '}
      <span>
        {title || <I18nCustomFormatter id="add-to-calendar-default-title" defaultMessage="Add to Calendar" /> }
      </span>
    </a>
  );
};

GoogleCalenderLink.propTypes = {
  eventTitle: string.isRequired,
  startDateTime: string,
  endDateTime: string,
  description: string,
  location: string,
};

GoogleCalenderLink.defaultProps = {
  startDateTime: '',
  endDateTime: '',
  description: '',
  location: '',
};

export default GoogleCalenderLink;
