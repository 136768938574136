function initializeCaptchaV2(callbackFn) {
  let captchaV2Id;

  window.grecaptcha.ready(() => {
    captchaV2Id = window.grecaptcha.render('g-recaptcha', {
      sitekey: window.googleCaptchaV2Key,
      callback: (token) => {
        callbackFn(token);
      },
    });

    window.grecaptcha.execute(captchaV2Id);
  });

  return captchaV2Id;
}

export default initializeCaptchaV2;
