/* eslint-disable max-len */
export const LOGIN_API = '/login/';
export const RESET_PASSWORD_API = '/_send_password_reset_email';
export const GET_PROFILE_BY_EMAIL_API_PATH = 'profiles/profile_by_email?email={}';
export const GET_PROFILE_API_PATH = 'profiles/{}?fields=first_name,last_name,email';
export const GET_PROFILES_API_PATH = 'profiles?id__in={}&fields=id,first_name,last_name,full_name,profile_url';
export const PROFILE_CREATE_API_PATH = 'profiles';
export const SIGNUP_MODAL_IMAGES_PATH = 'websites/singnup_modal_images';

// Stream

export const DEFAULT_TOKEN_API_PATH = 'feeds/users/default_user_token/';
export const TOKEN_API_PATH = 'feeds/users/{}/user_token/';
export const STREAM_ID_API_PATH = 'feeds/stream_id/{}/';

export const PUBLIC_FEED_API_PATH = 'feeds/stream/{module_name}/{object_id}/get_public_feed/';
export const FEED_GET_API_PATH = 'feeds/stream/{module_name}/{object_id}/feed/{feed_type}/';
export const REACTIONS_GET_API_PATH = 'feeds/stream/{module_name}/{object_id}/feed/{activity_id}/{reaction_type}/';

export const FEED_POST_API_PATH = 'feeds/{module_name}/{object_id}/posts/';
export const COMMENT_POST_API_PATH = 'feeds/{module_name}/{object_id}/comments/';
export const REACTIONS_POST_API_PATH = 'feeds/{reaction_on}/{foreign_id}/reactions/';

export const GET_STREAM_IMAGE_UPLOAD_API = 'https://api.stream-io-api.com/api/v1.0/images/?api_key={}';
export const GET_STREAM_OG_FETCH_API = 'https://api.stream-io-api.com/api/v1.0/og/?url={url}&api_key={}';

// Community

export const COMMUNITY_DETAIL_API_PATH = 'groups/{}/';
export const COMMUNITY_CATEGORIES = 'groups/categories/?skip_pagination=true';
export const COMMUNITY_MEMBERS_API_PATH = 'groups/{}/members/{member_type}/';
export const COMMUNITY_ADMIN_MEMBERS_API_PATH = 'groups/{}/members/admins/?skip_pagination=true';
export const COMMUNITY_MEMBER_MANAGE_API_PATH = 'groups/{}/members/manage/';

// BASE URLS

export const COMMUNITY_ALUMNI_BASE_URL = '/groups/{}/';

// EMAIL URLS

export const EMAIL_API = 'emails/{}';
export const EMAIL_GROUPS_API_PATH =
  'emails/groups?fields=id,name,type,group_id&content_type__id={type_id}&object_id={object_id}';
export const SUB_EVENT_EMAIL_GROUPS_API_PATH = `emails/groups?fields=id,name,type,group_id&content_type__id={type_id}&object_id__in={object_id}&skip_pagination=true`;
export const EMAIL_GROUPS_MEMBERS_API_PATH = 'emails/groups/{}/members';
export const EMAIL_GROUPS_MEMBER_COUNT_API = 'emails/groups/members_count?group_ids={}';
export const EMAIL_API_DETAILS = `${EMAIL_API}?fields=group__id,sender__id,updated_at,subject,internal_name,reply_to_emails,body_html,body_json,id,group__name,sender__formatted_name,sender__email,sender__present_picture,sender__profile_url,attachments__name,attachments__id,attachments__file,sender__slug,template,status,status_display,scheduled_at`;
export const DEFAULT_EMAIL_TEMPLATES_API = 'emails/templates/default/';
export const EMAIL_TEMPLATE_SECTIONS_API = 'emails/templates/sections/';
export const DEFAULT_EMAIL_TEMPLATE_SECTIONS_API = 'emails/templates/default/sections/';
export const EMAIL_TEMPLATES_API = 'emails/templates/';
export const EMAIL_TEMPLATES_API_WITH_SECTION = 'emails/templates/default/?page_size=20&page=1&section__slug={}';
export const AUTOMATED_EMAIL_TEMPLATES_API_PATH = 'emails/templates/automated/';

export const AUTOMATED_EMAIL_TEMPLATE_BEE_CONFIG_API_PATH = 'emails/templates/automated/{}/bee_config';

export const SCHEDULED_AUTOMATED_EMAILS_API_PATH = 'emails/automated/scheduled/';

// Custom Forms
export const CUSTOM_FORMS_LIST_API_PATH = '/custom_forms';

// Data studio profile count api
export const DATASTUDIO_PROFILE_COUNT_API_PATH = 'datastudio/get_profile_count/';
// Almasights URLS
export const ALMASIGHTS_COUNT_API = '/almasights/count/';
export const ALMASIGHTS_LIST_OF_EVENTS = '/almasights/touchpoints/';
export const ALMASIGHTS_EVENT_DISTRIBUTION_API = '/almasights/distribution/';

// Sendgrid email verification url
export const EMAIL_VALIDATION_URL = 'emails/validate_email_address';

// Verification groups endpoints
export const VERIFICATION_GROUPS_API_PATH = '/groups/manage/?type=verification_tag';

export const PROPERTY_OPTION_API_PATH = 'crm/options/?property_slug={}';

export const MERGE_TAG_API = 'emails/groups/{}/merge_tags';
