import React from 'react';
import { string, bool, object } from 'prop-types';
import HeroBanner from './../../heroBanner';
import ThemeXHeader from './../../themeXHeader/v1';
import ThemeXFooter from '../../themeXFooter';
import './style.scss';

const ThemeXContainerV1 = ({
  isLoaded,
  heroBannerImage,
  heroLogo,
  heroTitle,
  showHeroBackLink,
  heroBackText,
  heroBannerChildren,
  isDefaultCoverImage,
  redirectLink,
  children,
  displayStartIn,
  showCountDown,
  countDownCallback,
  startDateTime,
  endDateTime,
  backPage,
  isImageResizingEnabled,
  logoRedirectUrl,
}) => {
  return (
    <div id="themex-wrapper-v1">
      <div id="themex-container-v1">
        <ThemeXHeader backPage={backPage} logoRedirectUrl={logoRedirectUrl} />
        <HeroBanner
          isImageResizingEnabled={isImageResizingEnabled}
          isLoaded={isLoaded}
          logo={heroLogo}
          bannerImage={heroBannerImage}
          title={heroTitle}
          showBackLink={showHeroBackLink}
          backText={heroBackText}
          redirectLink={redirectLink}
          isDefaultCoverImage={isDefaultCoverImage}
          displayStartIn={displayStartIn}
          showCountDown={showCountDown}
          countDownCallback={countDownCallback}
          startDateTime={startDateTime}
          endDateTime={endDateTime}>
          {heroBannerChildren}
        </HeroBanner>
        <div id="themex-body-v1">{children}</div>
        <ThemeXFooter />
      </div>
    </div>
  );
};

ThemeXContainerV1.propTypes = {
  showHeroBanner: bool,
  isLoaded: bool,
  heroBannerImage: string,
  heroLogo: string,
  heroTitle: string,
  heroBackText: string,
  redirectLink: string,
  showHeroBackLink: bool,
  showHeader: bool,
  userData: object,
  isDefaultCoverImage: bool,
  isImageResizingEnabled: bool,
};

ThemeXContainerV1.defaultProps = {
  isLoaded: false,
  heroShowBackLink: false,
  redirectLink: null,
  showHeader: false,
  userData: {},
  isDefaultCoverImage: false,
  heroLogo: null,
  isImageResizingEnabled: false,
};
export default ThemeXContainerV1;
