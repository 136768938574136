import React from 'react';
import { Col, Row, Progress, Statistic } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faGift, faSeedling } from '@fortawesome/pro-light-svg-icons';
import CurrencyRenderer from 'Src/common/components/currencyRenderer';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

import './style.scss';

function CampaignGoalBlock({ completionPercentage, goal, goalPrefix, currency, countDonors, countGifts, amount }) {
  function getCompletionPerc() {
    if (completionPercentage < 3) {
      return 3;
    }
    return parseFloat(completionPercentage);
  }

  return (
    <div className="goal-block-wrapper">
      <div className="arc-color-black goal-completion-percentage">
        <Statistic value={parseFloat(completionPercentage)} />
        %
      </div>
      <div className="arc-p arc-color-black goal-giving-of">
        Reached of
        {'  '}
        <If condition={goalPrefix === 'amount'}>
          <CurrencyRenderer currencyId={currency} />
        </If>
        <Statistic value={goal} />
        {' '}
        <If condition={goalPrefix === 'gift'}>{goal > 1 ? 'gifts ' : 'gift '}</If>
        goal
      </div>
      <Row type="flex" justify="space-between" gutter={32}>
        <Col>
          <div className="arc-p arc-color-B85">
            <Choose>
              <When condition={goalPrefix === 'donor'}>
                <FontAwesomeIcon icon={faUsers} />
                <I18nCustomFormatter id="donors" values={{ donors: countDonors }} />
              </When>
              <Otherwise>
                <FontAwesomeIcon icon={faGift} className="mr4" />
                <I18nCustomFormatter id="gifts" values={{ gifts: countGifts }} />
              </Otherwise>
            </Choose>
          </div>
        </Col>
        <Col>
          <div className="arc-p arc-color-B85">
            <FontAwesomeIcon icon={faSeedling} className="mr8" />
            <CurrencyRenderer currencyId={currency} />
            <Statistic value={parseFloat(amount)} />
            {' '}
            <I18nCustomFormatter id="amount-raised" />
          </div>
        </Col>
      </Row>
      <div className="goal-progressbar">
        <Progress percent={getCompletionPerc()} />
      </div>
    </div>
  );
}

export default CampaignGoalBlock;
