import moment from "moment-timezone";

export const getUTCDate = (value, format = 'YYYY-MM-DDTHH:mm:ss') => {
    return value ? moment.utc(value).format(format) : null
}

export const getFormattedDate = (value, format = 'YYYY-MM-DDTHH:mm:ss') => {
    return value ? moment.parseZone(value).format(format) : null
}

export const getFormattedDateNoParseZone = (value, format = 'YYYY-MM-DDTHH:mm:ss') => {
    return value ? moment(value).format(format) : null;
  };  

export const getFormattedDateUnix = (columnDate, format) => {
    return columnDate != null && columnDate ? moment.unix(columnDate).format(format) : '-';
  };

export const getFormattedDateWithTimeZone = (value, timezone, format = 'YYYY-MM-DDTHH:mm:ss' ) => {
    if (!timezone) {
        return getFormattedDate(value, format);
    }
    return value ? moment.tz(value, timezone).format(format) : null;
}

export const getMomentDate = (value) => {
    return value ? moment.parseZone(value) : null
}

export const disabledStartDate = (startDate, endDate) => {
    const oneDayBefore = moment().subtract(1, 'days').endOf('day');
    if (startDate && endDate) {
        return startDate >= moment(endDate).endOf('day') || startDate < oneDayBefore;
    }
    else if (startDate) {
        return startDate < oneDayBefore;
    }
    return false;
}

export const disabledStartDateRelatedToTimeZone = (startDate, endDate, timezone) => {
    if (!timezone) {
        return false;
    }
    const oneDayBefore = moment.tz(timezone).subtract(1, 'days').endOf('day');
    if (startDate && endDate) {
        return startDate >= moment.tz(endDate, timezone).endOf('day') || startDate < oneDayBefore;
    }
    else if (startDate) {
        return startDate < oneDayBefore;
    }
    return false;
}

export const disabledEndDate = (endDate, startDate) => {
    if (!endDate || !startDate) {
        return false;
    }
    return endDate <= moment(startDate).subtract(1, 'days').endOf('day');
}

export const disabledEndDateRelatedToTimeZone = (endDate, startDate, timezone) => {
    if (!timezone) {
        return false;
    }
    const oneDayBefore = moment.tz(timezone).subtract(1, 'days').endOf('day');
    if (startDate && endDate) {
        return endDate <= moment.tz(startDate, timezone).subtract(1, 'days').endOf('day');
    } else if (endDate) {
        return endDate < oneDayBefore;
    } 
    return false;
}

export const getDisabledDates = (pickerDate, startDate, endDate) => {
    if (pickerDate) {
        const oneDayBefore = moment().subtract(1, 'days').endOf('day');
        if (startDate && endDate) {
            return pickerDate < moment(startDate).endOf('day').subtract(1, 'days') || pickerDate > moment(endDate).endOf('day');
        }
        else if (startDate) {
            return pickerDate < moment(startDate).endOf('day').subtract(1, 'days') || pickerDate < oneDayBefore;
        }
        else if (endDate) {
            return pickerDate > moment(endDate).endOf('day') || pickerDate < oneDayBefore;
        }
        return pickerDate < oneDayBefore;
    }
    return false
}

export const humanizeTimestamp = (timestamp) => {
    const time = moment.utc(timestamp); // parse time as UTC
    const now = moment(); // Not in future humanized time
    return moment.min(time, now).from(now);
}

export const getMomentDateWithSpecificTimeZone = (dateTime, timezone) => {
    if (!timezone) {
        timezone = moment.tz.guess();
    }
    return dateTime && timezone ? moment.tz(dateTime, timezone) : null;
}

export const getMomentDateAppendSpecificTimeZone = (dateTime, timezone) => {
    if (!timezone) {
        timezone = moment.tz.guess();
    }
    return dateTime && timezone ? moment(dateTime).tz(timezone) : null;
}

export const defaultTimeZone = () => moment.tz.guess();

export const getDateTimeDiff = (dateTime) => {
    let diff = moment.parseZone(dateTime).unix() - moment.parseZone().unix();
    return diff > 0 ? diff : 0;
}

export const isNowBeforeDate = (date,param='day')=>{
    return moment().isBefore(moment(date).startOf(param));
}