import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { AutoComplete, Input, Tooltip } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

const AutoCompleteOption = AutoComplete.Option;

const DOUBLE_THE_DONATION_BASE_URL =
  'https://doublethedonation.com/api/360matchpro-partners/v1/search_by_company_prefix';

function DoubleTheDonation(props) {
  const { getFieldDecorator } = props.form;
  const [companies, setCompanies] = useState([]);

  const websiteOptions = companies.map((company) => (
    <AutoCompleteOption key={company.id} value={company.company_name}>
      {company.company_name}
    </AutoCompleteOption>
  ));

  function fetchCompanyInfo(value, callback) {
    axios.get(DOUBLE_THE_DONATION_BASE_URL.concat(`?company_name_prefix=${value}`)).then((response) => {
      callback(response.data);
    });
  }

  function onCompanySelect(value, option) {
    let companyObj = _.filter(companies, {
      id: parseInt(option.key),
    })[0];
    if (companyObj) {
      props.form.setFieldsValue({
        company_matching_id: parseInt(option.key),
      });
    }
  }

  function handleCompanyChange(value) {
    fetchCompanyInfo(value, (data) => setCompanies(data));
  }

  return (
    <div className="double-the-donation-wrapper">
      <Form.Item
        label={
          <span>
            <span className="arc-H150">
              <I18nCustomFormatter id="double-the-donation-text" />
            </span>
            <span className="ml8">
              <Tooltip title={props.dtdHelpText}>
                <FontAwesomeIcon icon={faCircleQuestion} className="arc-color-B85" />
              </Tooltip>
            </span>
          </span>
        }
        labelCol={{ sm: 24 }}
        wrapperCol={{ sm: 24 }}>
        {getFieldDecorator('company_matching_name')(
          <AutoComplete
            allowClear
            size="large"
            dataSource={websiteOptions}
            onChange={handleCompanyChange}
            placeholder="Enter company name"
            className="company-autocomplete"
            onSelect={onCompanySelect}>
            <Input />
          </AutoComplete>,
        )}
      </Form.Item>
      <Form.Item style={{ display: 'none' }}>
        {getFieldDecorator('company_matching_id')(<Input type="hidden" />)}
      </Form.Item>
    </div>
  );
}

export default DoubleTheDonation;
