import React, { useState, useEffect } from 'react';
import { string, object, bool, func } from 'prop-types';
import { Modal } from 'antd';
import { isEmpty } from 'lodash';

import { getSignupModalImages } from './../../actions';
import { SIGNUP_MODAL_STEPS } from './../../constants';
import ImageAndTitleHeader from './../themeXHeaders/ImageAndTitleHeader';
import EmailBlock from './emailBlock';
import RegisterBlock from './registerBlock';
import LoginBlock from './loginBlock';

import './style.scss';

function ThemeXSignInModal({ title, description, initialStep, visible, profileData, handleClose }) {

    const [currentStep, updateStep] = useState(initialStep);
    const [modalImages, setModalImages] = useState([{ default: true }]);
    const [profile, setProfile] = useState(profileData);
    const [headerTitle, setHeaderTitle] = useState(title);
    const [headerText, setHeaderText] = useState(description);

    useEffect(() => {
        if (visible) {
            getSignupModalImages().then(response => {
                setModalImages(response.data.results);
            })
        }
        else {
            setProfile(profileData);
            updateStep(initialStep);
            setHeaderTitle(title);
            setHeaderText(description)
            setModalImages([{ default: true }])
        }
    }, [visible]);

    function processEmailResponse(response) {
        if (!isEmpty(response)) {
            setHeaderTitle(`Welcome back ${response.first_name}!`);
            setProfile(response);
            updateStep(SIGNUP_MODAL_STEPS.LOGIN);
        }
        else {
            setHeaderTitle('Create an account')
            setHeaderText('We couldn’t find an account with that email. You can create an account to continue.')
            updateStep(SIGNUP_MODAL_STEPS.REGISTER);
        }
    }

    function switchToEmailBlock() {
        setHeaderTitle('Please login to continue')
        updateStep(SIGNUP_MODAL_STEPS.EMAIL);
    }

    return (
        <Modal
            visible={visible}
            className="themex-sign-in-modal"
            width="25rem"
            closable={true}
            maskClosable={currentStep === SIGNUP_MODAL_STEPS.EMAIL}
            footer={null}
            onCancel={() => handleClose()}
        >
            <ImageAndTitleHeader
                title={headerTitle}
                text={headerText}
            />
            <Choose>
                <When condition={currentStep === SIGNUP_MODAL_STEPS.EMAIL}>
                    <EmailBlock
                        handleResponse={(response) => processEmailResponse(response)}
                        images={modalImages}
                    />
                </When>
                <When condition={currentStep === SIGNUP_MODAL_STEPS.LOGIN}>
                    <LoginBlock
                        profile={profile}
                        handleSwitchToEmailBlock={() => switchToEmailBlock()}
                    />
                </When>
                <When condition={currentStep === SIGNUP_MODAL_STEPS.REGISTER}>
                    <RegisterBlock />
                </When>
            </Choose>
        </Modal>
    )
}

ThemeXSignInModal.propTypes = {
    initialStep: string,
    profileData: object,
    title: string,
    description: string,
    visible: bool,
    handleClose: func.isRequired
}


ThemeXSignInModal.defaultProps = {
    initialStep: SIGNUP_MODAL_STEPS.EMAIL,
    profileData: {},
    title: 'Please login to continue',
    description: '',
    visible: false
}

export default ThemeXSignInModal;