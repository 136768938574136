export const GIVING_BASE_PATH = '/g/:slug';
export const GIVING_HUB_PATH = '/g/:slug/:campaignSlug';
export const GIVING_PAY_PATH = '/g/:slug/pay';
export const GIVING_GIFT_SUCCESS_PAYMENT_PATH = '/g/:slug/gifts/:giftId/success';
export const GIVING_GIFT_FAILED_PAYMENT_PATH = '/g/:slug/gifts/:giftId/failed';
export const GIVING_GIFT_SHARE_PATH = '/g/:slug/gifts/:giftId/share';

export const ROUTES = [
  {
    path: GIVING_BASE_PATH,
  },
  {
    path: GIVING_PAY_PATH,
  },
];
