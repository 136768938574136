import { func, node, string } from 'prop-types';
import React from 'react';

export function handleEnterKeyPress(event, cb) {
  if (event.key === 'Enter') {
    cb();
  }
}

function DivButton({ children, onClick, className, id, ariaLabel = '' }) {
  return (
    <div
      id={id}
      tabIndex="0"
      role="button"
      onClick={onClick}
      className={className}
      aria-label={ariaLabel}
      onKeyDown={(e) => handleEnterKeyPress(e, onClick)}>
      {children}
    </div>
  );
}

DivButton.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
  className: string,
  id: string,
};

DivButton.defaultProps = {
  className: '',
  id: '',
};

export default DivButton;
