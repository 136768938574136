// Reducer CONSTANTS
export const FETCHED_PAGE = 'FETCHED_PAGE';
export const FETCHED_GIFT = 'FETCHED_GIFT';
export const FETCHED_PAYMENT_LINK = 'FETCHED_PAYMENT_LINK';

// Module names
export const MODULE_PAGE = 'PAGE';
export const MODULE_GIFT = 'GIFT';
export const MODULE_PAYMENT_LINK = 'PAYMENT_LINK';

// Goal Type
export const GOAL_TYPE_DONORS = 'donor';
export const GOAL_TYPE_AMOUNT = 'amount';

// Payment Steps
export const CURRENCY_SELECTOR = 'currency_selector';
export const FUND_SELECTOR = 'fund_selector';
export const AMOUNT_SLABS = 'amount_slabs';
export const PREPAYMENT_FORM = 'pre_payment_form';
export const THANK_YOU_PAGE = 'thank_you_page';
export const SHARE_PAGE = 'share_page';
export const FAILED_PAYMENT = 'failed_payment';

// Page TYPES
export const PAGE_TYPE_CROWDFUNDING = 'crowdfunding';
export const PAGE_TYPE_CHECKOUT = 'checkout';
export const PAGE_TYPE_COMPETITIVE_FUNDRAISING = 'competitive_fundraising';

// Influence block text
export const InfluenceBlockText = {
  title: 'influencer-invite-title',
  description: 'influencer-invite-description',
};
// mark your calendar
export const CalendarBlockText = {
  title: 'add-to-calendar-title',
  description: 'add-to-calendar-description',
};

// Payment modal conditions
export const PAYMENT_MODAL_MUST_BE_MASKCLOSABLE = [FUND_SELECTOR, AMOUNT_SLABS];

// Almasights touchpoints
export const TOUCHPOINTS = {
  VIEWED_GIVING_PAGE: 'giving-viewed-a-giving-page',
};

// Leader board gift visibility
export const GIFT_VISIBILITY_TRIBUTE = 'type_gift_visibility_show_tributes';
export const TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT = 'type_gift_visibility_show_gift_with_amount';
export const TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITHOUT_AMOUNT = 'type_gift_visibility_show_gift_without_amount';

export const DESCRIPTION_SECTION = 'description_section';
export const CHALLENGE_SECTION = 'challenge_section';
export const CAMPAIGN_HUB_SECTION = 'campaign_hub_section';
export const TRIBUTE_SECTION = 'tribute_section';
export const LEADERBOARD_SECTION = 'leaderboard_section';
