import { keys } from 'lodash';
import { DEFAULT_COMMUNICATION_TEMPLATES, TEMPLATE_CATEGORIES } from 'Src/common/constants';
import { addOrUpdateParam } from 'Src/common/utilities/data_util';
import instance from './utilities/axios_util';
import {
  GET_PROFILE_BY_EMAIL_API_PATH,
  GET_PROFILE_API_PATH,
  PROFILE_CREATE_API_PATH,
  SIGNUP_MODAL_IMAGES_PATH,
  TOKEN_API_PATH,
  STREAM_ID_API_PATH,
  DEFAULT_TOKEN_API_PATH,
  COMMUNITY_DETAIL_API_PATH,
  COMMUNITY_MEMBERS_API_PATH,
  COMMUNITY_CATEGORIES,
  COMMUNITY_MEMBER_MANAGE_API_PATH,
  COMMUNITY_ADMIN_MEMBERS_API_PATH,
  EMAIL_API,
  EMAIL_API_DETAILS,
  EMAIL_GROUPS_API_PATH,
  EMAIL_GROUPS_MEMBERS_API_PATH,
  EMAIL_TEMPLATES_API_WITH_SECTION,
  CUSTOM_FORMS_LIST_API_PATH,
  DATASTUDIO_PROFILE_COUNT_API_PATH,
  ALMASIGHTS_COUNT_API,
  ALMASIGHTS_LIST_OF_EVENTS,
  ALMASIGHTS_EVENT_DISTRIBUTION_API,
  GET_PROFILES_API_PATH,
  SUB_EVENT_EMAIL_GROUPS_API_PATH,
  AUTOMATED_EMAIL_TEMPLATES_API_PATH,
  SCHEDULED_AUTOMATED_EMAILS_API_PATH,
  EMAIL_TEMPLATES_API,
  DEFAULT_EMAIL_TEMPLATES_API,
  DEFAULT_EMAIL_TEMPLATE_SECTIONS_API,
  EMAIL_TEMPLATE_SECTIONS_API,
  AUTOMATED_EMAIL_TEMPLATE_BEE_CONFIG_API_PATH,
  EMAIL_GROUPS_MEMBER_COUNT_API,
  MERGE_TAG_API,
} from './endpoints';
import { communityMemberFields } from './fields';

export const getProfileByEmail = (profileEmail) => {
  return instance.get(GET_PROFILE_BY_EMAIL_API_PATH.replace('{}', profileEmail));
};

export const getProfileById = (profileId, requiredFields = '') => {
  return instance.get(GET_PROFILE_API_PATH.replace('{}', profileId).concat(requiredFields));
};

export const getProfileByIds = (profileIds) => {
  return instance.get(GET_PROFILES_API_PATH.replace('{}', profileIds));
};

export const createNewProfile = (profileData) => {
  return instance.post(PROFILE_CREATE_API_PATH, profileData);
};

export const getSignupModalImages = () => {
  return instance.get(SIGNUP_MODAL_IMAGES_PATH);
};

export const getFeedUserToken = (userId) => {
  if (userId) {
    return instance.get(TOKEN_API_PATH.replace('{}', userId));
  }
  return instance.get(DEFAULT_TOKEN_API_PATH);
};

export const getFeedStreamId = (objectId) => {
  return instance.get(STREAM_ID_API_PATH.replace('{}', objectId));
};

export const uploadImage = (method, url, formData) => {
  if (method === 'POST') {
    return instance.post(url, formData);
  }
  return instance.put(url, formData);
};

/** Common Module Actions */

// Community App Actions

export const getCommunityDetail = (communityId) => {
  return instance.get(COMMUNITY_DETAIL_API_PATH.replace('{}', communityId));
};

export const getCommunityCategories = () => {
  return instance.get(COMMUNITY_CATEGORIES);
};

export const getCommunityMembers = ({
  communityId,
  memberType = '',
  skipPagination = false,
  pageNum = 1,
  searchTerm = '',
}) => {
  let path = COMMUNITY_MEMBERS_API_PATH.replace('{}', communityId);
  path = path.concat('?fields=', communityMemberFields.join(','));

  if (memberType) {
    path = path.replace('{member_type}', memberType);
  } else {
    path = path.replace('{member_type}/', '');
  }

  if (skipPagination) {
    path = path.concat('&skip_pagination=true');
  } else {
    path = path.concat('&page=', pageNum);
  }

  if (searchTerm) {
    path = path.concat('&search=', searchTerm);
  }
  return instance.get(path, { hideNotification: true });
};

export const getCommunityAdminMembers = (communityId) => {
  let path = COMMUNITY_ADMIN_MEMBERS_API_PATH.replace('{}', communityId);
  path = path.concat('&fields=', communityMemberFields.join(','));
  return instance.get(path, { hideNotification: true });
};

export const updateCommunityMemberStatus = (communityId, profileId, isDelete = false) => {
  const data = { profile: { id: profileId } };
  const path = COMMUNITY_MEMBER_MANAGE_API_PATH.replace('{}', communityId);
  if (isDelete) {
    return instance.delete(path, { data });
  }
  return instance.post(path, data);
};

/** ***** EMAIL **************** */

export const deleteEmail = (emailId) => {
  const path = EMAIL_API.replace('{}', emailId);
  return instance.delete(path);
};

export const cancelEmail = (emailId) => {
  const path = EMAIL_API.replace('{}', emailId).concat('/cancel');
  return instance.post(path);
};

export const createEmail = (body) => {
  const path = EMAIL_API.replace('{}', '');
  return instance.post(path, body);
};

export const updateEmail = (emailId, body) => {
  const path = EMAIL_API.replace('{}', emailId);
  return instance.put(path, body);
};

export const sendEmail = (emailId, body) => {
  const path = EMAIL_API.replace('{}', emailId).concat('/send');
  return instance.post(path, body);
};

export const testEmail = (emailId, body) => {
  const path = EMAIL_API.replace('{}', emailId).concat('/test');
  return instance.post(path, body);
};

export const getEmailDetails = (emailId) => {
  const path = EMAIL_API_DETAILS.replace('{}', emailId);
  return instance.get(path);
};

export const getAutomatedEmail = (emailType, contentObjectType, contentObjectId) => {
  return instance.post(AUTOMATED_EMAIL_TEMPLATES_API_PATH, {
    email_type: emailType,
    content_type: contentObjectType,
    object_id: contentObjectId,
  });
};

export const updateAutomatedEmail = (emailId, body, emailSaveApiPath, method = 'PATCH') => {
  let path = AUTOMATED_EMAIL_TEMPLATES_API_PATH.concat(`${emailId}`);
  if (emailSaveApiPath) {
    path = emailSaveApiPath.concat(emailId);
  }
  return instance({ method, url: path, data: body });
};

export const resetAutomatedEmail = (emailId) => {
  return instance.post(AUTOMATED_EMAIL_TEMPLATES_API_PATH.concat(`${emailId}/reset`), {});
};

export const getAutomatedEmailBeeConfig = (emailId) => {
  return instance.get(AUTOMATED_EMAIL_TEMPLATE_BEE_CONFIG_API_PATH.replace('{}', `${emailId}`));
};

export const createScheduledAutomatedEmail = (emailType, contentObjectType, contentObjectId, minuteOffset, active) => {
  return instance.post(SCHEDULED_AUTOMATED_EMAILS_API_PATH, {
    type: emailType,
    content_type: contentObjectType,
    object_id: contentObjectId,
    minute_offset: minuteOffset,
    active: active,
  });
};

export const getEmailGroupsBasedContentObject = (typeId, objectId) => {
  const path = EMAIL_GROUPS_API_PATH.replace('{object_id}', objectId).replace('{type_id}', typeId);
  return instance.get(path);
};

export const getSubEventEmailGroupsBasedContentObject = (typeId, objectId) => {
  const path = SUB_EVENT_EMAIL_GROUPS_API_PATH.replace('{object_id}', objectId).replace('{type_id}', typeId);
  return instance.get(path);
};

export const getEmailGroupMembersCount = (groupId) => {
  const path = EMAIL_GROUPS_MEMBERS_API_PATH.replace('{}', groupId).concat('/count');
  return instance.get(path);
};

export const getMultipleEmailGroupMemberCount = (groupIds) =>
  instance.get(EMAIL_GROUPS_MEMBER_COUNT_API.replace('{}', groupIds.join(',')));

export const getEmailGroupMembers = (groupId, search = '') => {
  let path;
  if (search) path = EMAIL_GROUPS_MEMBERS_API_PATH.replace('{}', groupId).concat(`?&q=${search}`);
  else path = EMAIL_GROUPS_MEMBERS_API_PATH.replace('{}', groupId);
  return instance.get(path);
};

export const getEmailTemplatesWithSection = (section) => {
  const path = EMAIL_TEMPLATES_API_WITH_SECTION.replace('{}', section);
  return instance.get(path);
};

export const getEmailTemplates = (page, section, query, fetchHtmlAndJson) => {
  let path = `${EMAIL_TEMPLATES_API}?page=${page}&page_size=20`;
  if (section) {
    path = path.concat(`&section__slug=${section}`);
  }
  if (query) {
    path = path.concat(`&search=${query}`);
  }
  if (fetchHtmlAndJson) {
    path = path.concat('&fields=body_json,body_html,subject,id,internal_name,thumbnail,section__name,section__slug');
  }
  return instance.get(path);
};

export const createEmailTemplate = (data) => instance.post(EMAIL_TEMPLATES_API, data);

export const updateEmailTemplate = (id, data) => instance.patch(`${EMAIL_TEMPLATES_API}/${id}`, data);

export const getEmailTemplateSections = () => {
  const path = `${EMAIL_TEMPLATE_SECTIONS_API}?templates__isnull=false`;
  return instance.get(path);
};

export const getDefaultEmailTemplates = (page, section, query, fetchHtmlAndJson) => {
  let path = `${DEFAULT_EMAIL_TEMPLATES_API}?page=${page}&page_size=20`;
  if (section) {
    path = path.concat(`&section__slug=${section}`);
  }
  if (query) {
    path = path.concat(`&search=${query}`);
  }
  if (fetchHtmlAndJson) {
    path = path.concat('&fields=body_json,body_html,subject,id,internal_name,thumbnail,section__name,section__slug');
  }
  return instance.get(path);
};

export const getDefaultEmailTemplateSections = () => {
  const path = `${DEFAULT_EMAIL_TEMPLATE_SECTIONS_API}?templates__isnull=false`;
  return instance.get(path);
};

export const getTemplateBody = (
  templateId,
  templateCategory = DEFAULT_COMMUNICATION_TEMPLATES,
  fields = ['body_json', 'body_html'],
) => {
  const api = `${TEMPLATE_CATEGORIES[templateCategory].templateApi + templateId}?fields=${fields.join(',')}`;
  return instance.get(api);
};

/** ****** CUSTOM FORMS **************** */

export const getCustomForms = () => {
  return instance.get(CUSTOM_FORMS_LIST_API_PATH);
};

/** ***** Profile Count API */

export const getProfileCountForCriteria = (data) => {
  const path = DATASTUDIO_PROFILE_COUNT_API_PATH;
  return instance.post(path, data);
};

// Almasights actions
// almasights key to url mapping, defining it here so easy to bundle it while reading
const ValueToKeyMapping = {
  touchpointType: 'touchpoint_type',
  parentObjectId: 'touchpoint_group__parent_object_id',
  objectId: 'object_id',
  fieldId: 'field_id',
  uniqueBy: 'distinct_count_field_name',
  customMetadata: 'custom_metadata',
  limit: 'page_size',
  pageNum: 'page',
  groupBy: 'group_by',
  orderBy: 'ordering',
  sortOrder: 'sort_order',
};

/**
 * Adds almasights params using mapping object
 */
function addAlmasightsEventParamsAndGetUrl(url, almasightsEvent) {
  let newUrl = url;
  keys(almasightsEvent).forEach((key) => {
    if (typeof almasightsEvent[key] !== 'object') {
      newUrl = addOrUpdateParam(newUrl, ValueToKeyMapping[key], almasightsEvent[key]);
    } else {
      keys(almasightsEvent[key]).forEach((innerKey) => {
        newUrl = addOrUpdateParam(newUrl, `${ValueToKeyMapping[key]}.${innerKey}`, almasightsEvent[key][innerKey]);
      });
    }
  });
  return newUrl;
}

export const getCount = (almasightsEvent) => {
  let url = ALMASIGHTS_COUNT_API;
  url = addAlmasightsEventParamsAndGetUrl(url, almasightsEvent);
  return instance.get(url);
};

export const getEvents = (almasightsEvent) => {
  let url = ALMASIGHTS_LIST_OF_EVENTS;
  url = addAlmasightsEventParamsAndGetUrl(url, almasightsEvent);
  return instance.get(url);
};

export const getEventDistribution = (almasightsEvent) => {
  let url = ALMASIGHTS_EVENT_DISTRIBUTION_API;
  url = addAlmasightsEventParamsAndGetUrl(url, almasightsEvent);
  return instance.get(url);
};

export const fetchVerificationGroups = (endpoint) => {
  return instance.get(endpoint, { baseURL: '/api/v2/' });
};

export const getMergeTags = (emailGroupId) => instance.get(MERGE_TAG_API.replace('{}', emailGroupId));
