import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import ThemeXModal from 'Src/common/components/themeXModal';
import ThemeXProfileDetailItem from 'Src/common/components/themeXProfileDetailItem';
import { CURRENCIES } from 'Src/common/constants';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

import './style.scss';

function DonorListModal({ title, handleClose, loading, data, isDonationAmountHidden, hasMore, loadMoreGifts }) {
  function getCurrencyEntity(id) {
    return CURRENCIES.filter((cur) => cur.id === id)[0].html_entity;
  }

  return (
    <ThemeXModal visible modalTitle={title} wrapClassName="list-modal" handleCancel={() => handleClose()}>
      <Choose>
        <When condition={loading && !data.length}>
          <LoadingOutlined className="list-loader" spin />
        </When>
        <Otherwise>
          {data.map((d, index) => (
            <div className="list-item" key={index}>
              <ThemeXProfileDetailItem
                src={d.profile && d.profile.present_picture}
                url={d.profile && d.profile.profile_url}
                title={d.donor_name}
                extra={!isDonationAmountHidden && `${getCurrencyEntity(d.currency)}${d.amount}`}
                isExtraHtmlNode
                hideProfileAttributes
                size="large"
              />
            </div>
          ))}
          <If condition={!loading && hasMore}>
            <a
              href="javascript:void(0)"
              onClick={loadMoreGifts}
              className="daenerys-fs-9 daenerys-fw-300 primary-color-text no-text-decoration">
              <I18nCustomFormatter id="view-more-gifts-label" />
            </a>
          </If>
          <If condition={loading}>
            <LoadingOutlined className="list-loader" spin />
          </If>
        </Otherwise>
      </Choose>
    </ThemeXModal>
  );
}

export default DonorListModal;
