import React from "react";
import { getUTCDate } from 'Src/common/utilities/moment_util';
import moment from 'moment-timezone';
import DateBlock from 'Src/common/components/dateBlock';
import GoogleCalenderLink from 'Src/common/components/googleCalenderLink';
 
const  CampaignCalendarBlock = ({ name, startDateTime, endDateTime, timezone, showTime, venue}) => {

  return (
    <div className="calendar-block-wrapper">
        <DateBlock
                                    startDateTime={startDateTime}
                                    endDateTime={endDateTime}
                                    timezone={timezone}
                                    showTime={showTime}
                                    shortTimezone={""}
                                />
      <GoogleCalenderLink
                                    eventTitle={name}
                                    startDateTime={getUTCDate(startDateTime, 'YYYYMMDDTHHmm00')}
                                    endDateTime={getUTCDate(endDateTime, 'YYYYMMDDTHHmm00')}
                                    location={venue && venue.formatted_address}
                                />
    </div>
  );
}

export default CampaignCalendarBlock;
