export function fetching(module) {
  return {
    type: `FETCHING_${module}`
  };
}

export function success(module, response) {
  return {
    type: `FETCHED_${module}`,
    response
  };
}

export function error(module, response, exception) {
  return {
    type: `ERROR_FETCHING_${module}`,
    response,
    exception
  };
}
