import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Avatar, Input, Spin, message } from 'antd';
import { values } from 'lodash';
import axios from 'axios';

import { REGISTRATION_TYPES } from './../../../constants';
import { LOGIN_API, RESET_PASSWORD_API } from './../../../endpoints';
import SocialConnectBlock from './../socialConnectBlock';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

const FormItem = Form.Item;

function LoginBlock({ profile, form, handleSwitchToEmailBlock }) {

    const [processing, setProcessing] = useState(false);
    const [formResponseText, setFormResponse] = useState('');
    const [showOtherOptions, setOtherOptions] = useState(false);
    const [registrationType, setRegistrationType] = useState(profile.user.registration_type);
    const { getFieldDecorator } = form;

    function authenticateUser() {
        form.validateFields((err, values) => {
            if (!err && !processing) {
                setProcessing(true);
                const formData = new FormData();
                formData.append('email', profile.email);
                formData.append('password', values.password);
                formData.append('csrfmiddlewaretoken', window["csrfToken"]);
                axios({
                    method: 'POST',
                    url: LOGIN_API,
                    data: formData,
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded',
                    }
                }).then(response => {
                    if (response.data.status === 'success') {
                        window.location = window.location.pathname;
                    }
                    else {
                        setFormResponse(response.data.message)
                    }
                    setProcessing(false);
                }).catch(err => {
                    setProcessing(false);
                })
            }
        })
    }

    function sendResetPasswordEmail() {
        if (!processing) {
            setProcessing(true);
            const formData = new FormData();
            formData.append('email', profile.email);
            formData.append('csrfmiddlewaretoken', window["csrfToken"]);
            axios({
                method: 'POST',
                url: RESET_PASSWORD_API,
                data: formData,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded',
                }
            }).then(response => {
                if (response.data.status === 'success') {
                    message.success(`We have sent you an email on ${profile.email}. 
                    Please follow the instructions on it to reset your password.`)
                }
                else {
                    setFormResponse(response.data.msg)
                }
                setProcessing(false);
            }).catch(err => {
                setProcessing(false);
            })
        }
    }

    function handleEmailSignInMethod() {
        setOtherOptions(false);
        setRegistrationType(REGISTRATION_TYPES.EMAIL);
    }

    return (
        <div className="signin-login-block">
            <Divider>
                <Avatar src={profile.present_picture} />
            </Divider>

            <div className="login-method-block">
                <Choose>
                    <When condition={registrationType === REGISTRATION_TYPES.EMAIL}>
                        <div className="regular-form">
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input password!'
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        placeholder="Enter your password"
                                        type="password"
                                        onPressEnter={() => authenticateUser()}
                                        addonAfter={
                                            processing ? (
                                                <Spin indicator={
                                                    <LoadingOutlined style={{ fontSize: 14 }} spin />
                                                } />
                                            ) : (   <FontAwesomeIcon icon={faArrowRight}   
                                                            onClick={() => authenticateUser()} />
                                                )
                                        }
                                    />
                                )}
                            </FormItem>
                        </div>
                    </When>
                    <Otherwise>
                        {/* <p className="arc-support arc-color-B55">Your previous login was via Facebook</p> */}
                        <SocialConnectBlock registrationTypes={[registrationType]} />
                    </Otherwise>
                </Choose>
                <If condition={formResponseText}>
                    <p className="arc-support arc-error-alert-text-color response-text">{formResponseText}</p>
                </If>
            </div>

            <div id="more-login-option-block">
                <If condition={!window.isEmailSignupDisabled}>
                    <a onClick={() => sendResetPasswordEmail()} className="arc-p">Reset Password</a>
                </If>
                <div>
                    <If condition={!window.isAuth0SignupEnabled}>
                        <a onClick={() => setOtherOptions(!showOtherOptions)} className="arc-p">Try another login option</a>
                    </If>
                    <If condition={showOtherOptions || window.isAuth0SignupEnabled}>
                        <If condition={registrationType !== REGISTRATION_TYPES.EMAIL && !window.isEmailSignupDisabled}>
                            <Button
                                id="email-signin-btn"
                                className="arc-btn-subtle"
                                type="primary"
                                onClick={() => handleEmailSignInMethod()}
                            >Connect with email</Button>
                        </If>
                        <SocialConnectBlock
                            registrationTypes={values(REGISTRATION_TYPES).filter(i => i !== registrationType)}
                        />
                    </If>
                </div>
                <If condition={handleSwitchToEmailBlock}>
                    <p className="arc-p">
                        <span>Not you?&nbsp;</span>
                        <a onClick={() => handleSwitchToEmailBlock()}>Use another email</a>
                    </p>
                </If>
            </div>
        </div>
    );
}

LoginBlock.propTypes = {
    profile: object.isRequired,
    handleSwitchToEmailBlock: func.isRequired,
}

export default Form.create()(LoginBlock);