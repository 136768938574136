/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { func, shape, string } from 'prop-types';

import './style.scss';

function FormLabelAlongTooltip({ label, tooltipText, onClick, tooltipProps }) {
  return (
    <span>
      <span className="form-label-tooltip-text mr4">{label}</span>
      <Tooltip title={tooltipText} {...tooltipProps}>
        <QuestionCircleOutlined onClick={onClick} />
      </Tooltip>
    </span>
  );
}

FormLabelAlongTooltip.defaultProps = {
  tooltipProps: {},
  onClick: () => {},
};

FormLabelAlongTooltip.propTypes = {
  label: string.isRequired,
  onClick: func,
  tooltipProps: shape(),
  tooltipText: string.isRequired,
};

export default FormLabelAlongTooltip;
