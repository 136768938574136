/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import FundSelector from './fundSelector';
import PrePaymentForm from './prePaymentForm';
import PostPaymentForm from './postPaymentForm';
import AmountSlabs from './amountSlabs';
import FailedPayment from './failedPayment';
import Share from './share';
import {
  FUND_SELECTOR,
  AMOUNT_SLABS,
  PREPAYMENT_FORM,
  THANK_YOU_PAGE,
  SHARE_PAGE,
  FAILED_PAYMENT,
} from '../../constants';

import './style.scss';

function PaymentBody(props) {
  const campaignHubFunds = useSelector((state) => state.campaignDataReducer.campaignHubFunds);
  const [funds, setFunds] = useState([]);
  const isDarkBackground = props.step === SHARE_PAGE && window.pageData?.type === 'checkout';

  const allAllFundsIfRequired = () => {
    const allFunds = [];
    const alreadyExitsFundsId = props.page.ordered_funds.map(({ fund }) => fund.id);
    if (props.page.allow_donation_to_all_funds) {
      // eslint-disable-next-line no-restricted-syntax
      for (const fund of props.page.all_funds) {
        if (!alreadyExitsFundsId.includes(fund.id))
          allFunds.push({ fund, display_order: props.page.ordered_funds.length + 1, hide: true });
      }
    }
    return allFunds;
  };

  useEffect(() => {
    if (!isEmpty(campaignHubFunds)) {
      setFunds([...cloneDeep(campaignHubFunds)]);
    } else {
      setFunds([...orderBy(props.page.ordered_funds, 'display_order', ['asc']), ...allAllFundsIfRequired()]);
    }
  }, [campaignHubFunds]);

  function getPaymentBodyComponent() {
    switch (props.step) {
      case FUND_SELECTOR:
        return <FundSelector funds={funds} proceedToNextStep={props.proceedToNextStep} />;
      case PREPAYMENT_FORM:
        return (
          <PrePaymentForm
            proceedToNextStep={props.proceedToNextStep}
            prePaymentCustomFields={props.prePaymentCustomFields}
          />
        );
      case THANK_YOU_PAGE:
        return (
          <PostPaymentForm
            proceedToNextStep={props.proceedToNextStep}
            postPaymentCustomFields={props.postPaymentCustomFields}
          />
        );
      case SHARE_PAGE:
        return <Share closeModal={props.closeModal} />;
      case AMOUNT_SLABS:
        return <AmountSlabs proceedToNextStep={props.proceedToNextStep} />;
      case FAILED_PAYMENT:
        return <FailedPayment proceedToNextStep={props.proceedToNextStep} />;
      default:
        props.closeModal();
    }
    return <div />;
  }

  return <div className={`payment-body ${isDarkBackground ? 'dark-background' : ''}`}>{getPaymentBodyComponent()}</div>;
}

export default PaymentBody;
