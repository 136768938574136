import React from 'react';
import { Col, Row } from 'antd';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faLockAlt } from '@fortawesome/pro-duotone-svg-icons';
import { isEmpty } from 'lodash';
import DivButton from 'Src/common/components/controller/buttons/buttonController';
import { GiftConsumer } from '../../context/gift';
import './style.scss';

function PaymentHeader({ hideGoBack, goBack }) {
  const [gift] = GiftConsumer();

  const getGiftLabel = () => {
    const { payment_configs: paymentConfigs } = gift.account || {};
    let label = 'make-a-gift-label';
    if (!isEmpty(paymentConfigs) && paymentConfigs.length === 1) {
      const { interval } = paymentConfigs[0];
      switch (interval) {
        case null:
          label = 'one-time-gift-label';
          break;
        case 'month':
          label = 'monthly-gift-label';
          break;
        case 'year':
          label = 'yearly-gift-label';
          break;
        default:
          label = 'make-a-gift-label';
          break;
      }
    }
    return label;
  };

  return (
    <div className="payment-header">
      <Row type="flex" align="middle" justify="space-between">
        <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
          <If condition={!hideGoBack}>
            <DivButton
              ariaLabel="Back"
              className="go-back-wrapper mr12 arc-color-primary arc-cursor-pointer arc-focus-outline"
              onClick={() => goBack()}>
              <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '18px' }} />
            </DivButton>
          </If>
          <div className={`arc-d-inline-block ${hideGoBack ? 'ml16' : ''}`}>
            <h2 className="arc-H200 arc-color-black arc-text-uppercase mb0">
              <I18nCustomFormatter id={getGiftLabel()} />
            </h2>
            <p className="arc-support arc-text-capitalize mt-2">
              <FontAwesomeIcon className="mr8" icon={faLockAlt} />
              <I18nCustomFormatter id="secure-payment-label" />
            </p>
          </div>
        </Col>
        <Col span={5} className="arc-text-align-r">
          <img className="college-logo" src={window.customerLogo} alt={window.collegeName} />
        </Col>
      </Row>
    </div>
  );
}

export default PaymentHeader;
