import React from 'react';
import { Card, Skeleton } from 'antd';

import './style.scss';

const GiftModalLoadingCard = () => {
  const range = Array.from({ length: 6 }, (v, i) => i);
  return (
    <Card bordered={false} className="gift-modal-loader">
      <div className="card-head-loader">
        <Skeleton.Button active size="small" shape="default" />
        <Skeleton.Button active size="small" shape="default" className="row-reverse" />
      </div>
      {range.map((i) => {
        return (
          <div className="skeletal-row" key={i}>
            <Skeleton.Button active size="small" shape="default" className="skeletal-fragment" />
            <Skeleton.Button
              active
              size="small"
              shape="default"
              className="row-reverse skeletal-fragment skeletal-fragment-small"
            />
          </div>
        );
      })}
    </Card>
  );
};

export default GiftModalLoadingCard;
