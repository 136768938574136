/* eslint-disable complexity */
import moment from 'moment';
import React from 'react';
import { range } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { getChoiceStructure } from 'Src/adminFormsX/utils';
import {
  INPUT_TYPES,
  FORM_FIELD_SPLIT_NAME,
  FORM_PURPOSE_CAMPAIGN_ALMABASE,
  FORM_PURPOSE_GROUP_JOINING_FORM,
  FORM_PURPOSE_MENTORSHIP_ONBOARDING,
} from '../constants';
import { getFormattedDate } from './moment_util';

const CUSTOM_FORM_MAPPING = {
  1: INPUT_TYPES.TEXT,
  2: INPUT_TYPES.TEXTAREA,
  5: INPUT_TYPES.CHECKBOX,
  6: INPUT_TYPES.SELECT,
  8: INPUT_TYPES.RADIO,
  9: INPUT_TYPES.FILE,
  10: INPUT_TYPES.DATE,
  14: INPUT_TYPES.URL,
  16: INPUT_TYPES.SELECT_RANGE,
  3: INPUT_TYPES.EMAIL,
  17: INPUT_TYPES.MULTIPLE_FILE,
  18: INPUT_TYPES.GOOGLE_LOCATION,
  21: INPUT_TYPES.RICH_TEXT,
  22: INPUT_TYPES.NAME,
  23: INPUT_TYPES.PHONE_NUMBER,
};

// adding this here because there was no easy way to check file types before.
const CUSTOM_FORM_TYPES = {
  [INPUT_TYPES.TEXT]: 1,
  [INPUT_TYPES.EMAIL]: 3,
  [INPUT_TYPES.TEXTAREA]: 2,
  [INPUT_TYPES.CHECKBOX]: 5,
  [INPUT_TYPES.SELECT]: 6,
  [INPUT_TYPES.RADIO]: 8,
  [INPUT_TYPES.FILE]: 9,
  [INPUT_TYPES.DATE]: 10,
  [INPUT_TYPES.URL]: 14,
  [INPUT_TYPES.SELECT_RANGE]: 16,
  [INPUT_TYPES.MULTIPLE_FILE]: 17,
  [INPUT_TYPES.GOOGLE_LOCATION]: 18,
  [INPUT_TYPES.RICH_TEXT]: 21,
  [INPUT_TYPES.NAME]: 22,
  [INPUT_TYPES.PHONE_NUMBER]: 23,
};

export const FIELD_WITH_SKIP_PAGINATION_FOR_CHOICES = [INPUT_TYPES.CHECKBOX, INPUT_TYPES.RADIO];

const RegExSplitWithCommon = new RegExp(',(?=(?:(?:[^"]*"){2})*[^"]*$)');

const removeDoubleQuotesFromBeginningAndEnd = new RegExp('(^"|"$)', 'g');

export const getFormChoices = (fieldType, choices) => {
  // Utility function to prepare data to show for user form
  if (CUSTOM_FORM_MAPPING[fieldType] === INPUT_TYPES.SELECT_RANGE) {
    const choiceStructure = getChoiceStructure(fieldType, choices);
    return range(choiceStructure.range_start, choiceStructure.range_end + 1).map((choice) => {
      const string = choiceStructure.prefix ? `${choiceStructure.prefix} ${choice}`: `${choice}`;
      return { value: string, label: string };
    });
  }
  return choices.split(RegExSplitWithCommon).map((choice) => {
    const trimString = choice.trim().replace(removeDoubleQuotesFromBeginningAndEnd, '');
    return { value: trimString, label: trimString };
  });
};

const getDefaultValue = (field) =>
  CUSTOM_FORM_MAPPING[field.field_type] === INPUT_TYPES.CHECKBOX
    ? field.default_value
        .split(RegExSplitWithCommon)
        .map((choice) => choice.trim().replace(removeDoubleQuotesFromBeginningAndEnd, ''))
    : field.default_value;

// Returns an object that contains key, value pairs of field id's and field type
// For range field type, we want to render condition values as comma separate values,
// so, for range field type, we are converting it into select field type
const getFieldIdFieldTypeMapping = (fields) =>
  fields.reduce((obj, field) => ({ ...obj, [field.id]: field.field_type===CUSTOM_FORM_TYPES[INPUT_TYPES.SELECT_RANGE] ? CUSTOM_FORM_TYPES[INPUT_TYPES.SELECT] : field.field_type }), {});

export const convertFormLayoutToComponentFields = (formLayout) => {
  const fieldTypeMapping = getFieldIdFieldTypeMapping(formLayout);
  return formLayout.map((field) => ({
    help_text: field.help_text,
    id: field.id,
    key: field.id ? field.id.toString() : field.slug,
    default_value: getDefaultValue(field),
    tooltip_text: '',
    slug: field.slug,
    label: field.label,
    placeholder: field.placeholder_text,
    type: CUSTOM_FORM_MAPPING[field.field_type] || field.field_type,
    visible: field.visible !== false,
    options: field.choices ? getFormChoices(field.field_type, field.choices) : [],
    rules: { required: { value: field.required } },
    condition_field: field.condition_field,
    condition_values:
      field.condition_field &&
      getFormChoices(fieldTypeMapping[field.condition_field], field.condition_values).map((choice) => choice.value),
    choice_source_identifier: field.choice_source_identifier || null,
    order: field.order || 0,
  }));
};

// we can use this function for convert list of Dict {key: values} to FormRecord formatted for init value in customFormItem
export const convertListOfValuesToFormRecord = (values, key = 'field_id') => {
  const formRecord = {};

  values.forEach((valueObj) => {
    if (valueObj[key]) {
      formRecord[valueObj[key]] = valueObj.value || null;
      formRecord.custom_data = valueObj.custom_data;
    }
    if (valueObj.google_place) {
      formRecord[`google_place_${valueObj[key]}`] = valueObj.google_place;
    }
  });

  return formRecord;
};

export const getFormEntryRecord = (formEntries, fieldId, fieldType, fieldKey) => {
  const entry = formEntries.filter((entry) => entry.field_id == fieldId)[0];
  if (entry) {
    let { value } = entry;
    let location = null;
    if (fieldType === INPUT_TYPES.DATE) {
      value = moment(entry.value);
    } else if (fieldType === INPUT_TYPES.CHECKBOX) {
      value = entry.value ? entry.value.split(',').map((choice) => choice.trim()) : [];
    } else if (fieldType === INPUT_TYPES.GOOGLE_LOCATION) {
      location = entry.google_place;
    }
    return { [fieldKey || fieldId]: value, [`google_place_${fieldKey || fieldId}`]: location };
  }
  return {};
};

export const getFormValue = (value) => {
  if (value instanceof moment) {
    return getFormattedDate(value, 'YYYY-MM-DD');
  }
  if (Array.isArray(value)) {
    return value.join(',');
  }
  return value;
};

export const getValueByType = (type, value, display_value) => {
  // function to check the type of form field and return an appropriate value
  // currently used to return an anchor for the file upload url
  // handling empty value case
  if (!display_value) {
    return '-';
  }
  if (type === CUSTOM_FORM_TYPES[INPUT_TYPES.FILE]) {
    return (
      <a href={value} target="_blank">
        <FontAwesomeIcon icon={faPaperclip} /> {display_value}
      </a>
    );
  }
  if (type === CUSTOM_FORM_TYPES[INPUT_TYPES.MULTIPLE_FILE]) {
    const urls = value.split(',');
    const names = display_value.split(',');
    return urls.map((url, index) => (
      <p className="submission-item-value arc-p arc-color-B45">
        <a href={url} download={url}>
          <FontAwesomeIcon icon={faPaperclip} /> {names[index]}
        </a>
      </p>
    ));
  }

  return display_value;
};

export const validateFullName = function (rule, value, callback) {
  value = value && value.trim();
  if (value) {
    const regex =
      /^[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*( [a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]([-']?[a-z0-9\[\]()#&<>\"~;$^%{}?\u00C0-\u017F\'\s\.\,]+)*)+$/;
    if (!regex.test(value.toLowerCase())) {
      callback('Please enter your full name');
    }
  }
  callback();
};

// returning fields based on the purpose of the form

export const getFieldTypesFromPurpose = (choices, purpose) => {
  if (purpose === FORM_PURPOSE_CAMPAIGN_ALMABASE) {
    // remove date and file uploads for
    // form_purpose 1 = form for giving or events, disabling file uploads and date.
    return choices.filter((c) => {
      if (
        c.value !== CUSTOM_FORM_TYPES.file &&
        c.value !== CUSTOM_FORM_TYPES.date &&
        c.value !== CUSTOM_FORM_TYPES.multi_file &&
        c.value !== CUSTOM_FORM_TYPES.email &&
        c.value !== CUSTOM_FORM_TYPES.name &&
        c.enabled
      ) {
        return c;
      }
    });
  }
  if (purpose === FORM_PURPOSE_GROUP_JOINING_FORM) {
    return choices.filter((c) => {
      if (c.enabled) {
        return c;
      }
    });
  }
  if (purpose === FORM_PURPOSE_MENTORSHIP_ONBOARDING) {
    return choices.filter((c) => {
      if (c.value !== CUSTOM_FORM_TYPES.email && c.value !== CUSTOM_FORM_TYPES.name && c.enabled) {
        return c;
      }
    });
  }
  return choices.filter((c) => c.enabled);
};
export const processLocationFieldAndExtractLocationValues = (values) => {
  /*
    Using this funtion to extract the google_place_{id} key value from a form object
    and returning it so that it can be used in the parent funtion to append with the location field
  */
  const locations = {};
  const keys = Object.keys(values);
  const prefix = 'google_place_';
  keys.forEach((item) => {
    if (item.startsWith(prefix)) {
      locations[item.substr(prefix.length)] = {
        google_place: values[item],
      };
      delete values[item];
    }
  });
  return { newValues: values, locations };
};

export const processLocationFieldsArray = (values) => {
  /*
    This function is used to append the google locations values to its fields if the
    input is a array of objects of fields
    example suppose we have a array of fields like this[
      {
        field_id: 5,
        value: value
      },
      google_place_{5}:{
        field_id: 5,
        value: {google_place_id:id,formatted_address:address}
      }
    ]
    converting this to
    [
      {
        field_id: 5,
        value: value,
        google_place:{google_place_id:id,formatted_address:address}
      }
    ]
  */
  const prefix = 'google_place_';
  const newData = [];
  const indexMap = {};
  const locationField = [];
  values.forEach((field) => {
    if (field.field_id.startsWith(prefix)) {
      locationField.push({
        field_id: field.field_id.substr(prefix.length),
        google_place: field.value,
      });
    } else {
      indexMap[field.field_id] = newData.length;
      newData.push(field);
    }
  });
  locationField.forEach((field) => {
    const index = indexMap[field.field_id];
    newData[index] = { ...newData[index], ...field };
  });
  return newData;
};

export const getNameFieldValue = (value, nameType) => {
  if (value && nameType === FORM_FIELD_SPLIT_NAME) {
    return `${value.first_name} ${value.last_name}`;
  }
  return value;
};

export const getNameFieldMetadata = (value, nameType) => {
  if (value && nameType === FORM_FIELD_SPLIT_NAME) {
    return { first_name: value.first_name, last_name: value.last_name };
  }
  return null;
};

export const sanitizeNameFields = (values) => {
  const newValues = { ...values };
  delete newValues.first_name;
  delete newValues.last_name;
  return newValues;
};
