import React, { useState } from 'react';
import { array, func } from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Carousel, Input, Spin } from 'antd';

import { getProfileByEmail } from './../../../actions';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
const FormItem = Form.Item;

function EmailBlock({ images, form, handleResponse }) {
  const [processing, setProcessing] = useState(false);
  const { getFieldDecorator } = form;

  function validateEmail() {
    form.validateFields((err, values) => {
      if (!err) {
        setProcessing(true);
        getProfileByEmail(values.email).then((response) => {
          let profile = response.data.length === 1 ? response.data[0] : {};
          // Which mean profile email is different from user email
          if (profile.email && profile.email !== values.email) {
            profile.email = values.email;
          }
          setProcessing(false);
          handleResponse(profile);
        });
      }
    });
  }

  return (
    <div className="signin-email-block">
      <Carousel>
        {images.map((image, index) => {
          return (
            <div key={index}>
              <div
                className={`slick-slide-cover${image.default ? ' --default-cover' : ''}`}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.96) 100%), url(${image.picture_url})`,
                }}>
                <h2>{image.title}</h2>
              </div>
            </div>
          );
        })}
      </Carousel>
      <div className="body">
        <div className="regular-form">
          <FormItem>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Please enter your email',
                },
                {
                  type: 'email',
                  message: 'Please enter your email',
                  transform: (value) => value && value.trim(),
                },
              ],
              validateTrigger: 'onBlur',
            })(
              <Input
                required
                size="large"
                autoComplete="email"
                placeholder="Enter your email address"
                onPressEnter={() => validateEmail()}
                aria-label="Enter your email address"
                addonAfter={
                  processing ? (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
                  ) : (
                    <Button
                      type="link"
                      className="link-btn arc-focus-outline"
                      onClick={() => validateEmail()}
                      aria-label="Sign up">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )
                }
              />,
            )}
          </FormItem>
        </div>
        <p className="arc-support arc-color-B55">
          {window.signupModalDescription ||
            `If you do not already have an account, 
                    you can create one in the following steps`}
        </p>
      </div>
    </div>
  );
}

EmailBlock.propTypes = {
  images: array.isRequired,
  handleResponse: func.isRequired,
};

export default Form.create()(EmailBlock);
