import React, { useState } from 'react';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/pro-solid-svg-icons';
import { handleEnterClick } from 'Src/common/utilities/dom_utils';
import SocialMediaShareModal from './socialMediaShareModal';
import InfluencerModal from '../influencerModal';

import './style.scss';

function Share({ url, title }) {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [influencerModalOpen, setInfluencerModalOpen] = useState(false);

  function toggleShareModal() {
    setShareModalOpen(!shareModalOpen);
  }

  function toggleInfluencerModel() {
    setShareModalOpen(false);
    setInfluencerModalOpen(!influencerModalOpen);
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="share-page-container arc-cursor-p"
        onClick={toggleShareModal}
        onKeyDown={(e) => handleEnterClick(e, toggleShareModal)}>
        <FontAwesomeIcon icon={faShare} /> Share
      </div>

      <If condition={shareModalOpen}>
        <SocialMediaShareModal
          url={url}
          title={title}
          toggleShareModal={toggleShareModal}
          toggleInfluencerModel={toggleInfluencerModel}
        />
      </If>

      <InfluencerModal visible={influencerModalOpen} onClose={toggleInfluencerModel} />
    </>
  );
}

Share.defaultProps = {
  title: '',
};

Share.propTypes = {
  url: string.isRequired,
  title: string,
};

export default Share;
