/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// please ignore these variables, I will use it in near future
const ENV_PROD = 'prod';
const ENV_STAGE = 'stage';
const ENV_DEV = 'dev';
const ENV_TO_LOG_FOR = [ENV_PROD];

// this will configure and initialize the sentry
export const initSentry = () => {
  Sentry.init({
    dsn: 'https://68401a22d9524e1d9fe70645a6e2e52a@o120785.ingest.sentry.io/5257388',
    release: 'askra-themex-release',
    allowUrls: [
      'static.almabaseapp.com', // code served on CDN
    ],
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: 0.2,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',

      // Patterns added by us
      /^'\$' is undefined$/,
      /^'\$' is not defined$/,
      /^'jQuery' is undefined$/,
      /^jQuery is not defined$/,
      /^jQuery is undefined$/,
      /^Can't find variable: _AutofillCallbackHandler$/,
      /^Non-Error promise rejection captured with keys: href, message, type$/,
      /^Could not load "util".$/,
      /^__jsonp1__ is not defined$/,
      /^Non-Error promise rejection captured with value: Timeout$/,
      /^Non-Error promise rejection captured with value: Object Not Found Matching Id:/,
    ],

    // Sentry suggests to deny these third party URLs
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
  Sentry.configureScope(() => {
    // all the stuff taken from window is expected to come from django template
    Sentry.setUser(window.sentryUser);
    Sentry.setExtra('site_id', window.siteId);
    Sentry.setExtra('domain', window.location.origin);
    Sentry.setTag('user_mode', window.userMode);
  });
};
