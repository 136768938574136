import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Select } from 'antd';
import { map, uniqBy } from 'lodash';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { CURRENCIES } from 'Src/common/constants';
import { SettingsConsumer } from '../../../context/settings';
import './style.scss';

const { Option } = Select;

function CurrencySeletor(props) {
  const [settings] = SettingsConsumer();
  const { currency } = props;

  // eslint-disable-max-len
  function getCurrencyDisplayName(id) {
    const currencyL = CURRENCIES.filter((cur) => cur.id === parseInt(id, 10))[0];
    return (
      // eslint-disable-max-len
      <span
        dangerouslySetInnerHTML={{
          __html: `${currencyL.iso_code} - ${currencyL.html_entity}`,
        }}
      />
    );
  }

  function getCurrencies() {
    return map(uniqBy(settings.page.accounts, 'currency'), 'currency');
  }

  return (
    <div className="currency-selector arc-size-lg">
      <Row type="flex" align="middle" justify="space-between" className="arc-full-height pt0 pb0 pl24 pr24">
        <Col span={12}>
          <p className="arc-H150" id="select-currency-label">
            <I18nCustomFormatter id="select-currency-label" />
          </p>
        </Col>
        <Col span={8}>
          <Select
            aria-labelledby="select-currency-label"
            defaultValue={currency}
            style={{ width: 120 }}
            onChange={(value) => props.setGiftCurrency(value)}
            className="currency-selector-dropdown">
            {getCurrencies().map((cur) => (
              <Option value={cur} key={`cur-${cur}`}>
                {getCurrencyDisplayName(cur)}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
}

CurrencySeletor.propTypes = {
  currency: PropTypes.number.isRequired,
  setGiftCurrency: PropTypes.func.isRequired,
};

export default CurrencySeletor;
