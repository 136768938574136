/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { isEmpty } from 'lodash';
import { IntlProvider } from 'react-intl';
import { getSrcFromSrcSet } from 'Src/common/utilities/dom_utils';
import { getFeaturedImage } from 'Src/alumniGiving/common/utils/helper';
import { getUrlParam } from 'Src/common/utilities/data_util';
import ProgressiveFadedImage from 'Src/common/components/progressiveFadedImage';
import { getMomentDateWithSpecificTimeZone, getMomentDate } from 'Src/common/utilities/moment_util';
import ThemeXContainer from 'Src/common/components/themeXContainer';
import ThemeXContainerV1 from 'Src/common/components/themeXContainer/v1';
import { initSentry } from 'Src/common/utilities/sentry_util';
import AlmasightsProvider from 'Src/common/providers/almasights';
import FroalaEditorView from 'Src/common/components/froalaEditor/froalaEditorView';
import { settingsReducer } from '../reducers/settings';
import { giftReducer } from '../reducers/gift';
import { paymentLinkReducer } from '../reducers/paymentLink';
import { SettingsProvider } from '../context/settings';
import { GiftProvider } from '../context/gift';
import { PaymentLinkProvider } from '../context/paymentLink';
import {
  GIVING_BASE_PATH,
  GIVING_GIFT_SUCCESS_PAYMENT_PATH,
  GIVING_GIFT_FAILED_PAYMENT_PATH,
  GIVING_PAY_PATH,
  GIVING_GIFT_SHARE_PATH,
  GIVING_HUB_PATH,
} from '../routes';
import HomePage from '../homePage';
import LandingPage from '../landingPage';
import PaymentModal from '../paymentModal';
import {
  THANK_YOU_PAGE,
  PAGE_TYPE_CROWDFUNDING,
  PAGE_TYPE_COMPETITIVE_FUNDRAISING,
  FAILED_PAYMENT,
  SHARE_PAGE,
} from '../constants';
import { ADMIN_BASE_URL } from '../endpoints';
import './style.scss';

// This will init sentry and log errors for the
// This will only log errors for production environment
if (window.environment === 'prod') {
  initSentry();
}

const isPageCrowdFunding = (type) => PAGE_TYPE_CROWDFUNDING === type;
const isPageTypeCompetitveFundraising = (type) => PAGE_TYPE_COMPETITIVE_FUNDRAISING === type;
const isCountDownPage = getUrlParam('countdownpage') === 'true' && (window.isSiteAdmin || window.isFundraisingAdmin);
const isLandingPage = getUrlParam('landingpage') === 'true' && (window.isSiteAdmin || window.isFundraisingAdmin);

const commonI18Messages = require('Data/en-US/common.json');
let i18Messages = require('Data/en-US/giving.json');

i18Messages = { ...commonI18Messages, ...i18Messages };

if (!isEmpty(window.i18nMetadata) && !isEmpty(window.i18nMetadata.common)) {
  i18Messages = { ...i18Messages, ...window.i18nMetadata.common };
}

if (!isEmpty(window.i18nMetadata) && !isEmpty(window.i18nMetadata.giving)) {
  i18Messages = { ...i18Messages, ...window.i18nMetadata.giving };
}

const isCampaignStarted = ({ start_datetime, timezone }) => {
  if (isCountDownPage) {
    return false;
  }
  if (isLandingPage) {
    return true;
  }
  if (start_datetime && timezone) {
    return (
      getMomentDateWithSpecificTimeZone(start_datetime, timezone).diff(
        getMomentDate(new Date().getTime()),
        'seconds',
      ) <= 0
    );
  }
  return false;
};

/**
 * Root Component
 */
function Root() {
  const [isCountDownCompleted, setIsCountDownCompleted] = useState(isCampaignStarted(window.pageData));

  useEffect(() => {
    const loader = document.getElementById('loader');
    if (loader) {
      loader.parentNode.removeChild(loader);
    }
  }, []);

  const onCampaignChangetoStarted = () => {
    if (isCountDownPage) {
      return;
    }
    setIsCountDownCompleted(true);
  };

  const renderLandingPage = (type, props) => {
    switch (type) {
      case PAGE_TYPE_CROWDFUNDING:
        return <HomePage {...props} />;
      case PAGE_TYPE_COMPETITIVE_FUNDRAISING:
        return (
          <LandingPage
            {...props}
            displayStartIn={!isCountDownCompleted}
            showCountDown={isPageTypeCompetitveFundraising(window.pageData.type)}
            startDateTime={window.pageData.start_datetime}
            endDateTime={window.pageData.end_datetime}
            countDownCallback={() => onCampaignChangetoStarted}
          />
        );
      default:
        return <PaymentModal {...props} />;
    }
  };

  const renderGiftWrapper = (LANDING, isCountDownCompleted) => (
    <GiftProvider initialState={{ funds: [] }} reducer={giftReducer}>
      <Route
        exact
        path={GIVING_BASE_PATH}
        render={(props) => renderLandingPage(window.pageData.type, { ...props, isCountDownCompleted })}
      />
      <Route
        exact
        path={GIVING_HUB_PATH}
        render={(props) => renderLandingPage(window.pageData.type, { ...props, isCountDownCompleted })}
      />
      <Route
        exact
        path={`${GIVING_PAY_PATH}`}
        render={(props) => <LANDING {...{ ...props, isCountDownCompleted }} />}
      />
      <Route
        path={`${GIVING_GIFT_SUCCESS_PAYMENT_PATH}`}
        exact
        render={(props) => <LANDING {...{ ...props, isCountDownCompleted }} step={THANK_YOU_PAGE} />}
      />
      <Route
        path={`${GIVING_GIFT_FAILED_PAYMENT_PATH}`}
        exact
        render={(props) => <LANDING {...{ ...props, isCountDownCompleted }} step={FAILED_PAYMENT} />}
      />
      <Route
        path={`${GIVING_GIFT_SHARE_PATH}`}
        exact
        render={(props) => <LANDING {...{ ...props, isCountDownCompleted }} step={SHARE_PAGE} />}
      />
    </GiftProvider>
  );

  return (
    <IntlProvider locale="en" messages={i18Messages}>
      <SettingsProvider initialState={{ page: window.pageData, user: window.userData }} reducer={settingsReducer}>
        <If condition={!window.pageData.is_listed}>
          <Alert
            message={<p className="arc-H150 arc-text-align-c">This page is not published yet</p>}
            banner
            closable
            showIcon={false}
          />
        </If>
        <PaymentLinkProvider initialState={{ data: window.paymentLinkData }} reducer={paymentLinkReducer}>
          <AlmasightsProvider
            parentObject={{
              type: 'givingxpage',
              id: window.pageData.id,
              name: window.pageData.name,
            }}>
            <Router>
              <Choose>
                <When condition={isPageTypeCompetitveFundraising(window.pageData.type)}>
                  <ThemeXContainerV1
                    isLoaded
                    logoRedirectUrl="/"
                    heroBannerImage={getFeaturedImage()}
                    heroTitle={window.pageData.name}
                    heroBackText="All Pages"
                    userData={window.userData}
                    isImageResizingEnabled
                    displayStartIn={!isCountDownCompleted}
                    showCountDown={isPageTypeCompetitveFundraising(window.pageData.type)}
                    startDateTime={window.pageData.start_datetime}
                    endDateTime={window.pageData.end_datetime}
                    countDownCallback={() => onCampaignChangetoStarted}>
                    {renderGiftWrapper(LandingPage, isCountDownCompleted)}
                  </ThemeXContainerV1>
                </When>
                <Otherwise>
                  <div className={window.pageData.type}>
                    <If condition={window.pageData.type !== PAGE_TYPE_CROWDFUNDING}>
                      <div className="checkout-page-description">
                        <h1 className="page-title arc-H700">{window.pageData?.name}</h1>
                        <FroalaEditorView model={window.pageData?.description} />
                      </div>
                    </If>

                    <ThemeXContainer
                      isLoaded
                      logoRedirectUrl="/"
                      showHeader={isPageCrowdFunding(window.pageData.type)}
                      heroBannerImage={getFeaturedImage()}
                      heroTitle={window.pageData.name}
                      heroBackText="All Pages"
                      redirectLink={window.backToPagesLink}
                      userData={window.userData}
                      showHeroBanner={isPageCrowdFunding(window.pageData.type)}
                      showLoginButton={!window.pageData?.is_detach_from_engagement_platform}
                      isImageResizingEnabled>
                      {renderGiftWrapper(HomePage, isCountDownCompleted)}
                      <If condition={window.pageData.type !== PAGE_TYPE_CROWDFUNDING}>
                        <ProgressiveFadedImage
                          className="gallery-cover-photo"
                          path={getFeaturedImage()}
                          render={(loading, src, srcSetData) => (
                            <div
                              id="hero-wrapper-overlay"
                              style={{
                                backgroundImage: `url(${getSrcFromSrcSet(srcSetData.srcSet, src)})`,
                                backgroundColor: '#000000a6',
                                position: 'fixed',
                                filter: loading ? 'blur(10px)' : 'none',
                                opacity: loading ? 0.5 : 1,
                                transition: 'filter 700ms ease-in',
                              }}
                            />
                          )}
                        />
                      </If>
                    </ThemeXContainer>
                  </div>
                </Otherwise>
              </Choose>
            </Router>
          </AlmasightsProvider>
        </PaymentLinkProvider>
        <If condition={window.isSiteAdmin || window.isFundraisingAdmin}>
          <Button
            icon={<SettingOutlined />}
            shape="round"
            type="primary"
            className="admin-link-btn"
            href={ADMIN_BASE_URL.replace('{}', window.pageData.id)}>
            Admin
          </Button>
        </If>
      </SettingsProvider>
    </IntlProvider>
  );
}

export default Root;
