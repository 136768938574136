import React from "react"
import { Progress } from "antd";

import './style.scss';

function RelativeProgress({ highestValue, value, strokeColor, strokeWidth, showInfo=false }) {

    function getPercent() {
        try{
            return (100*value)/highestValue
        }
        catch(err){
            return 0;
        }    
    }
    return(
        <Progress className="relative-progress" percent={getPercent()} strokeColor={strokeColor} showInfo={showInfo} strokeWidth={strokeWidth} size="large" />
    )
}

export default RelativeProgress;