import React, { Component } from 'react';
import { string } from 'prop-types';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditor from 'react-froala-wysiwyg/FroalaEditorView';

import './style.scss';

class FroalaEditorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: this.props.model,
    };
  }

  render() {
    return <FroalaEditor model={this.state.model} config={{ key: window.froalaEditorKey }} />;
  }
}

FroalaEditorComponent.propTypes = {
  model: string.isRequired,
};

export default FroalaEditorComponent;
