import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { Rating, Star } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';

import './style.scss';

const customStyles = {
  itemShapes: Star,
  itemStrokeWidth: 1,
  activeFillColor: '#DA7E07',
  activeStrokeColor: '#DA7E07',
  inactiveStrokeColor: '#8C8C8C',
  inactiveFillColor: '#8C8C8C',
};

function UserFeedback({
  question,
  submitting = false,
  onSubmit,
  submittedFeedback = true,
  hasSubmitButton = true,
  onRatingChange = () => {},
  onFeedbackChange = () => {},
}) {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(null);

  useEffect(() => {
    onRatingChange(rating);
  }, [rating]);

  useEffect(() => {
    onFeedbackChange(feedback);
  }, [feedback]);

  function getPlaceholder() {
    if (rating <= 3) {
      return question.followUp.negative;
    }
    return question.followUp.positive;
  }
  return (
    <div className="user-feedback-wrapper">
      <p className="arc-color-black arc-p arc-font-weight-medium">{question.main}</p>
      <Rating
        style={{ maxWidth: '200px', margin: '8px auto' }}
        itemStyles={customStyles}
        spaceBetween="medium"
        onChange={(val) => setRating(val)}
        value={rating}
      />
      <If condition={rating}>
        <div className="feedback-text-wrapper mt16">
          <Input.TextArea
            rows={6}
            className={`text-content ${submittedFeedback ? 'inactive' : ''}`}
            bordered={false}
            maxLength={512}
            resize={false}
            placeholder={getPlaceholder()}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <If condition={hasSubmitButton}>
            <Choose>
              <When condition={!submittedFeedback || submitting}>
                <Button
                  type="primary"
                  className="light-button mt8"
                  block
                  loading={submitting}
                  onClick={() => onSubmit({ rating, comment: feedback })}>
                  Submit
                </Button>
              </When>
              <Otherwise>
                <p className="mt16 arc-color-success arc-H200">Thank you for your feedback</p>
              </Otherwise>
            </Choose>
          </If>
        </div>
      </If>
    </div>
  );
}

export default UserFeedback;
