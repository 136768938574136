import { FETCHED_GIFT } from "./../constants";

export const giftReducer = (state, action) => {
  switch (action.type) {
    case FETCHED_GIFT:
      return { ...action.response };

    default:
      return state;
  }
};
