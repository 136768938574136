import { faShare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { Share, Avatar } from 'arcl';
import React, { useEffect, useState } from 'react';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import FroalaEditorView from 'Src/common/components/froalaEditor/froalaEditorView';
import NavigationFooter from 'Src/common/components/navigationFooter';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { SettingsConsumer } from '../../../context/settings';
import CampaignGoalBlock from '../goal';
import { setCampaignIdAction, setCampaignHubFunds, setSelectedCampaignData } from '../../../redux/reducers/campaignHub';
import store from '../../../redux/store';
import { fetchCampaignDonors } from '../../../actions/settings';
import './style.scss';

let pageNumber = 1;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function CampaignModal({ campaignData, handleClose }) {
  const [settings] = SettingsConsumer();
  const [donors, setDonors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasNext, setHasNext] = useState(null);
  const [hasPrev, setHasPrev] = useState(null);

  const getDonors = async () => {
    try {
      setLoading(true);
      const response = await fetchCampaignDonors(campaignData?.id, pageNumber);
      if (response && response.data) {
        setHasNext(response.data.next);
        setHasPrev(response.data.previous);
        setDonors(response.data.results);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setDonors([]);
    }
  };

  function onNext() {
    setLoading(true);
    if (hasNext) {
      pageNumber++;
      getDonors();
    }
  }

  function onPrev() {
    setLoading(true);
    if (hasPrev) {
      pageNumber--;
      getDonors();
    }
  }

  useEffect(() => {
    getDonors();
    pageNumber = 1;
  }, [campaignData]);

  const handleMakeGift = () => {
    const allFunds = [];
    campaignData?.funds?.forEach((fund) => {
      allFunds.push({ fund, display_order: 1 });
    });
    store.dispatch(setCampaignHubFunds(allFunds));
    store.dispatch(setCampaignIdAction(campaignData?.id));
    store.dispatch(setSelectedCampaignData(campaignData));
  };

  const showDonateBlock = ({ page }) => {
    if (page.accept_gifts && page.accounts && page.accounts.length) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Modal
        visible
        width={779}
        title={settings?.page?.name}
        footer={null}
        onCancel={handleClose}
        wrapClassName="hub-campaign-modal-wrapper">
        <div id="g-recaptcha" className="g-recaptcha" data-size="invisible" />
        <If condition={campaignData?.feature_image}>
          <div className="hub-campaign-modal-image">
            <img alt="campaign" src={campaignData?.feature_image} />
          </div>
        </If>
        <div className="hub-campaign-content-wrapper">
          <Choose>
            <When condition={campaignData?.goal_prefix}>
              <div className="hub-campaign-modal-heading arc-color-B85">{campaignData?.name}</div>
              <div className="hub-campaign-modal-action">
                <If condition={campaignData?.goal_prefix}>
                  <CampaignGoalBlock
                    completionPercentage={campaignData.completion_percentage}
                    goal={campaignData?.goal}
                    goalPrefix={campaignData?.goal_prefix}
                    currency={settings?.page?.currency}
                    countDonors={campaignData?.count_donors}
                    countGifts={campaignData?.count_gifts}
                    amount={campaignData?.amount}
                  />
                </If>
                <If condition={showDonateBlock(settings)}>
                  <Button className="no-effect-btn" type="primary" onClick={handleMakeGift}>
                    <I18nCustomFormatter id="campaign-hub-make-gift" />
                  </Button>
                </If>
              </div>
            </When>
            <Otherwise>
              <div className="hub-campaign-without-goal">
                <div className="hub-campaign-modal-heading arc-color-B85">{campaignData?.name}</div>
                <If condition={showDonateBlock(settings)}>
                  <Button className="no-effect-btn" type="primary" onClick={handleMakeGift}>
                    <I18nCustomFormatter id="campaign-hub-make-gift" />
                  </Button>
                </If>
              </div>
            </Otherwise>
          </Choose>

          <div className="hub-campaign-modal-share-block">
            <div>
              <FroalaEditorView model={campaignData?.description} />
            </div>
            <Share
              className="share-block"
              title="Share"
              url={`${window.location.origin}/g/${settings?.page?.slug}/${campaignData?.slug}`}
              disableNativeShare>
              <div className="arc-color-primary" style={{ width: 'fit-content' }}>
                <FontAwesomeIcon icon={faShare} className="mr8" />
                <I18nCustomFormatter id="campaign-hub-share-campaign" />
              </div>
            </Share>
          </div>
          <If condition={campaignData?.show_donor && !isEmpty(donors)}>
            <div className="hub-campaign-modal-donor-block">
              <div className="arc-H150 arc-color-black mb20">
                <I18nCustomFormatter id="campaign-hub-donors" />
              </div>
              <Choose>
                <When condition={loading}>
                  <Spin indicator={antIcon} className="loading-donors-icon" />
                </When>
                <Otherwise>
                  <Row gutter={[12, 12]}>
                    {donors.map((donor) => (
                      <Col className="cs-donor" span={12}>
                        <Avatar
                          src={donor.profile ? donor.profile.present_picture : ''}
                          icon={<UserOutlined />}
                          size="large"
                        />
                        {donor.donor_name || 'Anonymous'}
                      </Col>
                    ))}
                  </Row>
                </Otherwise>
              </Choose>
              <NavigationFooter onNext={onNext} onPrev={onPrev} prevDisabled={!hasPrev} nextDisabled={!hasNext} />
            </div>
          </If>
        </div>
      </Modal>
    </div>
  );
}

export default CampaignModal;
