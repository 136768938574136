import React, { Fragment, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { REACTIONS_LEADERBOARD_API, PAGE_UGC_REACTION_API } from 'Src/alumniGiving/endpoints';
import ThemeXModal from 'Src/common/components/themeXModal';
import axiosInstance from 'Src/common/utilities/axios_util';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import HonorWallItem from 'Src/alumniGiving/landingPage/honorWall/item';
import AnimationBlock from 'Src/alumniGiving/landingPage/honorWall/animationBlock';
import { getQueryParamValueFromUrl, pollLeaderboardData } from 'Src/alumniGiving/common/utils/helper';
import { findIndex, sumBy, isEmpty, find } from 'lodash';
import { faHeart, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { faGrinBeam } from '@fortawesome/pro-duotone-svg-icons';
import TributeModalLoadingCard from '../leaderboardLoaders/tributeModalLoader';

import './tributeModal.scss';

let pageNum = 1;
let url = '';
let fetchingMoreData = false;
const initialDelay = 2000;

const reactionIcons = {
  'fad fa-grin-beam': faGrinBeam,
  'fas fa-heart': faHeart,
  'fas fa-thumbs-up': faThumbsUp,
};

function TributeModal({ leaderboard, item, closeModal }) {
  const [messages, setMessages] = useState([]);
  const [settings, dispatch] = SettingsConsumer();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedHonorWall, setSelectedHonorWall] = useState({});
  const [next, setNext] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  function getReactions() {
    return axiosInstance({
      method: 'get',
      url,
    });
  }

  const getReactionsData = async (tributeUrl) => {
    const currentPage = Number(getQueryParamValueFromUrl(tributeUrl, 'page')) || 1;
    if (currentPage !== pageNum) {
      setIsLoading(false);
      return false;
    }
    try {
      const response = await getReactions(tributeUrl);
      if (currentPage === pageNum) {
        const result = response.data?.results || [];
        setMessages(messages.concat(result));
        setNext(response.data?.next);
        if (response.data) setIsLoading(false);
      }
      return response;
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = `dimension=${encodeURIComponent(item.label)}&&dimension_type=${
      item.groupable ? 'group' : 'item'
    }`;
    url = REACTIONS_LEADERBOARD_API.replace('{}', settings.page.id)
      .replace('<lb_id>', leaderboard.id)
      .concat(`?${queryParams}`);
    setIsLoading(true);
    pageNum = 1;
    const initialUrl = url;
    pollLeaderboardData(async () => getReactionsData(initialUrl), initialDelay);
    return () => {
      pageNum = 0; // Dismounting the poll function when component dismounts
    };
  }, []);

  function updateReactions(selectedWall, reaction) {
    axiosInstance({
      method: 'post',
      url: PAGE_UGC_REACTION_API.replace('{}', settings.page.id),
      data: { ugc_id: selectedWall, kind: reaction },
    });
  }

  function removeURLParameter(url, parameter) {
    // prefer to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
      const prefix = `${encodeURIComponent(parameter)}=`;
      const pars = urlparts[1].split(/[&;]/g);

      // reverse iteration as may be destructive
      for (let i = pars.length; i-- > 0; ) {
        // idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
    }
    return url;
  }

  function selectEmoji(selectedWall, reaction) {
    const index = findIndex(messages, (val) => val.id === selectedWall);
    if (index > -1) {
      const selectedMessage = messages[index];
      const innerIndex = findIndex(selectedMessage.reactions, (val) => val.kind === reaction);
      const totalCount = sumBy(selectedMessage.reactions, (val) => (val.is_self ? 1 : 0));
      if (totalCount === 0) {
        if (innerIndex === -1) {
          selectedMessage.reactions.push({
            kind: reaction,
            count: 1,
            is_self: true,
          });
        } else {
          selectedMessage.reactions[innerIndex].count += 1;
          selectedMessage.reactions[innerIndex].is_self = true;
        }
        messages[index] = selectedMessage;
        messages[index].total_reactions += 1;
        setMessages(messages.slice());
        updateReactions(selectedWall, reaction);
      }
      const r = find(selectedMessage.reactions, (val) => val.kind === reaction);
      if (!isEmpty(r) && r.is_self) {
        attachAnimationNode(selectedWall, reaction);
      }
    }
  }

  function attachAnimationNode(id, reaction) {
    let data = [];
    if (selectedHonorWall[id]) {
      data = selectedHonorWall[id];
    }
    data.push(<AnimationBlock reaction={reaction} />);
    selectedHonorWall[id] = data.slice();
    setSelectedHonorWall({ ...selectedHonorWall });
  }

  function generateUrlWithPageNum(pageNum) {
    url = removeURLParameter(url, 'page');
    url = url.concat(`&&page=${pageNum}`);
    return url;
  }

  const getPaginatedTributeData = async (tributeUrl, initialData) => {
    const currentPage = Number(getQueryParamValueFromUrl(tributeUrl, 'page')) || 1;
    if (currentPage !== pageNum) {
      setIsLoadingMore(false);
      fetchingMoreData = false;
      return false;
    }
    try {
      const response = await getReactions(tributeUrl);
      if (currentPage === pageNum) {
        const result = response.data?.results || [];
        setMessages([...initialData, ...result]);
        setNext(response.data?.next);
        if (response.data) setIsLoadingMore(false);
        fetchingMoreData = false;
      }
      return response;
    } catch (error) {
      setIsLoadingMore(false);
      fetchingMoreData = false;
    }
  };

  function tributesScrolled(e) {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (next && !fetchingMoreData) {
        fetchingMoreData = true;
        pageNum += 1;
        url = generateUrlWithPageNum(pageNum);
        setIsLoadingMore(true);
        const nextPageUrl = url;
        pollLeaderboardData(async () => getPaginatedTributeData(nextPageUrl, messages), initialDelay);
      }
    }
  }

  return (
    <ThemeXModal
      modalTitle={`Tributes from ${item.label}`}
      visible={true}
      wrapClassName="tribute-modal"
      handleCancel={closeModal}>
      <div className="tribute-wrapper" onScroll={tributesScrolled}>
        {isLoading && <TributeModalLoadingCard />}
        {!isLoading && isEmpty(messages) && <div className="empty-container">No tributes found</div>}
        {!isLoading && !isEmpty(messages) && (
          <Fragment>
            {messages.map((d) => (
              <HonorWallItem
                message={d}
                selectEmoji={selectEmoji}
                selectedHonorWall={selectedHonorWall}
                reactionIcons={reactionIcons}
              />
            ))}
            {isLoadingMore && (
              <div className="load-more">
                <Spin />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </ThemeXModal>
  );
}

export default TributeModal;
