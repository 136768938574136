import React from 'react';
import { notification, Button } from 'antd';

const Notification = (props) => {
  let errorMessage;
  let errorDesc;
  let requestId = '';

  function copyText() {
    let copyInputElem = document.createElement('input');
    copyInputElem.setAttribute('type', 'text');
    copyInputElem.setAttribute('id', 'copy-input-element');
    copyInputElem.setAttribute('value', `requestId: ${requestId}, msg: ${props['errorText']}`);
    document.body.appendChild(copyInputElem);
    let copyTextElem = document.getElementById('copy-input-element');
    copyTextElem.select();
    document.execCommand('copy');
    copyTextElem.remove();
  }

  let copyBtn = props['responseRequestId'] ? (
    <Button type="primary" size="small" onClick={() => copyText()}>
      Copy error code
    </Button>
  ) : (
    ''
  );

  if (props['overrideDefault']) {
    errorMessage = props['errorTitle'];
    errorDesc = props['errorText'];
  } else {
    requestId = props['responseRequestId'] || '';
    if (props['errorCode'] === 400 || props['errorCode'] === 403) {
      errorMessage = "Sorry something's not right";
      let textNode = '';
      if (props['errorText']) {
        textNode = <p dangerouslySetInnerHTML={{ __html: props['errorText'] }} />;
      }
      errorDesc = (
        <div>
          {textNode}
          {/* <span style={{ marginTop: '8px', display: 'inline-block' }}>
            If you'd like to report this issue, please email us on help@almabase.com and include this unique error code{' '}
            <b>{props['responseRequestId']}</b>
          </span> */}
        </div>
      );
    } else if (props['errorCode'] === 500) {
      errorMessage = 'Sorry, something’s not right. Please try again in a bit.';
      errorDesc = (
        <p style={{ marginTop: '8px' }}>
          If you'd like to report this, please email us on help@almabase.com. Please include this unique error code{' '}
          {props['responseRequestId']} to help us track down the issue faster.
        </p>
      );
    } else if (props['errorCode'] === 404) {
      errorMessage = 'Not found';
      errorDesc = (
        <p style={{ marginTop: '8px' }}>
          Sorry! We couldn't find what you were looking for. If you think there is something fishy, please email us on
          help@almabase.com.
        </p>
      );
      copyBtn = '';
    } else {
      errorMessage = "Sorry, something's not right.";
      errorDesc = "There's nothing to show";
    }
  }
  return notification['error']({
    message: errorMessage ? errorMessage : '',
    description: errorDesc,
    duration: 5,
    btn: copyBtn,
  });
};

export default Notification;
