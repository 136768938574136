/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { InfluenceBlockText } from 'Src/alumniGiving/constants';
import InfluencerModal from 'Src/alumniGiving/campaign/blocks/influencerModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import './style.scss';

function InfluencerCard() {
  const [isInfluencerModelOpen, toggleInfluencerModel] = useState(false);

  function onClick() {
    toggleInfluencerModel(true);
  }

  return (
    <If condition={!window.blockInfluencerEmails}>
      <div id="influencer-wrapper">
        <div className="influencer-heading">
          <FontAwesomeIcon icon={faUsers} className="influencer-icon" />
          <p className="arc-color-black">
            <I18nCustomFormatter id={InfluenceBlockText.title} />
          </p>
        </div>
        <div className="influencer-content">
          <p className="arc-p mb16">
            <I18nCustomFormatter id={InfluenceBlockText.description} />
          </p>
          <a
            tabIndex={0}
            role="button"
            className="arc-color-primary unique-link"
            onKeyDown={(e) => handleEnterKeyPress(e, () => onClick())}
            onClick={() => onClick()}>
            <span>
              <FontAwesomeIcon icon={faShareAlt} className="mr8" />
            </span>
            <I18nCustomFormatter id="influencer-invite-label" />
          </a>
        </div>
        <InfluencerModal visible={isInfluencerModelOpen} onClose={() => toggleInfluencerModel(false)} />
      </div>
    </If>
  );
}

export default InfluencerCard;
