import {FIELD_VALUES_SHOULD_HAVE_RANGE, FIELD_VALUES_SHOULD_HAVE_OPTIONAL, FIELD_TYPES_HAVE_CONDITIONS} from './constants';

//Converts the choices string to a structure that can be used for frontend display
export const getChoiceStructure = (field_type, choices) => {
    // Expects choices to be of <prefix>: <start> ~ <end>
    if(FIELD_VALUES_SHOULD_HAVE_RANGE.includes(field_type) && choices){
        const choiceRegex = /[:~]/
        let choiceList = choices.split(choiceRegex);
        return choices ? {
            prefix: choiceList[0],
            range_start: parseInt(choiceList[1]),
            range_end: parseInt(choiceList[2])
        }: {};
    }
    //Other fields conversions can be added here
    //Return string for other fields such as options
    return {choices: choices};
}

//Convert the structure to a choice string that is pushed to backend via api
export const setChoiceString = (field_type, structure) => {
    if(FIELD_VALUES_SHOULD_HAVE_RANGE.includes(field_type)){
        structure.choices = `${structure.prefix||""}:${structure.range_start}~${structure.range_end}`;
    }
}

export const setConditionValues = (field_type, structure) => {
    //Create string of condition values from a list of choices
    //If any value has comma in it wrap it with double quotes
    let backEndCompatibleConditionalString = '';
    structure["condition_values"].forEach((conditionalValue, index)=>{
        backEndCompatibleConditionalString += conditionalValue.includes(',') ? `"${conditionalValue}"` : conditionalValue;
        if(index != structure["condition_values"].length -1) {
            backEndCompatibleConditionalString+= ','
        }
    })
    structure["condition_values"] = backEndCompatibleConditionalString;
}
