import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getFallbackImage } from 'Src/common/utilities/dom_utils';

function ImageWithFallbackSrc(props) {

    const [imgSrc, setImgSrc] = useState(props.src)
    const [fallbackImage, setFallbackImage] = useState(props.fallbackSrc, getFallbackImage())

    function onError() {
        // if image is already a fallback url and still fails
        if(imgSrc === fallbackImage) {
            // set image source to default fallbackurl
            if(imgSrc !== getFallbackImage()) {
                setImgSrc(fallbackImage)
            }
        }else {
            setImgSrc(fallbackImage)
        }

    }

    return (
        <img
            {...props}
            src={imgSrc}
            onError={onError}
        />
    )
}

ImageWithFallbackSrc.propTypes = {
    src: PropTypes.string,
    fallbackSrc: PropTypes.string,
};

export default ImageWithFallbackSrc;
