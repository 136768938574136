/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/pro-light-svg-icons';
import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { SettingsConsumer } from '../../../context/settings';
import './style.scss';

function CampaignDonateBlock(props) {
  const [settings] = SettingsConsumer();

  function isGoogleApplePayEnabled() {
    let isGoogleApplePayEnabledL = false;
    if (props.disableGoogleApplePayEnabled) {
      return false;
    }
    for (let i = 0; i < settings.page.accounts.length; i++) {
      if (settings.page.accounts[i].account.type === 'stripe') {
        isGoogleApplePayEnabledL = true;
        break;
      }
    }
    return isGoogleApplePayEnabledL;
  }

  return (
    <div className="donate-btn-wrapper" ref={props.onRef}>
      <If condition={settings.page.accept_gifts}>
        <div className="donate-btn-row">
          <Button
            tabIndex={0}
            type="primary"
            className="donate-btn"
            size="large"
            onClick={() => props.openPaymentModal()}>
            <p className="arc-H200 arc-color-white">{props.callToActionText || 'Make a gift'}</p>
          </Button>
        </div>
      </If>
      <If condition={settings.page.hashtag}>
        <div className="donate-btn-row">
          <p className="hashtag mt12 arc-H150 arc-color-B55 arc-text-align-c">
            <span className="hash-icon">
              <FontAwesomeIcon icon={faHashtag} className="mr4" />
            </span>
            {settings.page.hashtag}
          </p>
        </div>
      </If>
      <If condition={isGoogleApplePayEnabled()}>
        <div className="donate-btn-row google-apple-pay-row">
          <p className="tokenization-methods">
            <span className="tokenization-method arc-p arc-color-B65">Card</span>
            <span className="tokenization-method arc-p arc-color-B65">
              <FontAwesomeIcon icon={faApple} className="mr4" />
              Pay
            </span>
            <span className="tokenization-method arc-p arc-color-B65">
              <FontAwesomeIcon icon={faGoogle} className="mr4" />
              Pay
            </span>
          </p>
        </div>
      </If>
    </div>
  );
}

export default CampaignDonateBlock;
