/* eslint-disable */

import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { GiftConsumer } from 'Src/alumniGiving/context/gift';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import ThemeXShareModalGiving from 'Src/common/components/themeXShareModalGiving';
import ThemeXShareModal from 'Src/common/components/themeXShareModal';
import { getFeaturedImage } from 'Src/alumniGiving/common/utils/helper';
import { MODULE_GIFT, PAGE_TYPE_COMPETITIVE_FUNDRAISING } from 'Src/alumniGiving/constants';
import { UGC_CONTENT_API_PATH } from 'Src/alumniGiving/endpoints';
import axiosInstance from 'Src/common/utilities/axios_util';
import { success } from 'Src/alumniGiving/actionCreators';

function Share(props) {
  const [giftObj, giftDispatch] = GiftConsumer();
  const [settings, dispatch] = SettingsConsumer();

  let gift = window.giftData;

  if (isEmpty(gift)) {
    gift = giftObj;
  }

  function closeModal() {
    if (settings.page.gift_completion_redirect_url) {
      window.open(settings.page.gift_completion_redirect_url);
    }
    if (gift && gift.influencer && gift.influencer.page_share_id) {
      props.closeModal(gift.influencer.page_share_id);
    } else {
      props.closeModal();
    }
  }

  function update(ugcContent) {
    axiosInstance({
      method: 'patch',
      url: `${UGC_CONTENT_API_PATH.replace('{}', settings.page.id).concat(gift.ugc.id).concat('/')}`,
      data: ugcContent,
    }).then((response) => {
      gift.ugc = response.data;
      giftDispatch(success(MODULE_GIFT, gift));
    });
  }

  function handleFileChange(data) {
    if (data[0].status === 'done') {
      gift.ugc = data[0].data;
      giftDispatch(success(MODULE_GIFT, gift));
    }
  }

  return (
    <Fragment>
      <Choose>
        <When
          condition={settings.page.type === PAGE_TYPE_COMPETITIVE_FUNDRAISING && settings.page.is_gift_tribute_enabled}>
          <ThemeXShareModalGiving
            visible
            url={gift.influencer && gift.influencer.page_share_url}
            twitterShareText={`Donate to ${settings.page.name}`}
            title={<I18nCustomFormatter id="share-modal-title" />}
            handleClose={() => closeModal()}
            dashboardUrl={(gift.influencer && gift.influencer.dashboard_url) || ''}
            description={<I18nCustomFormatter id="share-modal-description" values={{ pageName: settings.page.name }} />}
            handleFileChange={handleFileChange}
            ugc={gift.ugc}
            donorName={gift.donor_name}
            update={update}
            dashboardAltMsg={<I18nCustomFormatter id="share-modal-alt-message" values={{ email: gift.donor_email }} />}
            actionUrl={`/api/v1${UGC_CONTENT_API_PATH.replace('{}', settings.page.id).concat(gift.ugc.id).concat('/')}`}
            method="PATCH"
            defaultImage={getFeaturedImage()}
            secretHash={gift.influencer.dashboard_hash}
          />
        </When>
        <Otherwise>
          <ThemeXShareModal
            visible
            url={settings.page.social_share_url || `${window.location.origin}/g/${settings.page.slug}`}
            twitterShareText={`Donate to ${settings.page.name}`}
            title={<I18nCustomFormatter id="share-title" />}
            handleClose={() => closeModal()}
            description={<I18nCustomFormatter id="share-subtitle" />}
            goBack={<I18nCustomFormatter id="go-back-text" />}
          />
        </Otherwise>
      </Choose>
    </Fragment>
  );
}

export default injectIntl(withRouter(Share));
