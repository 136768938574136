import { FETCHED_PAGE } from "./../constants";

export const settingsReducer = (state, action) => {
  switch (action.type) {
    case FETCHED_PAGE:
      return { page: action.response.page, user: action.response.user };

    default:
      return state;
  }
};
