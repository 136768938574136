import React, { useEffect, useState } from "react"
import { Modal, Spin } from "antd";

import './style.scss'

// need to make it generic
function WaitModal({ url, closeModal }) {

    const [isLinkVisible, setIsLinkVisible] = useState(false)

    useEffect(() => {
        // wait for 10 seonds and redirect
        setTimeout(()=>{
            setIsLinkVisible(true)
        }, 10000)
    }, [])

    return(
        <Modal 
            title=""
            visible={true}
            footer={null}
            wrapClassName="wait-modal"
            closable={true}
            onCancel={closeModal}
        >
            <p className="arc-H300 arc-color-p">Loading...Please Wait</p>
            <Choose>
                <When condition={!isLinkVisible}>
                    <Spin className="mt16 mb16" />
                </When>
                <Otherwise condition={isLinkVisible}>
                    <a href={url} target="_blank" className="mt16 mb16 arc-d-inline-block arc-H150">Your unique tribute is ready. Click here to share</a>
                </Otherwise>
            </Choose>
            <p className="arc-p">Great job!</p>
            <p className="arc-p">We’re just putting together the pieces and creating  your unique tribute image.</p>
            
        </Modal>    
    )
}

export default WaitModal;