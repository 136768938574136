import React from 'react';
import { array, bool } from 'prop-types';
import { Button } from 'antd';
import { includes } from 'lodash';

import { REGISTRATION_TYPES } from './../../../constants';
import { LOGIN_API } from './../../../endpoints';

import './style.scss';

function SocialConnectBlock({ registrationTypes, showAll }) {

    function redirectToSocialLogin(selectedType) {
        const nextUrl = window.location.pathname;
        if (window.isAuth0SignupEnabled) {
            window.location = `${LOGIN_API}${REGISTRATION_TYPES.AUTH0}?next=${nextUrl}`;
            return;
        }

        window.location = `${LOGIN_API}${selectedType}?next=${nextUrl}`;
    }

    return (
        <div className="social-connect-block">
            <If condition={
                !window.isLinkedinSignupDisabled
                && (showAll || includes(registrationTypes, REGISTRATION_TYPES.LINKEDIN))
            }>
                <Button
                    id="linkedin-btn"
                    type="primary"
                    onClick={() => redirectToSocialLogin(REGISTRATION_TYPES.LINKEDIN)}
                >
                    Connect with Linkedin
                    </Button>
            </If>
            <If condition={
                !window.isFacebookSignupDisabled
                && (showAll || includes(registrationTypes, REGISTRATION_TYPES.FACEBOOK))
            }>
                <Button
                    id="facebook-btn"
                    type="primary"
                    onClick={() => redirectToSocialLogin(REGISTRATION_TYPES.FACEBOOK)}
                >
                    Connect with Facebook
                </Button>
            </If>
            <If condition={
                window.isGoogleSignupEnabled
                && (showAll || includes(registrationTypes, REGISTRATION_TYPES.GOOGLE))
            }>
                <Button
                    id="google-btn"
                    type="primary"
                    onClick={() => redirectToSocialLogin('google-oauth2')}
                >
                    Connect with Google
                </Button>
            </If>
            <If condition={
                window.isAuth0SignupEnabled
                && (showAll || includes(registrationTypes, REGISTRATION_TYPES.AUTH0))
            }>
                <Button
                    id="auth0-btn"
                    type="primary"
                    onClick={() => redirectToSocialLogin(REGISTRATION_TYPES.AUTH0)}
                >
                    Login / Sign-up
                </Button>
            </If>
        </div>
    )
}

SocialConnectBlock.propTypes = {
    registrationTypes: array,
    showAll: bool
}

SocialConnectBlock.defaultProps = {
    registrationTypes: [],
    showAll: false
}

export default SocialConnectBlock;