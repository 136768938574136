export const communityMemberFields = [
    'id',
    'status',
    'is_admin',
    'title',
    'profile__id',
    'profile__full_name',
    'profile__first_name',
    'profile__last_name',
    'profile__role',
    'profile__education__class_year',
    'profile__employment__employer',
    'profile__employment__designation',
    'profile__employment__gist',
    'profile__active',
    'profile__listed',
    'profile__is_registered',
    'profile__location',
    'profile__present_picture',
    'profile__profile_url',
    'profile__formatted_name'
]