import React, { Fragment } from "react";
import { Col, Row } from "antd";

import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";

const ThemeXFooter = ({
  hidHashTag=false,
  hidPageName=false,
  hidCollegeName=false
}) => {

  return (
    <Fragment>
      <Row className="themex-footer" type="flex" align="middle" justify="center">
        <Col className="themex-footer-body" span={20}>
          <If condition={!hidHashTag && window.pageData.hashtag}>
            <p className="hash-tag arc-support arc-color-B55 arc-text-align-c">
              {`#${window.pageData.hashtag}`}
            </p>
          </If>
          <If condition={!hidCollegeName && window.collegeName}>
              <div className="arc-support arc-color-B99 arc-H200 arc-text-align-c">{`${window.collegeName}`}</div>
          </If>
          <p className="arc-support arc-color-B55 arc-text-align-c">
          <a href="https://almabase.com" target="_blank"><FontAwesomeIcon icon={faBolt}/></a>
          </p>
        </Col>
      </Row>
    </Fragment>
  );
}

export default ThemeXFooter;
