export const CREATE_GIFT_API_PATH = '/givingx/pages/{}/create_gift/';
export const UPDATE_GIFT_API_PATH = '/givingx/pages/{}/update_gift/';
export const ADMIN_BASE_URL = '/admin/givingx/{}';
export const GIFTS_LIST_API = '/givingx/gifts/{}';
export const DONOR_LIST_API = '/givingx/pages/{}/donors/';
export const GIFTS_PUBLIC_LIST_API = '/givingx/pages/{}/gifts_public/';
export const RECURRING_GIFTS_PUBLIC_LIST_API = '/givingx/pages/{}/recurring_gifts_public/';
export const PAGE_API = '/givingx/pages/{}/';
export const CHALLENGE_API_PATH = '/givingx/pages/{}/challenges/?page_size=100';
export const LEADERBOARD_API_PATH = '/givingx/pages/{}/leaderboards/?is_active=true';
export const LEADERBOARD_GIFTS_API_PATH = '/givingx/pages/{}/leaderboards/<lb_id>/gifts/';
export const INFLUENCER_API_PATH = '/givingx/pages/{}/influencers/';
export const UGC_CONTENT_API_PATH = '/givingx/pages/{}/influencers/ugc/';
export const INFLUENCER_LEADERBOARD_API = '/givingx/pages/{}/influencers/leaderboard/';
export const GROUP_LEADERBOARD_API = '/givingx/pages/{}/leaderboards/<lb_id>/gifts/';
export const TOP_RESULTS_LEADERBOARD_API = '/givingx/pages/{}/leaderboards/<lb_id>/top_results/';
export const REACTIONS_LEADERBOARD_API = '/givingx/pages/{}/leaderboards/<lb_id>/reactions/';
export const GENEROSITY_LEADERBOARD_API = 'givingx/pages/{}/generosity/leaderboard/';
export const PAGE_UGC_API = 'givingx/pages/{}/ugc/';
export const PAGE_UGC_REACTION_API = 'givingx/pages/{}/ugc/reactions/';

export const DONOR_COVERING_CHARGES_API = 'almapay/accounts/{}/charges';
export const HUB_API = 'givingx/pages/<page_id>/public_hubs/';
export const CAMPAIGN_HUB_DONOR_API = 'givingx/pages/campaign/<campaign_id>/donors/';
export const USER_FEEDBACK_API_PATH = '/user_feedback/submit/?content_type=givingxgift&object_id=<object_id>';
export const RECURRING_GIFT_FIRST_GIFT_API_PATH = 'givingx/recurring_gifts/<id>/';
