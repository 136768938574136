export const DEFAULT_RESULT_LIMIT = 20;
export const DEFAULT_PAGE = 1;
export const COVER_IMAGE = 'cover_image';

export const FORM_FIELD_SPLIT_NAME = 'split_name';

export const FORM_FIELD_FULL_NAME = 'full_name';

export const INPUT_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
  TEL: 'tel',
  URL: 'url',
  EMAIL: 'email',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  COORDINATE_SELECT: 'coordinate_select',
  DATE: 'date',
  TIME: 'time',
  DATE_TIME: 'date_time',
  DATE_TIME_RANGE: 'date_time_range',
  SWITCH: 'switch',
  LOCATION: 'location',
  FILE: 'file',
  MULTIPLE_FILE: 'multi_file',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  AUTOCOMPLETE: 'autocomplete',
  MULTI_SELECT: 'multi_select',
  SELECT_SEARCH: 'select_search',
  SELECT_RANGE: 'select_range',
  PHONE_NUMBER: 'phone_number',
  GOOGLE_LOCATION: 'google_location',
  RICH_TEXT: 'rich_text',
  NAME: 'name',
};

export const CURRENCIES = [
  {
    id: 1,
    iso_code: 'USD',
    html_entity: '&#x24;',
  },
  {
    id: 2,
    iso_code: 'INR',
    html_entity: '&#x20B9;',
  },
  {
    id: 3,
    iso_code: 'EUR',
    html_entity: '&#x20AC;',
  },
  {
    id: 4,
    iso_code: 'AED',
    html_entity: 'AED',
  },
  {
    id: 5,
    iso_code: 'GBP',
    html_entity: '&#xa3;',
  },
  {
    id: 6,
    iso_code: 'PHP',
    html_entity: '&#x20B1;',
  },
  {
    id: 7,
    iso_code: 'CHF',
    html_entity: 'CHF',
  },
  {
    id: 8,
    iso_code: 'SAR',
    html_entity: '&#xFDFC;',
  },
  {
    id: 9,
    iso_code: 'MYR',
    html_entity: 'RM;',
  },
  {
    id: 10,
    iso_code: 'AUD',
    html_entity: 'AU&#x24;;',
  },
  {
    id: 11,
    iso_code: 'CAD',
    html_entity: 'C$',
  },
];

export const REDUX_STATUSES = {
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  ERROR: 'ERROR',
};

export const BREAKPOINT = 576;
export const MEDIUM_BREAKPOINT = 768;
export const ASPECT_RATIO = 3.2;
export const MAX_CONTAINER_WIDTH = 1152;

export const ORIENTATION = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

export const REGISTRATION_TYPES = {
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  GOOGLE: 'google',
  EMAIL: 'email',
  AUTH0: 'auth0',
};

// Image Types
export const IMAGE_TYPE_COVER = 'cover_image';
export const IMAGE_TYPE_LOGO = 'logo_image';
export const IMAGE_TYPE_GALLERY = 'gallery_image';
export const ORIENTATION_PORTRAIT = 'portrait';
export const ORIENTATION_LANDSCAPE = 'landscape';

export const SIGNUP_MODAL_STEPS = {
  EMAIL: 'email',
  LOGIN: 'login',
  REGISTER: 'register',
};

export const GET_STREAM_VERBS = {
  POST: 'post',
};

export const GET_STREAM_REACTION_TYPES = {
  COMMENT: 'comments',
  POST: 'posts',
  LIKE: 'likes',
};

export const GET_STREAM_REACTION_KIND = {
  LIKE: 'like',
  FLAG: 'flag',
};

export const FILE_STATUS = {
  STARTED: 1,
  UPLOADING: 2,
  UPLOADED: 3,
  FAILED: 4,
};

export const AUTHENTICATION_ERROR_STRINGS = [
  'Authentication credentials were not provided.',
  'CSRF Failed: CSRF token missing or incorrect. Please refresh the page to renew token.',
];

export const DEFAULT_COMMUNICATION_TEMPLATES = 'almabase-templates';
export const SAVED_TEMPLATES = 'saved-templates';

export const TEMPLATE_CATEGORIES = {
  [DEFAULT_COMMUNICATION_TEMPLATES]: {
    sectionApi: 'emails/templates/default/sections?templates__isnull=false',
    templateApi: 'emails/templates/default/',
  },
  [SAVED_TEMPLATES]: {
    sectionApi: 'emails/templates/sections?templates__isnull=false',
    templateApi: 'emails/templates/',
  },
};

export const EMAIL_STATUS = {
  ALMABASEAPPROVALPENDING: 5,
  APPROVALPENDING: 10,
  CANCELLED: 8,
  DISCARDED: 3,
  DRAFT: 9,
  FAILED: 11,
  HIDE: 1,
  RECEIVED: 4,
  SCHEDULED: 7,
  SENDING: 6,
  SENT: 2,
};

export const EMPTY_THUMBNAIL_IMG_PATH = `${staticPath}img/react-modules/empty-graphics/empty-template.jpg`;

export const DATA_PUSH_EVENT_RESOURCE_TYPE = 0;

export const DATA_PUSH_EVENT_GUEST_RESOURCE_TYPE = 1;

// Almasights constants

export const MODULE_DIRECTORY = 'Directory';

export const ALMASIGHTS_MODULES = {
  [MODULE_DIRECTORY]: {
    parentObjectName: 'Directory',
    objectName: 'Directory Item',
    fieldName: 'Directory Item Field',
  },
};

export const PARENT_OBJECT_TYPES = {
  DIRECTORY: 'directory',
  CUSTOM: 'almasightscustomparentobject',
  GIVING: 'givingxpage',
  FORM: 'customform',
  EVENT: 'eventx',
  COMMUNICATION: 'bulkemail',
  PAGE: 'page',
  MENTORSHIP: 'mentorshipprogram',
};

export const FORM_PURPOSE_CAMPAIGN_ALMABASE = 1;
export const FORM_PURPOSE_SURVEY = 2;
export const FORM_PURPOSE_ONLINE_COMPETITION = 3;
export const FORM_PURPOSE_COLLECT_APPLICATIONS = 4;
export const FORM_PURPOSE_RESPONSE_TO_APPEAL = 5;
export const FORM_PURPOSE_CUSTOM_CAMPAIGN = 6;
export const FORM_PURPOSE_DIRECTORY_ALMABASE = 7;
export const FORM_PURPOSE_GROUP_JOINING_FORM = 8;
export const FORM_PURPOSES_TRIGGER_GIFT_WORKFLOW = 9;
export const FORM_PURPOSE_WORKFLOWS = 10;
export const FORM_PURPOSE_EVENT_SYNC_WORKFLOWS = 11;
export const FORM_PURPOSE_MENTORSHIP_ONBOARDING = 13;

export const TRIGGER_GIFT_WORKFLOW_PURPOSE = {
  label: 'To trigger a gift sync workflow',
  value: FORM_PURPOSES_TRIGGER_GIFT_WORKFLOW,
};

export const TRIGGER_WORKFLOW_PURPOSE = {
  label: 'To trigger a gift list workflow',
  value: FORM_PURPOSE_WORKFLOWS,
};

export const TRIGGER_EVENT_SYNC_WORKFLOW = {
  label: 'To trigger event sync workflow',
  value: FORM_PURPOSE_EVENT_SYNC_WORKFLOWS,
};

export const FORM_PURPOSE_CHOICES = [
  {
    label: 'To organize an event or a giving campaign on Almabase',
    value: FORM_PURPOSE_CAMPAIGN_ALMABASE,
  },
  {
    label: 'To collect submissions for a directory',
    value: FORM_PURPOSE_DIRECTORY_ALMABASE,
  },
  {
    label: 'To conduct a survey',
    value: FORM_PURPOSE_SURVEY,
  },
  {
    label: 'To conduct an online competition or campaign',
    value: FORM_PURPOSE_ONLINE_COMPETITION,
  },
  {
    label: 'To collect requests or applications',
    value: FORM_PURPOSE_COLLECT_APPLICATIONS,
  },
  {
    label: 'To collect responses to an appeal or request',
    value: FORM_PURPOSE_RESPONSE_TO_APPEAL,
  },
  {
    label: 'To create a custom campaign',
    value: FORM_PURPOSE_CUSTOM_CAMPAIGN,
  },
  {
    label: 'To onboard mentors for mentorship',
    value: FORM_PURPOSE_MENTORSHIP_ONBOARDING,
  },
];

export const BUTTON_TEXT_FORM_PURPOSE_MAPPING = {
  [FORM_PURPOSE_GROUP_JOINING_FORM]: 'Create Verification Form',
};

export const CONTENT_TYPES = {
  EVENT: 'eventx',
  GIVING_PAGE: 'givingxpage',
};

export const AUTOMATED_EMAIL_TEMPLATES = {
  EVENT_REGISTRATION_CONFIRMATION: 95,
  EVENT_REMINDER_ONE_DAY_PRIOR: 97,
  EVENT_REMINDER_ONE_HOUR_PRIOR: 92,
  EVENT_REMINDER_ONE_WEEK_PRIOR: 93,
  EVENT_TAX_RECEIPT: 89,
  EVENT_INVITE_VOLUNTEER: 91,
  GIVING_THANK_YOU: 44,
};

// the idea is to use this for consistency coming forward
export const DATE_FORMAT = 'Do MMMM YYYY';
export const DATE_FORMAT_SHORT = 'Do MMM YYYY';
export const TIME_FORMAT = 'hh:mm:ss A';
