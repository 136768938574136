import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import ImageWithFallbackSrc from 'Src/common/components/imageWithFallbackSrc';
import { getRandomCoverImage } from 'Src/common/utilities/dom_utils';
import CurrencyRenderer from '../../../../common/components/currencyRenderer';
import { GiftConsumer } from '../../../context/gift';
import { findIndex } from 'lodash';
import { SettingsConsumer } from '../../../context/settings';
import { success } from '../../../actionCreators';
import { MODULE_GIFT } from '../../../constants';
import { countDecimals, toFixed } from 'Src/common/utilities/data_util';

const TYPE_OTHER_FUND = 'other_fund';

export const OtherFundCard = ({ name, description, cover_photo, tabIndex }) => {
  const [gift, giftDispatch] = GiftConsumer();
  const [settings, dispatch] = SettingsConsumer();

  const [fundAmount, setFundAmount] = useState(null);
  const [fundName, setFundName] = useState(null);

  const checkAmount = (amount) => {
    if (isNaN(parseFloat(amount))) {
      return undefined;
    }
    if (countDecimals(amount) > 2) return toFixed(parseFloat(amount), 2);
    return amount;
  };

  useEffect(() => {
    const index = getIndexOfOtherFund();
    if (index !== -1) {
      const currentFund = gift.funds[index];
      if (currentFund) {
        if (currentFund.fund.amount || currentFund.fund.amount === 0) setFundAmount(currentFund.fund.amount);
        else setFundAmount();

        if (currentFund.fund.name) setFundName(currentFund.fund.name);
        else setFundName();
      }
    }
  }, [gift]);

  function getIndexOfOtherFund() {
    const index = findIndex(gift.funds, (val) => val.fund.type === TYPE_OTHER_FUND);
    return index;
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function updateOtherFundAmount(amount) {
    if (parseFloat(amount) < 0) return;
    const otherFund = { name: fundName, is_open: true, type: TYPE_OTHER_FUND, amount: checkAmount(amount) };
    const index = getIndexOfOtherFund();
    let id = null;
    if (index !== -1) {
      id = gift.funds[index]['id'];
    }
    for (var i = 0; i < gift.funds.length; i++) {
      // If multiple attribution is not enabled, only allow to select single amount
      if (!settings.page.is_multiple_funds_attribution_enabled && gift.funds[i].fund.id !== id) {
        gift.funds[i].fund.amount = 0;
        gift.funds[i].fund.is_open = false;
      }
    }
    setFundAmount(checkAmount(amount));
    updateOrCreate(otherFund);
  }

  const updateOrCreate = (data) => {
    const index = getIndexOfOtherFund();
    if (index === -1) {
      gift.funds.push({ fund: { ...data, id: uuidv4(), type: TYPE_OTHER_FUND } });
    } else {
      gift.funds[index].fund = { ...data, id: gift.funds[index].fund.id, type: TYPE_OTHER_FUND };
    }
    giftDispatch(success(MODULE_GIFT, gift));
  };

  const updateName = (name) => {
    setFundName(name);
    updateOrCreate({ name, amount: fundAmount });
  };

  return (
    <div className="fund-card-wrapper" key={'id'}>
      <div className="fund-card-detail">
        <ImageWithFallbackSrc
          alt="fund-image"
          className="fund-img"
          src={`${window.staticPath}img/givingx/no-image-placeholder.svg`}
          fallbackSrc={getRandomCoverImage()}
        />
        <div className="fund-card-text-info">
          <div className="fund-card-title arc-color-B65 arc-H150 arc-font-weight-Medium ">{name || ''}</div>
          <div className="fund-card-description arc-p arc-color-B65">{description || ''}</div>
        </div>
      </div>
      <div className="fund-input">
        <Input
          aria-label="Enter name for other fund"
          tabIndex={tabIndex}
          className="mb6"
          allowClear
          value={fundName}
          onChange={({ target }) => updateName(target.value)}
          placeholder="Enter Fund Name"
        />
        <Input
          aria-label="Enter amount for other fund"
          type="number"
          tabIndex={tabIndex}
          allowClear
          value={fundAmount}
          onChange={({ target }) => updateOtherFundAmount(target.value)}
          prefix={<CurrencyRenderer currencyId={gift.account.currency} />}
          placeholder="Enter Amount"
          onWheel={(e) => e.target.blur()}
        />
      </div>
    </div>
  );
};
